import { AxiosPromise } from 'axios';
import { RolesData } from '@/types';
import { Core, ROLES } from '../Core';

type GetRoles = () => AxiosPromise<RolesData>;

class Roles extends Core {
  getRoles: GetRoles = async () => this.get(ROLES());

  getGBARoles: GetRoles = async () => this.get(`${ROLES()}/gba`);
}

export const roles = new Roles();
