import React, { FC } from 'react';
import { PageContainer } from '@/ui/components/Page';
import { Carousel, Image } from '@/ui';
import { getFormattedLabel } from '@/utils/helpers';

import { InspectionPageTab } from '../types';

export const Images: FC<InspectionPageTab> = ({ inspection }) => {
  const {
    frontImageFileUrl,
    rearImageFileUrl,
    nearsideImageFileUrl,
    offsideImageFileUrl,
    chargeLevelImageFileUrl,
  } = inspection;

  return (
    <PageContainer fluid interval={null}>
      <div className="d-flex justify-content-center">
        <Carousel slide={false}>
          {Object.entries({
            frontImageFileUrl,
            rearImageFileUrl,
            nearsideImageFileUrl,
            offsideImageFileUrl,
            chargeLevelImageFileUrl,
          })
            .filter(([, value]) => Boolean(value))
            .map(([key, value]) => (
              <Carousel.Item key={key}>
                <Image
                  src={value}
                  alt={getFormattedLabel(key)}
                  rounded
                  style={{ maxHeight: '100vh' }}
                  loading="lazy"
                />
                <Carousel.Caption>
                  <h3>{getFormattedLabel(key)}</h3>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
        </Carousel>
      </div>
    </PageContainer>
  );
};
