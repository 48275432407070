import React, { FC } from 'react';
import { useTheme } from 'styled-components';
import { PageContainer } from '@/ui/components/Page';
import { Title } from '@/ui/components/Title';
import { useTranslate } from '@/translations';
import { faBuilding } from '@/ui/components/Icon';
import { usePTS } from '@/api/PTS';
import { Loader } from '@/ui/components/Loader';
import { PTSDataGrid } from '@/modules/PTS/components';
import { PTSDetails } from '@/types';

export const PTS: FC = () => {
    const { t } = useTranslate();
    const { accent } = useTheme();
    const { data: ptsData, isLoading: isPTSLoading } = usePTS();

    if (isPTSLoading) return <Loader />;
  
    return (
      <PageContainer fluid>
        <Title
          title={t('pts')}
          icon={faBuilding}
          color={accent}
        />
        <PTSDataGrid ptsdata={ptsData?.entities as PTSDetails[]} />
      </PageContainer>
    );
  };
  