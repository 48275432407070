import React from 'react';
import { PageContainer } from '@/ui/components/Page';
import { Alert } from '@/ui';
import { useTranslate } from '@/translations';

export const NoAccessAlert = () => {
  const { t } = useTranslate();

  return (
    <PageContainer>
      <Alert variant="danger">{t('noAccessAlert')}</Alert>
    </PageContainer>
  );
};
