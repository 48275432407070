import React, { useState, useCallback } from 'react';

import { UploadDocumentModal } from './UploadDocumentModal';
import {
  CloseUploadDocumentModal,
  OpenUploadDocumentModal,
  RenderUploadDocumentModalType,
  UseUploadDocumentModal,
  UseUploadDocumentModalState,
} from './types';

const initialState: UseUploadDocumentModalState = {
  isOpen: false,
};

export const useUploadDocumentModal: UseUploadDocumentModal = () => {
  const [{ isOpen }, setState] =
    useState<UseUploadDocumentModalState>(initialState);

  const openUploadDocumentModal: OpenUploadDocumentModal = useCallback(
    () => setState({ isOpen: true }),
    [],
  );

  const closeUploadDocumentModal: CloseUploadDocumentModal = useCallback(
    () => setState(initialState),
    [],
  );

  const RenderUploadDocumentModal: RenderUploadDocumentModalType = useCallback(
    ({ initialValues }) => (
      <UploadDocumentModal
        isOpen={isOpen}
        closeModal={closeUploadDocumentModal}
        initialValues={initialValues}
      />
    ),
    [isOpen, closeUploadDocumentModal],
  );

  return {
    openUploadDocumentModal,
    RenderUploadDocumentModal,
  };
};
