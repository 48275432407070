import React from 'react';
import { useParams } from 'react-router-dom';
import {
  useInspectionsChecklistDynamicOperations,
  useInspectionsChecklistElectricHybrid,
  useInspectionsChecklistEngineRunning,
  useInspectionsChecklistInterior,
  useInspectionsVehicle,
} from '@/api/Inspections';
import { Loader } from '@/ui/components/Loader';
import { useInspectionsMechanicalsWheels } from '@/api/Inspections/hooks/useInspectionsVehicles';
import { checkResult } from '@/modules/Inspection/components/tabs/Documents/components/helpers';
import { DynamicOperations, ElectricHybrid, EngineRunning, Inspection, Interior, MechanicalsWeels } from '@/types';

import { MechanicalReport as MechanicalReportMarkup } from '../components/MechanicalReport';

export const MechanicalReport = () => {
  const { id } = useParams();

  const { isLoading, data } = useInspectionsVehicle(Number(id));
  const { isLoading: dynamicOperationsIsLoading, data: dynamicOperationsData } =
    useInspectionsChecklistDynamicOperations(Number(id));
  const { isLoading: electricHybridIsLoading, data: electricHybridData } =
    useInspectionsChecklistElectricHybrid(Number(id));
  const { isLoading: engineRunningIsLoading, data: engineRunningData } =
    useInspectionsChecklistEngineRunning(Number(id));
  const { isLoading: interiorIsLoading, data: interiorData } =
    useInspectionsChecklistInterior(Number(id));
  const { isLoading: isMechanicalsWheelsLoading, data: mechanicalsWheelsData } =
    useInspectionsMechanicalsWheels(Number(id));

  if (
    isLoading ||
    dynamicOperationsIsLoading ||
    electricHybridIsLoading ||
    engineRunningIsLoading ||
    interiorIsLoading ||
    isMechanicalsWheelsLoading
  )
    return <Loader />;

  const dynamicOperations = dynamicOperationsData?.entities?.[0] as DynamicOperations;
  const electricHybrid = electricHybridData?.entities?.[0] as ElectricHybrid;
  const engineRunning = engineRunningData?.entities?.[0] as EngineRunning;
  const interior = interiorData?.entities?.[0] as Interior;
  const wheels = mechanicalsWheelsData?.entities?.[0] as MechanicalsWeels;
  const vehicleData = data?.entities?.[0] as Inspection;

  const checkItems = {
    interior,
    electricHybrid,
    dynamicOperations,
    engineRunning,
  };

  const checkNSF = checkResult([
    wheels?.frontLeftWheelInnerDepth,
    wheels?.frontLeftWheelMiddleDepth,
    wheels?.frontLeftWheelOuterDepth,
  ]);

  const checkOSF = checkResult([
    wheels?.frontRightWheelInnerDepth,
    wheels?.frontRightWheelMiddleDepth,
    wheels?.frontRightWheelOuterDepth,
  ]);

  const checkNSR = checkResult([
    wheels?.rearLeftWheelInnerDepth,
    wheels?.rearLeftWheelMiddleDepth,
    wheels?.rearLeftWheelOuterDepth,
  ]);

  const checkOSR = checkResult([
    wheels?.rearRightWheelInnerDepth,
    wheels?.rearRightWheelMiddleDepth,
    wheels?.rearRightWheelOuterDepth,
  ]);

  return (
    <MechanicalReportMarkup
      vehicleData={vehicleData}
      interior={interior}
      checkItems={checkItems}
      wheels={wheels}
      checkNSF={checkNSF}
      checkOSF={checkOSF}
      checkNSR={checkNSR}
      checkOSR={checkOSR}
    />
  )
};
