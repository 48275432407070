import styled from 'styled-components';

export const Header = styled.div`
  background-color: #171b60;
  color: #fff;
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 27px;
`;
export const HorizontalList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
  font-size: 20px;
  font-weight: 600;
`;

export const Li = styled.li`
  font-size: 17px;
  display: flex;
`;
