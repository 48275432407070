import { AxiosPromise } from 'axios';
import { CompaniesData, UpdateCompany, CreateCompany } from '@/types';
import { Core, COMPANIES } from '../Core';

type GetCompanies = () => AxiosPromise<CompaniesData>;
type UpdateCompanies = (data: UpdateCompany) => AxiosPromise<boolean>;
type CreateCompanies = (data: CreateCompany) => AxiosPromise<boolean>;

class Companies extends Core {
  getCompanies: GetCompanies = async () => this.get(COMPANIES());

  updateCompanies: UpdateCompanies = async (data) =>
    this.patch<UpdateCompany, AxiosPromise<boolean>>(COMPANIES(), data);

  createCompanies: CreateCompanies = async (data) =>
    this.post<CreateCompany, AxiosPromise<boolean>>(COMPANIES(), data);
}

export const companies = new Companies();
