import styled, { css } from 'styled-components';
import { Button } from '@/ui';

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.accent};
  border-color: ${({ theme }) => theme.accent};
  ${({ fullwidth }) =>
    fullwidth &&
    css`
      width: 100%;
    `};
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.accent};
    border-color: ${({ theme }) => theme.accent};
    filter: brightness(85%);
  }
`;
