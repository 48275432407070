import styled from 'styled-components';
import { Button } from '@/ui';

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Img = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

export const NoPreviewWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

export const NoPreviewText = styled.p`
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 30px;
  color: ${({ theme }) => theme.accent};
`;

export const NoPreviewButton = styled(Button)`
  width: 200px;
  border-color: ${({ theme }) => theme.accent};
  background-color: ${({ theme }) => theme.accent};
  
  :hover, :focus {
    border-color: ${({ theme }) => theme.accent};
    background-color: ${({ theme }) => theme.accent};
  }
`;