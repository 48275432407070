import styled from 'styled-components';

export const NumberBoxWrapper = styled.h6`
  .dx-texteditor-input {
    padding: 1px 6px;
    min-height: 0;
  }

  .dx-state-hover, .dx-state-focused {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, .25);
  }
`
