import React, { FC, memo, useCallback } from 'react';
import { Column, DataGrid, Pager, Paging } from '@/ui';
import { isPagerVisible } from '@/utils/helpers';
import { FORMAT_DATE_WITH_TIME } from '@/utils/constants';
import { useTranslate } from '@/translations';
import { Loader } from '@/ui/components/Loader';
import { Link } from 'react-router-dom';
import { faFile, Icon } from '@/ui/components/Icon';
import { paths } from '@/routes';
import { StyledLink, StyledLinkContainer } from '@/ui/globalStyles';

import { InspectionsDataGridProps } from './types';

const PAGE_SIZE = 10;

export const InspectionsDataGrid: FC<InspectionsDataGridProps> = memo(
  ({ passportsData, isLoading }) => {
    const { t } = useTranslate();

    const onRenderActionsCell = useCallback(
      ({ data: { inspectionId } }) => (
        <StyledLinkContainer>
          <StyledLink>
            <Link
              to={`/${paths.inspection}/${inspectionId}`}
            >
              <Icon icon={faFile} />
            </Link>
          </StyledLink>
        </StyledLinkContainer>
      ),
      [],
    );

    if (isLoading) return <Loader />;

    const dataSource = passportsData?.entities.filter(({ inspectionId }) => inspectionId);

    return (
      <DataGrid
        dataSource={dataSource}
        columnHidingEnabled
        showRowLines
        showBorders
        allowColumnReordering
        width="100%"
        allowColumnResizing
      >
        <Paging pageSize={PAGE_SIZE} />
        <Pager
          showInfo
          showNavigationButtons
          visible={isPagerVisible(
            PAGE_SIZE,
            passportsData?.entities?.length,
          )}
        />

        <Column
          dataField="inspectionId"
          caption={t('inspectionId')}
          width={100}
        />
        <Column
          dataField="inspectionRef"
          caption={t('damageReference')}
        />
        <Column
          dataField="createDate"
          caption={t('date')}
          dataType="date"
          format={FORMAT_DATE_WITH_TIME}
          width={150}
        />
        <Column
          dataField="actions"
          caption={null}
          cellRender={onRenderActionsCell}
          alignment="center"
          allowSorting={false}
          allowEditing={false}
          allowSearch={false}
          allowFiltering={false}
          width={50}
        />
      </DataGrid>
    );
  },
);
