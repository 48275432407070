import React, { ChangeEvent, FC, useCallback } from 'react';
import { isPagerVisible } from '@/utils/helpers';
import {
  Column,
  DataGrid,
  LoadPanel,
  Pager,
  Form,
  RequiredRule,
  Sorting,
  FilterRow,
  ColorBox,
  Editing,
  FormItem,
  DataGridForm,
  MasterDetail,
  Col,
} from '@/ui';
import { useIdTokenClaims } from '@/hooks/useIdTokenClaims';
import { hasRoleAccess } from '@/utils/access';
import { AppRoles } from '@/types';
import { acceptImageFiles } from '@/modules/UploadFile/utils';
import { TenantsDataGridProps } from './types';
import MasterDetailView from '../tabs/MasterDetailView';

const PAGE_SIZE = 20;

interface CellProps {
  setValue(value: string | ArrayBuffer): void;
}

export const TenantsDataGrid: FC<TenantsDataGridProps> = ({
  tenants,
  onTenantAdd,
  onTenantUpdate,
}) => {
  const idTokenClaims = useIdTokenClaims();
  const userRoles = idTokenClaims?.roles || [];
  const hasGbaSuperAdminAccess = hasRoleAccess(userRoles, [
    AppRoles.GbaSuperAdmin,
  ]);

  const renderActiveCell = useCallback(
    ({ data: { isActive } }) => (
      <Form.Check
        className="dx-custom-switch"
        type="switch"
        checked={isActive}
        readOnly
        disabled={!isActive}
      />
    ),
    [],
  );

  const renderColor1Cell = useCallback(
    ({ data: { color1 } }) => <ColorBox value={color1} readOnly />,
    [],
  );

  const renderColor2Cell = useCallback(
    ({ data: { color2 } }) => <ColorBox value={color2} readOnly />,
    [],
  );

  const renderIconCell = useCallback(
    ({ data: { iconUrl } }) => (
      <img src={iconUrl} alt="tenant icon" style={{ height: '35px' }} />
    ),
    [],
  );

  const handleAddTenant = useCallback(
    ({ data }) => {
      onTenantAdd(data);
    },
    [onTenantAdd],
  );

  const handleEditTenant = useCallback(
    ({ newData, oldData }) => {
      onTenantUpdate(newData, oldData);
    },
    [onTenantUpdate],
  );

  const onValueChanged = (
    e: ChangeEvent<HTMLInputElement>,
    cell: CellProps,
  ) => {
    const item = e.target.files?.[0];

    if (!item) {
      return;
    }

    const reader = new FileReader();
    reader.onload = ({ target }) => {
      if (target?.result) {
        cell.setValue(target.result);
      }
    };
    reader.readAsDataURL(item); // convert to base64 string
  };

  const iconUrlEditorRender = (cell: CellProps) => (
    <input
      type="file"
      id="img"
      name="img"
      accept={acceptImageFiles}
      onChange={(e) => onValueChanged(e, cell)}
    />
  );

  return (
    <Col>
      <DataGrid
        id="gridContainer"
        columnHidingEnabled
        showBorders
        dataSource={tenants}
        onRowInserted={handleAddTenant}
        onRowUpdating={handleEditTenant}
        rowAlternationEnabled
        width="100%"
      >
        <LoadPanel enabled />
        <Pager
          allowedPageSizes={PAGE_SIZE}
          showInfo
          showNavigationButtons
          visible={isPagerVisible(PAGE_SIZE, tenants?.length)}
        />
        {hasGbaSuperAdminAccess && (
          <Editing
            mode="popup"
            allowAdding
            allowUpdating
            popup={{
              width: 800,
              height: 250,
            }}
          >
            <DataGridForm>
              <FormItem itemType="group">
                <FormItem dataField="name" />
                <FormItem dataField="iconUrl" />
                <FormItem dataField="isActive" />
              </FormItem>
              <FormItem itemType="group">
                <FormItem dataField="color1" editorType="dxColorBox" />
                <FormItem dataField="color2" editorType="dxColorBox" />
              </FormItem>
            </DataGridForm>
          </Editing>
        )}
        <FilterRow visible />
        <Sorting mode="multiple" />
        <MasterDetail
          enabled
          render={({ data: { id } }) => <MasterDetailView tenantId={id} />}
        />
        <Column dataField="name" caption="Name">
          <RequiredRule />
        </Column>
        <Column
          allowFiltering={false}
          allowSorting={false}
          dataField="iconUrl"
          caption="Icon"
          alignment="center"
          cellRender={renderIconCell}
          editCellRender={iconUrlEditorRender}
          width={150}
        />
        <Column
          allowFiltering={false}
          dataField="color1"
          caption="Foreground Colour"
          alignment="center"
          cellRender={renderColor1Cell}
          width={160}
        />
        <Column
          allowFiltering={false}
          dataField="color2"
          caption="Background Colour"
          alignment="center"
          cellRender={renderColor2Cell}
          width={160}
        />
        <Column
          allowEditing
          dataField="isActive"
          caption="Active"
          width={85}
          cellRender={renderActiveCell}
          alignment="center"
        />
      </DataGrid>
    </Col>
  );
};
