import React from 'react';

export const GbaLogo = ({ fill = '#ffffff', width = '60', height = '53' }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 260 223.956"
    enableBackground="new 0 0 260 223.956"
    xmlSpace="preserve"
  >
    <g>
      <path
        fill={fill}
        d="M13.226,22.622c-5.41,0-9.811-4.401-9.811-9.811C3.415,7.401,7.816,3,13.226,3c5.409,0,9.811,4.401,9.811,9.811C23.036,18.22,18.635,22.622,13.226,22.622"
      />
      <path
        fill={fill}
        d="M177.242,170.405c-12.877,0-21.907-2.68-30.639-5.271c-9.189-2.727-17.87-5.304-31.024-4.699c-8.282,0.38-16.726,1.827-24.175,3.103c-16.327,2.799-31.75,5.442-34.8-7.337L29.85,43.209c-1.02-3.205-0.48-6.429,1.531-9.107C40.372,22.12,81.035,23.16,93.284,23.765c17.681,0.874,32.507,4.842,45.588,8.344c10.646,2.851,19.851,5.323,27.596,5.185c18.705-0.305,30.371-5.654,38.092-9.195c5.73-2.628,9.872-4.526,13.951-2.554c3.599,1.741,4.634,5.633,4.986,7.069l32.428,110.459c1.386,4.717-0.332,9.858-4.271,12.792C242.749,162.499,222.611,170.405,177.242,170.405 M119.39,150.423c12.52,0,21.411,2.638,30.037,5.198c8.423,2.5,16.379,4.86,27.815,4.86c33.277,0,57.599-4.465,68.484-12.575c0.627-0.466,0.899-1.287,0.679-2.039L213.945,35.3c-0.021-0.073-0.042-0.146-0.059-0.22c-0.014-0.056-0.027-0.11-0.042-0.162c-1.323,0.444-3.396,1.395-5.148,2.199c-7.959,3.65-21.28,9.758-42.066,10.098c-9.164,0.134-18.96-2.479-30.325-5.521c-12.601-3.374-26.884-7.198-43.51-8.019c-12.72-0.628-25.687-0.273-35.576,0.971c-14.871,1.873-17.613,5.006-17.938,5.465c0.008,0.032,0.02,0.075,0.038,0.128c0.054,0.156,0.1,0.315,0.138,0.476l26.802,113.191c0.889,3.722,12.887,1.666,23.472-0.149c7.733-1.325,16.498-2.828,25.395-3.235C116.592,150.456,118.013,150.423,119.39,150.423"
      />
      <path
        fill={fill}
        d="M58.16,221.17l8.015-1.887c0.54-0.127,0.878-0.673,0.751-1.213L21.378,24.629c-0.127-0.54-0.673-0.877-1.213-0.75l-8.015,1.887c-0.54,0.127-0.878,0.673-0.75,1.213l45.546,193.44C57.074,220.96,57.62,221.297,58.16,221.17"
      />
      <path
        fill={fill}
        d="M97.976,65.764c6.668,0,13.971,2.778,19.369,7.461c0.715,0.556,0.794,1.508,0.158,2.144l-6.271,6.668c-0.635,0.555-1.507,0.555-2.143,0.08c-3.017-2.779-6.509-3.811-10.398-3.811c-8.653,0-15.639,7.303-15.639,16.035c0,8.652,7.064,15.796,15.718,15.796c2.301,0,4.683-0.317,7.064-1.27v-4.604h-5.001c-0.874,0-1.509-0.635-1.509-1.429v-7.859c0-0.873,0.635-1.508,1.509-1.508h15.956c0.793,0,1.428,0.715,1.428,1.508l0.08,21.354c0,0.397-0.317,1.032-0.635,1.271c0,0-8.176,5.16-19.687,5.16c-15.797,0-28.499-12.542-28.499-28.418S82.179,65.764,97.976,65.764"
      />
      <path
        fill={fill}
        d="M127.504,67.986c0-0.794,0.634-1.508,1.508-1.508h18.495c9.367,0,17.067,6.43,17.067,14.765c0,6.112-5.478,10.558-9.208,12.542c4.208,1.747,10.637,5.636,10.637,12.939c0,8.891-7.859,15.32-17.304,15.32h-19.687c-0.874,0-1.508-0.715-1.508-1.508V67.986z M146.078,89.022c3.017,0,5.558-2.54,5.558-5.715s-2.541-5.398-5.558-5.398h-6.192v11.113H146.078z M147.11,110.693c3.097,0,5.716-2.54,5.716-5.794c0-3.175-3.414-5.557-6.51-5.557h-6.431v11.352H147.11z"
      />
      <path
        fill={fill}
        d="M167.273,119.98l24.846-53.423c0.238-0.477,0.793-0.873,1.35-0.873h0.793c0.555,0,1.111,0.396,1.349,0.873l24.846,53.423c0.477,1.032-0.159,2.064-1.349,2.064h-8.812c-1.428,0-2.064-0.476-2.778-1.984l-2.857-6.271h-21.592l-2.857,6.351c-0.397,0.953-1.271,1.905-2.858,1.905h-8.732C167.431,122.044,166.796,121.012,167.273,119.98 M199.898,102.993l-6.033-13.098h-0.08l-5.953,13.098H199.898z"
      />
    </g>
  </svg>
);
