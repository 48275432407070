import { AxiosPromise } from 'axios';
import { OrderSummaryData } from '@/types';
import { Core, ORDER_SUMMARY } from '../Core';

type GetOrderSummary = () => AxiosPromise<OrderSummaryData>;

class Order extends Core {
  getOrderSummary: GetOrderSummary = async () => this.get(ORDER_SUMMARY());
}

export const order = new Order();
