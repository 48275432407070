import { useQuery, useMutation } from 'react-query';
import { queryClient } from '@/config/reactQuery';
import { QUERY_KEYS } from '../../queryKeys';
import { shippingPlan } from '../ShippingPlan';

export const useShippingPlan = (tenantId?: string) =>
  useQuery(
    [QUERY_KEYS.shippingPlan, tenantId],
    async () => (await shippingPlan.getShippingPlan(tenantId)).data,
  );

export const useUploadShippingPlan = () =>
  useMutation(shippingPlan.uploadShippingPlan, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.shippingPlan);
    },
  });

export const useDeleteShippingPlan = () =>
  useMutation(shippingPlan.deleteShippingPlan, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.shippingPlan);
    },
  });
