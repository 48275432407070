import React, { FC } from 'react';
import { PageContainer } from '@/ui/components/Page';
import { useTranslate } from '@/translations';
import {
  useInspectionsChecklistDynamicOperations,
  useInspectionsChecklistElectricHybrid,
  useInspectionsChecklistEngineRunning,
  useInspectionsChecklistInterior,
} from '@/api/Inspections';
import { Accordion, Col, Row } from '@/ui';
import { Loader } from '@/ui/components/Loader';
import { TranslationKey } from '@/translations/types';
import { isValidDate, localDateTimeFormatted } from '@/utils/helpers';

import { AccordionItemWrapper } from './Checklist.styled';
import { InspectionPageTab } from '../types';

export const Checklist: FC<InspectionPageTab> = ({ inspection }) => {
  const { t } = useTranslate();

  const { isLoading: dynamicOperationsIsLoading, data: dynamicOperationsData } =
    useInspectionsChecklistDynamicOperations(inspection.id);
  const { isLoading: electricHybridIsLoading, data: electricHybridData } =
    useInspectionsChecklistElectricHybrid(inspection.id);
  const { isLoading: engineRunningIsLoading, data: engineRunningData } =
    useInspectionsChecklistEngineRunning(inspection.id);
  const { isLoading: interiorIsLoading, data: interiorData } =
    useInspectionsChecklistInterior(inspection.id);

  const dynamicOperations = dynamicOperationsData?.entities?.[0];
  const electricHybrid = electricHybridData?.entities?.[0];
  const engineRunning = engineRunningData?.entities?.[0];
  const interior = interiorData?.entities?.[0];

  if (
    dynamicOperationsIsLoading ||
    electricHybridIsLoading ||
    engineRunningIsLoading ||
    interiorIsLoading
  )
    return <Loader />;

  const accordionItems = {
    dynamicOperations,
    engineRunning,
    electricHybrid,
    interior,
  };

  return (
    <PageContainer fluid>
      <Accordion
        defaultActiveKey={Object.keys(accordionItems).filter(
          (key) => !!accordionItems[key as keyof typeof accordionItems],
        )}
        alwaysOpen
      >
        {Object.entries(accordionItems).map(([endpoint, data]) => {
          const needToHighlightBlockWithFailedCheck = Object.values(
            data || {},
          ).some((value) => value === 'Fail');

          return (
            <AccordionItemWrapper
              isFailed={needToHighlightBlockWithFailedCheck}
            >
              <Accordion.Item eventKey={endpoint} key={endpoint}>
                <Accordion.Header>
                  {t(endpoint as TranslationKey)}
                </Accordion.Header>
                <Accordion.Body>
                  {data &&
                    Object.entries(data)
                      .filter(([key]) => key !== 'id')
                      .map(([question, answer]) => {
                        const temp = question.replace(/([A-Z])/g, ' $1');
                        const formattedQuestion = `${temp
                          .charAt(0)
                          .toUpperCase()}${temp.slice(1)}`;
                        let formattedAnswer = answer;

                        if (typeof answer === 'string') {
                          formattedAnswer = answer
                            .replace(/([A-Z])/g, ' $1')
                            .trim();
                        }

                        if (
                          question.toLowerCase().includes('date') &&
                          isValidDate(answer)
                        ) {
                          formattedAnswer = localDateTimeFormatted(answer);
                        }

                        return (
                          <Row
                            key={question}
                            style={{
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Col>
                              <div className="dx-field-label fw-bold">
                                {formattedQuestion}
                              </div>
                            </Col>
                            <Col>
                              <div
                                className="dx-field-value"
                                style={{ float: 'unset' }}
                              >
                                {formattedAnswer}
                              </div>
                            </Col>
                          </Row>
                        );
                      })}
                </Accordion.Body>
              </Accordion.Item>
            </AccordionItemWrapper>
          );
        })}
      </Accordion>
    </PageContainer>
  );
};
