import React, { FC } from 'react';
import { useIdTokenClaims } from '@/hooks/useIdTokenClaims';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from '@/ui/theme';

export const AppThemeProvider: FC = ({ children }) => {
  const idTokenClaims = useIdTokenClaims();
  const customerTheme = {
    ...defaultTheme,
    ...(idTokenClaims?.tenantColor1
      ? {
          accent: idTokenClaims?.tenantColor1,
        }
      : {}),
    ...(idTokenClaims?.tenantColor2
      ? {
          main: idTokenClaims?.tenantColor2,
        }
      : {}),
  };

  return <ThemeProvider theme={customerTheme}>{children}</ThemeProvider>;
};
