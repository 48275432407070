import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { API_VERSION, BASE_URL } from './constants';

type Request = <T, K = T>(
  url: string,
  data?: T,
  config?: AxiosRequestConfig,
) => Promise<K>;

export const httpClientDefaultHeaders = {
  'api-version': API_VERSION,
};

export const httpClient = axios.create({
  baseURL: BASE_URL,
  headers: httpClientDefaultHeaders,
});

export class Core {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = httpClient;
  }

  get: Request = async (url, data, config) => this.httpClient.get(url, config);

  put: Request = async (url, data, config) =>
    this.httpClient.put(url, data, config);

  patch: Request = async (url, data, config) =>
    this.httpClient.patch(url, data, config);

  post: Request = async (url, data, params) =>
    this.httpClient.post(url, data, params);

  delete: Request = async (url, data) => this.httpClient.delete(url, { data });
}
