import React, { FC } from 'react';
import { Col, DevButton } from '@/ui';
import { useTranslate } from '@/translations';
import { Field } from '../Field';
import { UserProfileProps } from './types';

export const UserProfile: FC<UserProfileProps> = ({
  user,
  inspector,
  onResetPasswordClick,
}) => {
  const { t } = useTranslate();

  return (
    <Col xxl={8} xl={10} xs={12}>
      <Field label={t('username')} value={user?.displayName ?? ''} />
      <Field label={t('role')} value={user?.role.name ?? ''} />
      <Field
        label={t('inspectorAssigned')}
        value={`${inspector?.inspectorForenames ?? ''} ${
          inspector?.inspectorSurname ?? ''
        }`}
      />
      <Field label={t('email')} value={user?.email ?? ''} />

      <DevButton
        text="Reset Password"
        stylingMode="contained"
        icon="key"
        onClick={onResetPasswordClick}
      />
    </Col>
  );
};
