import styled, { createGlobalStyle, css } from 'styled-components';
import icomoonSvg from './fonts/icomoon.svg';
import icomoonEot from './fonts/icomoon.eot';
import icomoonWoff2 from './fonts/icomoon.woff2';
import icomoon from './fonts/IcoMoon-Free.ttf';

import 'devextreme/dist/css/dx.carmine.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export const GlobalStyledLink = css`
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.accent};
  transition: all 1s ease-out;

  &:hover {
    opacity: 0.4;
    color: ${({ theme }) => theme.accent};
    fill: ${({ theme }) => theme.accent};
    transition: all 0.4s ease-out;
  }
`;

export const StyledLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  a,
  span {
    padding: 0 8px;
  }
`;

export const StyledLink = styled.span`
  ${GlobalStyledLink}
`;

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'icomoon';
    src: url(${icomoonEot}) format('embedded-opentype'), url(${icomoon}) format('truetype'), url(${icomoonWoff2}) format('woff2'), url(${icomoonSvg}) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
  }

  .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active,
  .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-list-item-selected {
    background-color: ${({ theme }) => theme.accent};
    color: ${({ theme }) => theme.white};
  }

  .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active {
    opacity: 0.8;
  }

  .dx-datebox-wrapper-calendar {
    && .dx-icon-chevronleft,
    && .dx-icon-chevronright {
      color: ${({ theme }) => theme.accent};
    }

    .dx-calendar-caption-button {
      .dx-button-text {
        color: ${({ theme }) => theme.accent};
      }
    }

    && .dx-calendar-selected-date,
    && .dx-calendar-contoured-date.dx-calendar-today {
      box-shadow: ${({ theme }) =>
        `inset 0 0 0 1px ${theme.lightGray}, inset 0 0 0 1000px ${theme.accent}`};
      color: ${({ theme }) => theme.white};;
      font-weight: 700;
    }
  }

  #gridContainer {
    .dx-datagrid-action {
      vertical-align: middle;
    }

    .dx-datagrid-text-content {
      color: ${({ theme }) => theme.black};
      cursor: pointer;
      padding: 3px 6px;
      font-weight: 500;
      white-space: pre-wrap;
    }

    .dx-row {
      font-weight: 500;
      color: ${({ theme }) => theme.gray};
    }

    .dx-pages {
      float: left;
      padding: 0 16px;
    }

    .dx-pager {
      border: 1px solid ${({ theme }) => theme.dataGridPager};
      padding: 4px 0;
    }

    .dx-selection {
      background-color: ${({ theme }) => theme.accent};

      td {
        background-color: ${({ theme }) => theme.accent};
      }
    }

    .dx-datagrid-rowsview .dx-state-hover:not(.dx-selection) {
      cursor: pointer;
      background-color: ${({ theme }) => theme.accent};
      filter: opacity(75%);
    }

    .dx-info {
      color: ${({ theme }) => theme.black};
      font-weight: 500;
    }

    a {
      ${GlobalStyledLink}
    }

    .dx-custom-switch {
      .form-check-input {
        background-color: ${({ theme }) => theme.accent};
        border-color: ${({ theme }) => theme.accent};

        box-shadow: none;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9Jy00IC00IDggOCc+PGNpcmNsZSByPSczJyBmaWxsPScjZmZmJy8+PC9zdmc+);
      }
    }

    .dx-scrollable-scroll-content {
      background-color: ${({ theme }) => theme.accent};
    }
  }

  .dx-texteditor.dx-editor-outlined {
    display: flex;
    flex-direction: row-reverse;
  }

  .dx-freespace-row {
    height: 0 !important;
  }

  .card .dx-tabpanel-tabs .dx-tab:not(.dx-tab-selected) {
    .dx-tab-text {
      color: ${({ theme }) => theme.accent};
    }
  }

  .dx-dropdowneditor-input-wrapper {
    flex-grow: 1;
  }

  .dx-dropdowneditor {
    background-color: inherit !important;
  }

  .dx-editor-cell .dx-texteditor .dx-texteditor-input {
    background-color: inherit !important;
  }
`;
