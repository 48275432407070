import styled from 'styled-components';
import { Row, Col, Container } from '@/ui';
import bannerImg from './banner.jpeg';

export const HomeBanner = styled(Container)`
  flex-grow: 1;
  background-image: url('${bannerImg}');
  background-size: cover;
  // margin-left: 4rem;
`;

export const HomeBannerContent = styled(Row)`
  padding: 100px 0;
`;

export const HomeBannerInfo = styled(Col)`
  color: ${({ theme }) => theme.loginBannerInfo};
`;

export const HomeBannerInfoTitle = styled.h1`
  && {
    text-transform: uppercase;
    font-weight: 800;
  }
`;

export const HomeBannerInfoDescription = styled.p`
  font-size: 20px;
`;
