import React, { FC, useCallback, useMemo } from 'react';
import {
  Column,
  DataGrid,
  Editing,
  FilterRow,
  LoadPanel,
  Pager,
  Paging,
} from '@/ui';
import { currencyFormatted, isPagerVisible } from '@/utils/helpers';
import { useTranslate } from '@/translations';
import { useInspectionsVehicleDamageParts } from '@/api/Inspections';
import { faTrash, Icon } from '@/ui/components/Icon';
import { StyledLinkContainer, StyledLink } from '@/ui/globalStyles';
import { useDeleteInspectionsVehicleDamagePart } from '@/api/Inspections/hooks';

import { DamagePartsDataGridProps } from './types';

const PAGE_SIZE = 6;

export const DamagePartsDataGridSection: FC<DamagePartsDataGridProps> = ({
  damageActionId,
}) => {
  const { t } = useTranslate();
  const { data: damagePartsData } =
    useInspectionsVehicleDamageParts(damageActionId);
  const { mutate: deleteVehicleDamagePartMutate } =
    useDeleteInspectionsVehicleDamagePart();

  const renderActionsCell = useCallback(
    ({ data: { id } }) => (
      <StyledLinkContainer>
        <StyledLink onClick={() => deleteVehicleDamagePartMutate(id.toString())}>
          <Icon icon={faTrash} />
        </StyledLink>
      </StyledLinkContainer>
    ),
    [deleteVehicleDamagePartMutate],
  );

  const dataSource = useMemo(
    () =>
      damagePartsData?.entities.map((e) => ({
        ...e,
        lineCost: e.price * e.quantity,
      })),
    [damagePartsData],
  );

  return (
    <DataGrid
      dataSource={dataSource}
      columnHidingEnabled
      allowColumnResizing
      allowColumnReordering
      columnAutoWidth
      showRowLines
      showBorders
      width="100%"
    >
      <LoadPanel enabled />
      <FilterRow visible />
      <Paging pageSize={PAGE_SIZE} />
      <Pager
        showInfo
        showNavigationButtons
        visible={isPagerVisible(PAGE_SIZE, dataSource?.length)}
      />
      <Editing mode="popup" confirmDelete={false} />

      <Column dataField="partNumber" caption={t('code')} />
      <Column dataField="description" caption={t('description')} />
      <Column dataField="quantity" caption={t('quantity')} />
      <Column
        dataField="price"
        caption={t('cost')}
        format={currencyFormatted}
      />
      <Column
        dataField="lineCost"
        caption={t('lineCost')}
        format={currencyFormatted}
      />
      <Column
        dataField="actions"
        minWidth={80}
        width={80}
        alignment="center"
        cellRender={renderActionsCell}
        allowEditing={false}
        allowSorting={false}
        allowFiltering={false}
        allowResizing={false}
      />
    </DataGrid>
  );
};
