import { getFormattedLabel } from '@/utils/helpers';
import { getValueOrNA } from '@/ui/components/NotApplicable';
import { Inspection } from '@/types';

import { TireGroup } from './types';

export const splitTireData = (
  inspection: Inspection,
): Record<string, TireGroup> => {
  const {
    spareTyreHeight,
    spareTyreWidth,
    spareRimRadius,
    spareTyreLoad,
    spareTyreSpeed,
    spareWheelDepth,
  } = inspection;

  const tireGroups = ['frontLeft', 'frontRight', 'rearLeft', 'rearRight'];

  const temp: Record<string, TireGroup> = {
    spareTyre: {
      location: getFormattedLabel(`spareTyreBrand`, 'TyreBrand'),
      brand: inspection.spareTyreBrand,
      type: inspection.spareWheelType,
      size: `${spareTyreHeight || 0}/${spareTyreWidth || 0} R${
        spareRimRadius || 0
      }
      ${spareTyreLoad || ''} ${spareTyreSpeed || ''} ${spareWheelDepth || ''}`,
      imageFileUrl: inspection.spareImageFileUrl,
      condition: getValueOrNA('spareTyreCondition')({
        data: inspection,
      }),
    },
  };

  return tireGroups.reduce((result, group) => {
    const copiedResult = result;

    const tyreHeight = inspection[`${group}TyreHeight` as keyof Inspection] || 0;
    const tyreWidth = inspection[`${group}TyreWidth` as keyof Inspection] || 0;
    const rimRadius = inspection[`${group}RimRadius` as keyof Inspection] || 0;
    const tyreLoad = inspection[`${group}TyreLoad` as keyof Inspection] || '';
    const tyreSpeed = inspection[`${group}TyreSpeed` as keyof Inspection] || '';
    const wheelDepth = inspection[`${group}WheelDepth` as keyof Inspection] || '';

    copiedResult[group] = {
      location: getFormattedLabel(`${group}TyreBrand`, 'TyreBrand'),
      brand: inspection[`${group}TyreBrand` as keyof Inspection] as string,
      type: inspection[`${group}WheelType` as keyof Inspection] as string,
      size: `${tyreHeight}/${tyreWidth} R${rimRadius} ${tyreLoad} ${tyreSpeed} ${wheelDepth}`,
      imageFileUrl: inspection[
        `${group}ImageFileUrl` as keyof Inspection
      ] as string,
      condition: getValueOrNA(
        inspection[`${group}Condition` as keyof Inspection] as string,
      )({
        data: inspection,
      }),
    };

    return copiedResult;
  }, temp);
};
