import React, { ReactNode } from 'react';
import { StyledButton } from './Button.styled';

export const Button = ({
  title,
  fullWidth,
  onClick,
  size,
  children,
}: {
  title?: string;
  fullWidth?: string;
  onClick?: () => void;
  size?: string;
  children?: ReactNode | undefined;
}) => (
  <StyledButton size={size || 'lg'} fullwidth={fullWidth} onClick={onClick}>
    {title || children}
  </StyledButton>
);
