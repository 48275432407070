import React from 'react';
import {
  Column,
  DataGrid,
  Editing,
  LoadPanel,
  Lookup,
  Pager,
  Form,
  RequiredRule,
  Sorting,
  FilterRow,
} from '@/ui';
import { isPagerVisible } from '@/utils/helpers';
import { CompaniesDataGridProps } from './types';

const PAGE_SIZE = 20;

export const CompaniesDataGrid: React.FC<CompaniesDataGridProps> = ({
  companies,
  countries,
  companyTypes,
  onCompanyAdd,
  onCompanyChanged,
}) => {
  const renderActiveCell = React.useCallback(
    (cell) => (
      <Form.Check
        className="dx-custom-switch"
        type="switch"
        checked={cell.data.isActive}
        readOnly
        disabled={!cell.data.isActive}
      />
    ),
    [],
  );

  const handleAddCompany = React.useCallback(
    (e) => {
      const { data } = e;
      onCompanyAdd(data);
    },
    [onCompanyAdd],
  );

  const handleEditCompany = React.useCallback(
    (e) => {
      const { newData, oldData } = e;
      onCompanyChanged(newData, oldData);
    },
    [onCompanyChanged],
  );

  return (
    <DataGrid
      id="gridContainer"
      columnHidingEnabled
      showBorders
      dataSource={companies}
      onRowUpdating={handleEditCompany}
      onRowInserted={handleAddCompany}
      rowAlternationEnabled
      allowColumnReordering
      width="60%"
    >
      <LoadPanel enabled />
      <Pager
        allowedPageSizes={PAGE_SIZE}
        showInfo
        showNavigationButtons
        visible={isPagerVisible(PAGE_SIZE, companies?.length)}
      />
      <Editing mode="form" allowAdding allowUpdating />
      <FilterRow visible />
      <Sorting mode="multiple" />
      <Column dataField="name" caption="Company Name">
        <RequiredRule />
      </Column>
      <Column dataField="code" caption="Company Code" width={200}>
        <RequiredRule />
      </Column>
      <Column dataField="country.id" caption="Country Description" width={250}>
        <Lookup
          dataSource={countries}
          displayExpr="name"
          valueExpr="id"
          allowClearing
        />
      </Column>
      <Column dataField="companyType.id" caption="Company Type" width={200}>
        <Lookup dataSource={companyTypes} displayExpr="name" valueExpr="id" />
        <RequiredRule />
      </Column>

      <Column
        dataField="isActive"
        caption="Active"
        width={100}
        cellRender={renderActiveCell}
        alignment="center"
      />
    </DataGrid>
  );
};
