import React, { FC, memo, useCallback } from 'react';
import { Popup, Button } from '@/ui';
import { useTranslate } from '@/translations';
import { PreviewModalProps } from '@/ui/components/PreviewModal/types';
import { PDFReader } from '@/ui/components/PdfReader';
import { ExcelReader } from '@/ui/components/ExcelReader';
import { downloadFile } from '@/utils/helpers';
import {
  TitleWrapper,
  Img,
} from '@/ui/components/PreviewModal/PreviewModal.styled';
import { NoPreview } from '@/ui/components/PreviewModal/NoPreview';
import { faClose, Icon } from '@/ui/components/Icon';
import { StyledLink, StyledLinkContainer } from '@/ui/globalStyles';
import { ImageSupportedExtensions, XLSXSupportedExtensions } from '@/utils/constants';

const getFileExtension = (fileName: string): string =>
  fileName.split('.').pop() || '';

const getComponentToRenderByExtension = (
  extension: string,
  src: string,
  fileName: string,
  closeModal: () => void,
) => {
  switch (true) {
    case extension === 'pdf':
      return () => <PDFReader src={src} />;
    case ImageSupportedExtensions.includes(extension):
      return () => <Img src={src} alt={fileName} />;
    case XLSXSupportedExtensions.includes(extension):
      return () => <ExcelReader src={src} />;
    default:
      // if extension is undefined or not handled
      return () => <NoPreview closeModal={closeModal} />;
  }
};

export const PreviewModal: FC<PreviewModalProps> = memo(
  ({ isOpen, closeModal, src, fileName }) => {
    const { t } = useTranslate();

    const extension = getFileExtension(fileName);

    const ComponentToRender = getComponentToRenderByExtension(
      extension,
      src,
      fileName,
      closeModal,
    );

    const titleRender = useCallback(
      () => (
        <TitleWrapper>
          <Button onClick={() => downloadFile(src, fileName)}>
            {t('download')}
          </Button>
          <StyledLinkContainer onClick={closeModal}>
            <StyledLink>
              <Icon icon={faClose} scale={2} />
            </StyledLink>
          </StyledLinkContainer>
        </TitleWrapper>
      ),
      [t, src, fileName, closeModal],
    );

    return (
      <Popup
        visible={isOpen}
        onHiding={closeModal}
        fullScreen
        titleRender={titleRender}
      >
        <ComponentToRender />
      </Popup>
    );
  },
);
