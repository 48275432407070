import React, { FC } from 'react';
import { useTheme } from 'styled-components';
import {
  Chart,
  CommonSeriesSettings,
  Export,
  Legend,
  Series,
  Title,
  ChartTooltip,
  ValueAxis,
} from '@/ui';
import { useTranslate } from '@/translations';
import { labelBackgroundColor } from '@/utils/helpers';
import { customizeTooltip } from './utils';
import { StorageChartProps } from './types';

export const StorageChart: FC<StorageChartProps> = ({ graphData }) => {
  const { t } = useTranslate();
  const { primaryChartColor, secondaryChartColor } = useTheme();

  return (
    <Chart
      id="StorageChart"
      title={t('storageUtilisation')}
      dataSource={graphData}
    >
      <CommonSeriesSettings argumentField="locationDesc" type="stackedBar" />
      <Series
        valueField="freeSlots"
        name={t('freeSlots')}
        color={primaryChartColor}
        label={labelBackgroundColor(primaryChartColor)}
      />
      <Series
        valueField="utilisation"
        name={t('utilisation')}
        color={secondaryChartColor}
        label={labelBackgroundColor(secondaryChartColor)}
      />
      <Export enabled />
      <Legend
        verticalAlignment="bottom"
        horizontalAlignment="center"
        itemTextPosition="top"
      />
      <ValueAxis>
        <Title text={t('numberOfVehicles')} />
      </ValueAxis>
      <ChartTooltip
        enabled
        location="edge"
        customizeTooltip={customizeTooltip}
      />
    </Chart>
  );
};
