import { useQuery } from 'react-query';
import { PostPassportBody } from '@/types';

import { QUERY_KEYS } from '../../queryKeys';
import { passport } from '../Passport';

type OptionsType = {
  enabled: boolean;
};

export const usePassports = (body: PostPassportBody, options?: OptionsType) =>
  useQuery(
    [QUERY_KEYS.passports, body],
    async () => (await passport.postPassports(body)).data,
    options,
  );

export const usePassportsTransactions = (body: PostPassportBody, options?: OptionsType) =>
  useQuery(
    [QUERY_KEYS.passportsTransactions, body],
    async () => (await passport.postPassportsTransactions(body)).data,
    options,
  );
