import React, { useCallback } from 'react';
import { useTheme } from 'styled-components';
import { useTranslate } from '@/translations';
import { PageContainer } from '@/ui/components/Page';
import { Title } from '@/ui/components/Title';
import { faUserPlus } from '@/ui/components/Icon';
import { Loader } from '@/ui/components/Loader';
import {
  useCreateUser,
  useDeleteUser,
  useInviteUser,
  useUpdateUser,
  useUsers,
} from '@/api/Users';
import { User } from '@/types';
import { useRoles } from '@/api/Roles';
import { useInspectionsVehiclesInspectors } from '@/api/Inspections';

import { ManageUsersDataGrid } from './components/ManageUsersDataGrid';
import { formatCreateUserData, formatUpdateUserData } from './utils';

export const ManageUsers = () => {
  const { t } = useTranslate();
  const { accent } = useTheme();
  const { data: userData, isLoading: isUserLoading } = useUsers();
  const { data: rolesData, isLoading: isRolesLoading } = useRoles();
  const { data: inspectorsData, isLoading: isInspectorsLoading } =
    useInspectionsVehiclesInspectors();
  const { mutate: createMutate, isLoading: isCreateLoading } = useCreateUser();
  const { mutate: updateMutate, isLoading: isUpdateLoading } = useUpdateUser();
  const { mutate: deleteMutate, isLoading: isDeleteLoading } = useDeleteUser();
  const { mutate: inviteMutate, isLoading: isInviteLoading } = useInviteUser();

  const handleCreateUser = useCallback(
    (data: User) => {
      const formattedData = formatCreateUserData(data);
      createMutate(formattedData);
    },
    [createMutate],
  );

  const handleUpdateUser = useCallback(
    (newData, oldData) => {
      const formattedData = formatUpdateUserData(newData, oldData);

      updateMutate(formattedData);
    },
    [updateMutate],
  );

  if (
    isUserLoading ||
    isRolesLoading ||
    isInspectorsLoading ||
    isUpdateLoading ||
    isCreateLoading ||
    isDeleteLoading ||
    isInviteLoading
  )
    return <Loader />;

  return (
    <PageContainer fluid>
      <Title title={t('manageUsers')} icon={faUserPlus} color={accent} />
      <ManageUsersDataGrid
        dataSource={userData?.entities}
        onAddUser={handleCreateUser}
        onUpdateUser={handleUpdateUser}
        onDeleteUser={deleteMutate}
        onInviteUser={inviteMutate}
        roles={rolesData?.entities}
        inspectors={inspectorsData?.entities}
      />
    </PageContainer>
  );
};
