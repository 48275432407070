import React, { FC, useEffect, useState } from 'react';
import { read, utils } from 'xlsx';
import { ScrollView } from '@/ui';
import { Loader } from '@/ui/components/Loader';
import { TableWrapper } from '@/ui/components/ExcelReader/ExcelReader.styled';
import { updateSheetRange } from '@/ui/components/ExcelReader/utils';

interface ExcelReaderProps {
  src: string
}

export const ExcelReader: FC<ExcelReaderProps> = ({ src }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  /* the component state is an HTML string */
  const [html, setHtml] = useState('');

  /* Fetch and update the state once */
  useEffect(() => {
    (async () => {
      if (!src) return;
      setIsLoading(true);
      const f = await (await fetch(src)).arrayBuffer();
      const wb = read(f); // parse the array buffer
      const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
      updateSheetRange(ws); // fixes the problem: "Worksheet only includes one row of data"
      const data = utils.sheet_to_html(ws); // generate HTML
      setHtml(data); // update state
      setIsLoading(false);
    })();
  }, [src]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ScrollView direction="both">
      <TableWrapper dangerouslySetInnerHTML={{ __html: html }} />
    </ScrollView>
  );
};
