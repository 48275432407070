import React, { FC, useCallback, useState } from 'react';
import { DevButton } from '@/ui';
import { PageContainer } from '@/ui/components/Page';
import { useTranslate } from '@/translations';

import { DamagePartsDataGridSection } from './DamagePartsDataGridSection';
import { DamagePartsProps } from './types';
import { AddDamagePartPopup } from '../AddDamagePartPopup/AddDamagePartPopup';

export const DamageParts: FC<DamagePartsProps> = ({ damageActionId, flatRateCode }) => {
  const { t } = useTranslate();

  const [isPopupVisible, setPopupVisibility] = useState(false);

  const togglePopup = useCallback(() => {
    setPopupVisibility(!isPopupVisible);
  }, [isPopupVisible]);

  return (
    <PageContainer fluid>
      <h2>{t('partNumbers')}</h2>

      <div className="my-3 d-flex justify-content-end">
        <DevButton stylingMode="outlined" icon="add" onClick={togglePopup} />
      </div>

      <DamagePartsDataGridSection damageActionId={damageActionId} />

      <AddDamagePartPopup
        vehicleDamageActionId={damageActionId}
        flatRateCode={flatRateCode}
        isPopupVisible={isPopupVisible}
        togglePopup={togglePopup}
      />
    </PageContainer>
  );
};
