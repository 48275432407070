import React from 'react';
import {
  Column,
  DataGrid,
  Editing,
  LoadPanel,
  Lookup,
  Pager,
  Form,
  RequiredRule,
  Sorting,
  FilterRow,
} from '@/ui';
import { isPagerVisible } from '@/utils/helpers';
import { TransportUnitsDataGridProps } from './types';

const PAGE_SIZE = 20;

export const TransportUnitsDataGrid: React.FC<TransportUnitsDataGridProps> = ({
  transportUnits,
  countries,
  serviceProviders,
  serviceUnitTypes,
  onTransportUnitAdd,
  onTransportUnitChanged,
}) => {
  const renderActiveCell = React.useCallback(
    (cell) => (
      <Form.Check
        className="dx-custom-switch"
        type="switch"
        checked={cell.data.isActive}
        readOnly
        disabled={!cell.data.isActive}
      />
    ),
    [],
  );

  const handleAddTransportUnit = React.useCallback(
    (e) => {
      const { data } = e;
      onTransportUnitAdd(data);
    },
    [onTransportUnitAdd],
  );

  const handleEditTransportUnit = React.useCallback(
    (e) => {
      const { newData, oldData } = e;
      onTransportUnitChanged(newData, oldData);
    },
    [onTransportUnitChanged],
  );

  return (
    <DataGrid
      id="gridContainer"
      columnHidingEnabled
      showBorders
      dataSource={transportUnits}
      onRowUpdating={handleEditTransportUnit}
      onRowInserted={handleAddTransportUnit}
      rowAlternationEnabled
      allowColumnReordering
      width="85%"
    >
      <LoadPanel enabled />
      <Pager
        allowedPageSizes={PAGE_SIZE}
        showInfo
        showNavigationButtons
        visible={isPagerVisible(PAGE_SIZE, transportUnits?.length)}
      />
      <Editing mode="form" allowAdding allowUpdating />
      <FilterRow visible />
      <Sorting mode="multiple" />
      <Column dataField="ref" caption="Unit ref" width={175} />
      <Column dataField="name" caption="Unit Name" width={275}>
        <RequiredRule />
      </Column>
      <Column dataField="country.id" caption="Country" width={250}>
        <Lookup
          dataSource={countries}
          displayExpr="name"
          valueExpr="id"
          allowClearing
        />
      </Column>
      <Column dataField="serviceUnitType.id" caption="Unit Type" width={150}>
        <Lookup
          dataSource={serviceUnitTypes}
          displayExpr="name"
          valueExpr="id"
          allowClearing
        />
      </Column>
      <Column dataField="serviceProvider.id" caption="Provider" width={250}>
        <Lookup
          dataSource={serviceProviders}
          displayExpr="name"
          valueExpr="id"
          allowClearing
        />
      </Column>
      <Column dataField="imo" caption="IMO" width={150} />
      <Column dataField="mmsi" caption="MMSI" width={150} />
      <Column
        dataField="isActive"
        caption="Active"
        width={100}
        cellRender={renderActiveCell}
        alignment="center"
      />
    </DataGrid>
  );
};
