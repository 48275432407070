import React from 'react';
import { useTheme } from 'styled-components';
import { useTranslate } from '@/translations';
import { Title } from '@/ui/components/Title';
import { faLocationDot } from '@/ui/components/Icon';
import { PageContainer } from '@/ui/components/Page';
import { Loader } from '@/ui/components/Loader';
import { Company } from '@/types';
import {
  useCreateCompany,
  useUpdateCompany,
  useCompanies,
} from '@/api/Companies';
import { useCountries } from '@/api/Countries';
import { useCompanyTypes } from '@/api/CompanyTypes';
import { CompaniesDataGrid } from './components/CompaniesDataGrid/CompaniesDataGrid';
import { formatUpdateCompanyData, formatCreateCompanyData } from './helpers';

export const AdminCompanies: React.FC = () => {
  const { t } = useTranslate();
  const { accent } = useTheme();

  const { data: companiesData, isLoading: isCompaniesLoading } = useCompanies();
  const { mutate: createMutate, isLoading: isCreateLoading } =
    useCreateCompany();
  const { mutate: updateMutate, isLoading: isUpdateLoading } =
    useUpdateCompany();

  const { data: countriesData, isLoading: isCountriesLoading } = useCountries();
  const { data: companyTypesData, isLoading: isCompanyTypesLoading } =
    useCompanyTypes();

  const handleCreateCompany = React.useCallback(
    (data: Company) => {
      const formattedData = formatCreateCompanyData(data);

      createMutate(formattedData);
    },
    [createMutate],
  );

  const handleUpdateCompany = React.useCallback(
    (newData: Partial<Company>, oldData: Company) => {
      const formattedData = formatUpdateCompanyData(newData, oldData);

      updateMutate(formattedData);
    },
    [updateMutate],
  );

  if (
    isCompaniesLoading ||
    isCreateLoading ||
    isUpdateLoading ||
    isCountriesLoading ||
    isCompanyTypesLoading
  )
    return <Loader />;

  return (
    <PageContainer fluid>
      <Title title={t('manageCompanies')} icon={faLocationDot} color={accent} />
      <CompaniesDataGrid
        onCompanyAdd={handleCreateCompany}
        onCompanyChanged={handleUpdateCompany}
        companies={companiesData?.entities}
        countries={countriesData?.entities}
        companyTypes={companyTypesData?.entities}
      />
    </PageContainer>
  );
};
