import styled from 'styled-components';
import { Container } from '@/ui';

export const ButtonsContainer = styled(Container)`
  padding: 8px 0;
  margin: 0;
  
  button {
    margin-right: 16px;
  }

  @media (max-width: 600px) {
    button {
      margin-bottom: 16px;
    }
  }
`;
