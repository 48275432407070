import { AxiosPromise } from 'axios';
import { ShippingPlanData } from '@/types';
import { Core, SHIPPING_PLAN, SHIPPING_PLANS } from '../Core';

interface UploadShippingPlanData {
  data: {
    shippingPlanFileBase64: ArrayBuffer | string | null;
    fileName?: string;
    tenantId?: string;
  };
}

type GetShippingPlan = (tenantId?: string) => AxiosPromise<ShippingPlanData>;

type UploadShippingPlan = (
  data: UploadShippingPlanData,
) => AxiosPromise<boolean>;

type DeleteShippingPlan = (documentId: number) => AxiosPromise<boolean>;

class ShippingPlan extends Core {
  getShippingPlan: GetShippingPlan = async (tenantId) => this.get(`${SHIPPING_PLANS(tenantId)}/shippingplans`);

  uploadShippingPlan: UploadShippingPlan = async ({
    data,
  }: UploadShippingPlanData) =>
    this.post<typeof data, AxiosPromise<boolean>>(SHIPPING_PLAN(), data);

  deleteShippingPlan: DeleteShippingPlan = async (documentId) =>
    this.delete<AxiosPromise>(SHIPPING_PLAN(documentId));
}

export const shippingPlan = new ShippingPlan();
