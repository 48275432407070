import React, { FC, useEffect } from 'react';
import { useTranslate } from '@/translations';
import { PageContent } from './Page.styled';

export const Page: FC = ({ children }) => {
  const { t } = useTranslate();

  useEffect(() => {
    document.title = t('title');
  }, [t]);

  return <PageContent>{children}</PageContent>;
};
