import { useTheme } from 'styled-components';
import { routes, systemAdministrationRoutes } from '@/routes';
import { useTranslate } from '@/translations';
import {
  faBriefcase,
  faBuilding,
  faCalendar,
  faCalendarDays,
  faCarBurst,
  faFileLines,
  faFileWaveform,
  faCog,
  faFile,
  faFileUpload,
  faHomeUser,
  faLocationDot,
  faMapMarkerAlt,
  faShip,
  faTruckFast,
  faUserPlus,
  faUsersGear,
  faUserTag,
  faUserPen,
  // faCar,
  // faTruck,
  faPassport,
  faListCheck,
  Icons,
  IconDefinition,
} from '@/ui/components/Icon';
import { useIdTokenClaims } from '@/hooks/useIdTokenClaims';
import { AppFeatures, AppRoles } from '@/types';
import { hasFeatureAccess, hasRoleAccess } from '@/utils/access';

type PageLinkInfo = {
  to: string;
  icon: IconDefinition | Icons;
  color: string;
  label: string;
};

type Pages = {
  appPages: PageLinkInfo[];
  systemAdministrationPages: PageLinkInfo[];
  adminPages: PageLinkInfo[];
};

export const useNavigation = () => {
  const theme = useTheme();
  const { t } = useTranslate();
  const idTokenClaims = useIdTokenClaims();
  const featuresDisabled = idTokenClaims?.featuresDisabled || [];
  const userRoles = idTokenClaims?.roles || [];
  const hasAdminAccess = hasRoleAccess(userRoles, [
    AppRoles.Admin,
    AppRoles.GbaMember,
    AppRoles.GbaAdmin,
    AppRoles.GbaSuperAdmin,
  ]);
  const hasGbaAdminAccess = hasRoleAccess(userRoles, [
    AppRoles.GbaAdmin,
    AppRoles.GbaSuperAdmin,
  ]);

  const pages: Pages = {
    appPages: [],
    systemAdministrationPages: [
      {
        to: systemAdministrationRoutes.locations,
        icon: faLocationDot,
        color: theme.defaultLink,
        label: t('locations'),
      },
      {
        to: systemAdministrationRoutes.transportUnits,
        icon: faTruckFast,
        color: theme.defaultLink,
        label: t('transportUnits'),
      },
      {
        to: systemAdministrationRoutes.companies,
        icon: faBriefcase,
        color: theme.defaultLink,
        label: t('companies'),
      },
      {
        to: systemAdministrationRoutes.shippingPlan,
        icon: faCalendarDays,
        color: theme.defaultLink,
        label: t('shippingPlan'),
      },
      {
        to: systemAdministrationRoutes.tenantCustomization,
        icon: faHomeUser,
        color: theme.defaultLink,
        label: t('tenantCustomization'),
      },
      {
        to: systemAdministrationRoutes.checklist,
        icon: faListCheck,
        color: theme.defaultLink,
        label: t('checklist'),
      },
      {
        to: systemAdministrationRoutes.damages,
        icon: faCarBurst,
        color: theme.defaultLink,
        label: t('damage'),
      },
    ],
    adminPages: [],
  };

  if (hasFeatureAccess(featuresDisabled, AppFeatures.Storage)) {
    pages.appPages.push({
      to: routes.storage,
      // icon: faCar,
      icon: Icons.compound,
      color: theme.defaultIconLinkColour,
      label: t('compoundStorage'),
    });
  }

  if (hasFeatureAccess(featuresDisabled, AppFeatures.TransportMovement)) {
    pages.appPages.push({
      to: routes.transportSummary,
      // icon: faTruck,
      icon: Icons.shortTransporter,
      color: theme.defaultIconLinkColour,
      label: t('transportSummary'),
    });
  }

  if (hasFeatureAccess(featuresDisabled, AppFeatures.Vessel)) {
    pages.appPages.push({
      to: routes.vessels,
      icon: faShip,
      color: theme.defaultIconLinkColour,
      label: t('vesselsSummary'),
    });
  }

  if (hasFeatureAccess(featuresDisabled, AppFeatures.Passport)) {
    pages.appPages.push({
      to: routes.passport,
      icon: faPassport,
      color: theme.defaultIconLinkColour,
      label: t('vehiclePassport'),
    });
  }

  if (hasFeatureAccess(featuresDisabled, AppFeatures.StockSheet)) {
    pages.appPages.push({
      to: routes.pts,
      icon: faBuilding,
      color: theme.defaultIconLinkColour,
      label: t('pts'),
    });
  }

  if (hasFeatureAccess(featuresDisabled, AppFeatures.VinTracking)) {
    pages.appPages.push({
      to: routes.vin,
      icon: faMapMarkerAlt,
      color: theme.defaultIconLinkColour,
      label: t('vinTracking'),
    });
  }

  if (hasFeatureAccess(featuresDisabled, AppFeatures.Order)) {
    pages.appPages.push({
      to: routes.order,
      icon: faFile,
      color: theme.defaultIconLinkColour,
      label: t('orderSummary'),
    });
  }

  if (hasAdminAccess) {
    pages.appPages.push({
      to: routes.orderAdmin,
      icon: faFileUpload,
      color: theme.defaultIconLinkColour,
      label: t('orderSummaryAdmin'),
    });
  }

  if (hasFeatureAccess(featuresDisabled, AppFeatures.ShippingPlan)) {
    pages.appPages.push({
      to: routes.shippingPlan,
      icon: faCalendar,
      color: theme.defaultIconLinkColour,
      label: t('shippingPlan'),
    });
  }

  if (hasFeatureAccess(featuresDisabled, AppFeatures.Inspections)) {
    pages.appPages.push({
      to: routes.inspections,
      icon: faCarBurst,
      color: theme.defaultIconLinkColour,
      label: t('inspectionSearch'),
    });
  }

  if (hasFeatureAccess(featuresDisabled, AppFeatures.DocumentManagement)) {
    pages.appPages.push({
      to: routes.documentManagement,
      icon: faFileLines,
      color: theme.defaultIconLinkColour,
      label: t('documentManagement'),
    });
  }

  if (hasAdminAccess) {
    pages.appPages.push({
      to: routes.adminDocumentManagement,
      icon: faFileWaveform,
      color: theme.defaultIconLinkColour,
      label: t('adminDocumentManagement'),
    });
  }

  if (hasAdminAccess) {
    pages.appPages.push({
      to: routes.systemAdministration,
      icon: faCog,
      color: theme.defaultIconLinkColour,
      label: t('systemAdministration'),
    });
  }

  if (hasGbaAdminAccess) {
    pages.appPages.push({
      to: routes.tenantManagement,
      icon: faUsersGear,
      color: theme.defaultIconLinkColour,
      label: t('tenantManagement'),
    });

    pages.adminPages.push({
      to: routes.manageGBAUsers,
      icon: faUserTag,
      color: theme.defaultIconLinkColour,
      label: t('manageGBAUsers'),
    });

    pages.adminPages.push({
      to: routes.manageTMSUsers,
      icon: faUserPen,
      color: theme.defaultIconLinkColour,
      label: t('manageTMSUsers'),
    });
  }

  if (hasAdminAccess) {
    pages.adminPages.push({
      to: routes.manageUsers,
      icon: faUserPlus,
      color: theme.defaultIconLinkColour,
      label: t('manageUsers'),
    });
  }

  return { pages };
};
