import React, { FC } from 'react';
import {
  Column,
  DataGrid,
  Export,
  LoadPanel,
  MasterDetail,
  Paging,
  Pager,
} from '@/ui';
import { isPagerVisible } from '@/utils/helpers';
import { OrderGridDetailSection } from '../OrderGridDetailSection';
import { FormattedOrderSummaryData } from '../../types';
import { OrderDataGridProps } from './types';
import { handleExport } from './export';

const PAGE_SIZE = 20;

export const OrderDataGrid: FC<OrderDataGridProps> = ({ dataSource }) => (
  <DataGrid
    id="gridContainer"
    dataSource={dataSource}
    keyExpr="companyId"
    columnHidingEnabled
    width="100%"
    showBorders
    allowColumnReordering
    onExporting={handleExport}
  >
    <LoadPanel enabled />
    <Paging pageSize={PAGE_SIZE} />
    <Pager
      showInfo
      showNavigationButtons
      visible={isPagerVisible(PAGE_SIZE, dataSource?.length)}
    />
    <Column dataField="companyId" minWidth={20} visible={false} />
    <Column dataField="consigneeCode" minWidth={20} />
    <Column dataField="country" minWidth={20} />
    <MasterDetail
      enabled
      render={(row: { data: FormattedOrderSummaryData }) => (
        <OrderGridDetailSection dataSource={row.data.items} />
      )}
    />
    <Export enabled />
  </DataGrid>
);
