import { Core, TENANT_BRANDING } from '@/api/Core';
import { UpdateTenantBrandingData } from '@/types';
import { AxiosPromise } from 'axios';

type UpdateTenantBranding = (
  data: UpdateTenantBrandingData,
) => AxiosPromise<boolean>;

class TenantCustomization extends Core {
  updateTenantBranding: UpdateTenantBranding = async (data) =>
    this.patch<UpdateTenantBrandingData, AxiosPromise<boolean>>(
      TENANT_BRANDING(),
      data,
    );
}

export const tenantCustomization = new TenantCustomization();
