import React from 'react';
import { PageContainer } from '@/ui/components/Page';
import { Loader } from '@/ui/components/Loader';
import { useInspectionsLogs } from '@/api/Inspections';
import { EventViewerDataGrid } from './components/EventViewerDataGrid';

export const EventViewer = () => {
  const { data, isLoading } = useInspectionsLogs();

  if (isLoading) return <Loader />;

  const dataSource = data?.entities.map(({ spUser, ...rest }) => ({
    spUser: spUser === 'undefined' ? '' : spUser,
    ...rest,
  }));

  return (
    <PageContainer fluid>
      <EventViewerDataGrid dataSource={dataSource} />
    </PageContainer>
  );
};
