import React, { FC, useCallback, useMemo } from 'react';
import {
  Column,
  DataGrid,
  LoadPanel,
  MasterDetail,
  Paging,
  Pager,
  FilterRow,
  SearchPanel,
  Grouping,
} from '@/ui';
import { faDownload, faEye, Icon } from '@/ui/components/Icon';
import { StyledLink, StyledLinkContainer } from '@/ui/globalStyles';
import {
  filterCustomerPreviewRestrictedItems,
  isPagerVisible,
} from '@/utils/helpers';
import { usePreviewModal } from '@/ui/components/PreviewModal/usePreviewModal';
import { FORMAT_DATE_WITH_TIME } from '@/utils/constants';
import { DocumentManagementDataGridProps } from '@/modules/DocumentManagement/components/types';
import { RelatedDocumentsDataGrid } from '@/modules/DocumentManagement/components/RelatedDocumentsDataGrid';
import { useSearchParams } from 'react-router-dom';

const PAGE_SIZE = 16;

export const DocumentManagementDataGrid: FC<
  DocumentManagementDataGridProps
> = ({ documents }) => {
  const [searchParams] = useSearchParams();
  const { openPreviewModal, RenderPreviewModal } = usePreviewModal();

  const onRenderActionsCell = useCallback(
    ({ data: { fileUrl, fileName } }) => (
      <StyledLinkContainer>
        <StyledLink
          onClick={() => openPreviewModal({ src: fileUrl, fileName })}
          aria-label="View"
        >
          <Icon icon={faEye} />
        </StyledLink>
        <a href={fileUrl} download={fileName} aria-label="Download">
          <Icon icon={faDownload} />
        </a>
      </StyledLinkContainer>
    ),
    [openPreviewModal],
  );

  // doing it because of searching purposes, FilterRow using "contains" only for strings
  const dataSource = useMemo(
    () =>
      documents
        ?.filter(filterCustomerPreviewRestrictedItems)
        .map((document) => ({
          ...document,
          relatedDocumentsString: document.relatedDocuments.join(', '),
        })),
    [documents],
  );

  const searchId = useMemo(() => searchParams.get('id'), [searchParams]);

  return (
    <>
      <DataGrid
        id="gridContainer"
        dataSource={dataSource}
        keyExpr="documentId"
        showBorders
        allowColumnReordering
      >
        <LoadPanel enabled />
        <Grouping autoExpandAll={false} />
        <FilterRow visible />
        <SearchPanel visible />
        <MasterDetail
          enabled
          render={({ data }) => (
            <RelatedDocumentsDataGrid data={data} documents={documents} />
          )}
        />
        <Paging pageSize={PAGE_SIZE} />
        <Pager
          showInfo
          showNavigationButtons
          visible={isPagerVisible(PAGE_SIZE, documents?.length)}
        />
        <Column
          dataField="relatedDocumentsString"
          caption="Associated Documents"
          minWidth={200}
          visible={false}
        />
        <Column
          dataField="documentId"
          minWidth={300}
          width={300}
          alignment="center"
          visible={false}
        />
        <Column dataField="documentType" />
        <Column dataField="entity" />
        <Column
          dataField="entityReference"
          defaultSelectedFilterOperation="="
          defaultFilterValue={searchId}
        />
        <Column dataField="origin" />
        <Column dataField="destination" />
        <Column
          dataField="createdDate"
          dataType="datetime"
          minWidth={200}
          width={200}
          format={FORMAT_DATE_WITH_TIME}
        />
        <Column
          dataField="actions"
          allowFiltering={false}
          minWidth={100}
          width={100}
          alignment="center"
          cellRender={onRenderActionsCell}
        />
      </DataGrid>

      <RenderPreviewModal />
    </>
  );
};
