import React, { FC, useCallback, useState } from 'react';
import {
  DataGrid,
  LoadPanel,
  Paging,
  Pager,
  FilterRow,
  Column,
  Export,
  MasterDetail,
  Grouping,
  exportDataGrid,
  Workbook,
  ExcelRow,
  saveAs,
} from '@/ui';
import { Storage } from '@/types';
import { isPagerVisible } from '@/utils/helpers';
import { storageExcelConfiguration } from './storageExcelConfiguration';
import { StorageGridDetailSection } from '../StorageGridDetailSection';
import {
  getStorageItem,
  getStorageItems,
  getUniqueStorageItem,
  insertRow,
} from './utils';
import { MasterRow, StorageDataGridProps, ExportingEvent } from './types';

const PAGE_SIZE = 10;

export const StorageDataGrid: FC<StorageDataGridProps> = ({ storageData }) => {
  const [dataSource] = useState<Storage[]>(() => {
    if (storageData) {
      return getUniqueStorageItem(storageData);
    }
    return [];
  });

  const storageDataGridSection = useCallback(
    ({ data }) => (
      <StorageGridDetailSection data={data} storageData={storageData} />
    ),
    [storageData],
  );

  const onExporting = (e: ExportingEvent<Storage>) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    const masterRows: MasterRow[] = [];

    exportDataGrid({
      component: e.component,
      worksheet,
      topLeftCell: { row: 1, column: 1 },
      customizeCell: ({ gridCell, excelCell }) => {
        if (
          gridCell?.column?.dataField === 'locationCode' &&
          gridCell.rowType === 'data'
        ) {
          masterRows.push({
            rowIndex: excelCell.fullAddress.row + 1,
            data: gridCell.data,
          });
        }
      },
    })
      .then((cellRange) => {
        // HEADER TITLE CONFIGURATION
        const headerTitle = worksheet.getRow(1);
        headerTitle.height = storageExcelConfiguration.height;
        // @ts-ignore
        headerTitle.fill = storageExcelConfiguration.fill;
        // @ts-ignore
        headerTitle.border = storageExcelConfiguration.border;
        // @ts-ignore
        headerTitle.alignment = storageExcelConfiguration.alignment;

        let offset = 0;

        for (let i = 0; i < masterRows.length; i += 1) {
          let row: ExcelRow;
          // @ts-ignore
          const columnIndex = cellRange.from.column + 1;
          const storageItemData = getStorageItem(dataSource, masterRows, i);

          // MASTER GRID HEADER TITLE CONFIGURATION
          row = insertRow(
            worksheet,
            masterRows[i].rowIndex + i,
            // eslint-disable-next-line no-plusplus
            offset++,
            2,
          );
          row.height = storageExcelConfiguration.height;
          // MASTER GRID ROW CONFIGURATION
          Object.values(storageExcelConfiguration.columns).forEach(
            (columnName, currentColumnIndex) => {
              Object.assign(row.getCell(columnIndex + currentColumnIndex), {
                value: columnName,
                fill: storageExcelConfiguration.fill,
                border: storageExcelConfiguration.border,
                alignment: storageExcelConfiguration.alignment,
                font: storageExcelConfiguration.font,
              });
            },
          );

          getStorageItems(storageItemData?.locationId!, storageData).forEach(
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            (storage: Storage) => {
              // MASTER GRID ITEM ROWS
              row = insertRow(
                worksheet,
                masterRows[i].rowIndex + i,
                // eslint-disable-next-line no-plusplus
                offset++,
                2,
              );

              // MASTER GRID ITEM COLUMNS
              Object.keys(storageExcelConfiguration.columns).forEach(
                (columnName, currentColumnIndex) => {
                  Object.assign(row.getCell(columnIndex + currentColumnIndex), {
                    value: storage[columnName as keyof Storage],
                  });
                },
              );
            },
          );
          // eslint-disable-next-line no-plusplus
          offset--;
        }
      })
      .then(() => {
        workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            'Compound Storage.xlsx',
          );
        });
      });
    e.cancel = true;
  };

  return (
    <DataGrid
      id="gridContainer"
      dataSource={dataSource}
      keyExpr="locationId"
      columnHidingEnabled
      width="100%"
      showBorders
      allowColumnReordering
      onExporting={onExporting}
    >
      <FilterRow visible />
      <Grouping autoExpandAll={false} />
      <LoadPanel enabled />
      <Paging pageSize={PAGE_SIZE} />
      <Pager
        showInfo
        showNavigationButtons
        visible={isPagerVisible(PAGE_SIZE, dataSource?.length)}
      />
      <Column
        dataField="locationId"
        visible={false}
        minWidth={20}
        alignment="left"
      />
      <Column
        dataField="locationCode"
        minWidth={20}
        width="auto"
        alignment="left"
      />
      <Column
        dataField="locationDesc"
        caption="Location Description"
        minWidth={60}
        alignment="left"
      />
      <Column dataField="capacity" minWidth={20} width={120} alignment="left" />
      <Column
        dataField="utilisation"
        minWidth={30}
        width={120}
        alignment="left"
      />
      <Column
        dataField="freeSlots"
        caption="Free Slots"
        minWidth={30}
        width={120}
        alignment="left"
      />
      <MasterDetail enabled render={storageDataGridSection} />
      <Export enabled />
    </DataGrid>
  );
};
