import { Statuses } from './types';

export const STATUSES: Statuses[] = [
  {
    id: 1,
    next: [
      { id: 2, text: 'Awaiting Authorisation' },
      { id: 3, text: 'Requires Parts Pricing' },
    ],
  },
  {
    id: 2,
    next: [
      { id: 1, text: 'Inspected' },
      { id: 3, text: 'Requires Parts Pricing' },
      { id: 4, text: 'Requires Review' },
      { id: 5, text: 'Requires Re-submission Review' },
      { id: 7, text: 'Auth Rejected' },
    ],
  },
  {
    id: 3,
    next: [
      { id: 1, text: 'Inspected' },
      { id: 2, text: 'Awaiting Authorisation' },
    ],
  },
  {
    id: 4,
    next: [{ id: 2, text: 'Awaiting Authorisation' }],
  },
  {
    id: 5,
    next: [
      { id: 2, text: 'Awaiting Authorisation' },
      { id: 6, text: 'Authorised' },
    ],
  },
  {
    id: 6,
    next: [
      { id: 5, text: 'Requires Re-submission Review' },
      { id: 8, text: 'Completed' },
    ],
  },
  {
    id: 7,
    next: [
      { id: 2, text: 'Awaiting Authorisation' },
      { id: 8, text: 'Completed' },
    ],
  },
  {
    id: 8,
    next: [
      { id: 5, text: 'Requires Re-submission Review' },
      { id: 6, text: 'Authorised' },
    ],
  },
];
