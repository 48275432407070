import { TransportMovement, TransportPosition } from '@/types';
import {
  FormattedTransportMovementData,
  TransportMovementFiltersInitData,
  TransportMovementFiltersValues,
} from './types';

const removeSpace = (value: string) => value.replace(/ /g, '');

const findTransportRowIndex = (
  rows: FormattedTransportMovementData[],
  item: TransportMovement,
) =>
  rows.findIndex(
    (row) =>
      row.movementRef === item.movementRef &&
      row.despatchDate === item.despatchDate &&
      row.locationFromCity === item.locationFromCity &&
      row.locationToCity === item.locationToCity &&
      row.statusDesc === item.statusDesc &&
      row.providerName === item.providerName &&
      row.unitName === item.unitName,
  );

export const formatTransportMovements = (
  transportMovementsEntities: TransportMovement[],
) =>
  transportMovementsEntities.reduce((result, item) => {
    const transportRowIndex = findTransportRowIndex(result, item);
    const {
      movementId,
      movementRef,
      despatchDate,
      locationFromCity,
      locationToCity,
      movementStatusCode,
      statusDesc,
      providerName,
      unitName,
      orderReference,
      serialNumber,
      productCode,
      productDesc,
      colourCode,
      colourDesc,
      etaDate,
      etdDate,
      loadLane,
    } = item;
    if (transportRowIndex === -1) {
      return [
        ...result,
        {
          movementId,
          movementRef,
          despatchDate,
          locationFromCity,
          locationToCity,
          movementStatusCode,
          statusDesc,
          providerName,
          unitName,
          quantity: [item].length,
          orderReference,
          serialNumber,
          productCode,
          productDesc,
          colourCode,
          colourDesc,
          etaDate,
          etdDate,
          loadLane,
          items: [item],
        },
      ];
    }
    const updatedResult = [...result];
    updatedResult[transportRowIndex].items.push(item);
    updatedResult[transportRowIndex].quantity =
      updatedResult[transportRowIndex].items.length;

    return updatedResult;
  }, [] as FormattedTransportMovementData[]);

export const getTransportMovementsFilters = (
  transportMovementsData: FormattedTransportMovementData[],
) => {
  const initialFiltersData: TransportMovementFiltersInitData = {
    locationFromItems: [],
    locationToItems: [],
  };

  return transportMovementsData.reduce((result, item) => {
    const updatedResult = { ...result };

    if (!result.locationFromItems.includes(item.locationFromCity)) {
      updatedResult.locationFromItems.push(item.locationFromCity);
    }

    if (!result.locationToItems.includes(item.locationToCity)) {
      updatedResult.locationToItems.push(item.locationToCity);
    }

    return updatedResult;
  }, initialFiltersData);
};

export const formatDate = (dateValue?: string) => {
  if (!dateValue) {
    return undefined;
  }
  const date = new Date(dateValue);
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);

  return `${year}-${month}-${day}`;
};

export const filterTransportMovements = (
  transportMovementsData: FormattedTransportMovementData[],
  selectedFilters: TransportMovementFiltersValues,
) =>
  transportMovementsData.filter((item) => {
    const comparisonResults: boolean[] = [];

    if (selectedFilters.locationFrom) {
      comparisonResults.push(
        selectedFilters.locationFrom === item.locationFromCity,
      );
    }

    if (selectedFilters.locationTo) {
      comparisonResults.push(
        selectedFilters.locationTo === item.locationToCity,
      );
    }

    if (selectedFilters.despatchDate) {
      comparisonResults.push(
        selectedFilters.despatchDate === formatDate(item.despatchDate),
      );
    }

    return !comparisonResults.includes(false);
  });

export const getFilteredTransportPositions = (
  transportPositionsEntities: TransportPosition[],
  selectedUnitName: string[] | null,
  filteredTransportMovements: FormattedTransportMovementData[],
) =>
  selectedUnitName
    ? transportPositionsEntities.filter((position) =>
        selectedUnitName.some(
          (unitName) => unitName === removeSpace(position.truckReg),
        ),
      )
    : transportPositionsEntities.filter((position) =>
        filteredTransportMovements
          .map(({ unitName }) => unitName)
          .some((unitName) => unitName === removeSpace(position.truckReg)),
      );
