import { utils, WorkSheet } from 'xlsx';

// fixes the problem: "Worksheet only includes one row of data"
// https://docs.sheetjs.com/docs/miscellany/errors#worksheet-only-includes-one-row-of-data
export const updateSheetRange = (ws: WorkSheet) => {
  const range = { s: { r: Infinity, c: Infinity }, e: { r: 0, c: 0 } };
  Object.keys(ws)
    .filter((x) => x.charAt(0) !== '!')
    .map(utils.decode_cell)
    .forEach((x) => {
      range.s.c = Math.min(range.s.c, x.c);
      range.s.r = Math.min(range.s.r, x.r);
      range.e.c = Math.max(range.e.c, x.c);
      range.e.r = Math.max(range.e.r, x.r);
    });
  /* eslint-disable no-param-reassign */
  ws['!ref'] = utils.encode_range(range);
}