import styled from 'styled-components';
import { Row } from '@/ui';

export const TitleRow = styled(Row)`
  align-items: baseline;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const MainIcon = styled.span`
  color: ${({ color, theme }) =>
    color ? `${color}` : `${theme.mainIconColor}`};
`;

export const TitleText = styled.h2`
  color: ${({ theme }) => theme.accent};
  font-size: 32px !important;
  margin: 0;
  font-weight: 500 !important;
  line-height: 1.2;
`;

export const Text = styled.span`
  margin-left: 8px;
`;

export const AdditionalIcon = styled.span`
  font-size: 40px;
  color: ${({ theme }) => theme.additionalIconColor};
  line-height: 1;
`;
