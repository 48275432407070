import { CreateLocation, UpdateLocation, Location } from '@/types';

export const formatUpdateLocationData = (
  newData: Partial<Location>,
  oldData: Location,
): UpdateLocation => ({
  id: oldData.id,
  capacity: {
    isChanged: newData.capacity !== undefined,
    value: newData.capacity ?? oldData.capacity,
  },
  city: {
    isChanged: newData.city !== undefined,
    value: newData.city ?? oldData.city,
  },
  countryId: {
    isChanged: newData.country?.id !== undefined,
    value: newData.country?.id ?? oldData.country.id,
  },
  desc: {
    isChanged: newData.desc !== undefined,
    value: newData.desc ?? oldData.desc,
  },
  isActive: {
    isChanged: newData.isActive !== undefined,
    value: !!(newData.isActive ?? oldData.isActive),
  },
  locationTypeId: {
    isChanged: newData.locationType?.id !== undefined,
    value: newData.locationType?.id ?? oldData.locationType.id,
  },
  postCode: {
    isChanged: newData.postCode !== undefined,
    value: newData.postCode ?? oldData.postCode,
  },
  code: {
    isChanged: false,
    value: null,
  },
  latitude: {
    isChanged: false,
    value: null,
  },
  longitude: {
    isChanged: false,
    value: null,
  },
  sort: {
    isChanged: false,
    value: null,
  },
  uncode: {
    isChanged: false,
    value: null,
  },
  icoportCode: {
    isChanged: false,
    value: null,
  },
  isPipeLine: {
    isChanged: false,
    value: null,
  },
  pipeLineSortOrder: {
    isChanged: false,
    value: null,
  },
  isCompoundStorage: {
    isChanged: false,
    value: null,
  },
});

export const formatCreateLocationData = (data: Location): CreateLocation => ({
  locationTypeId: data.locationType.id,
  desc: data.desc,
  capacity: data.capacity,
  countryId: data.country.id,
  city: data.city,
  postCode: data.postCode,
  isActive: data.isActive ?? false,
});
