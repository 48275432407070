import { AxiosPromise } from 'axios';
import {
  LocationsData,
  UpdateLocation,
  CreateLocation,
  LocationsSitesData,
} from '@/types';
import { Core, LOCATIONS, LOCATIONS_SITES } from '../Core';

type GetLocations = () => AxiosPromise<LocationsData>;
type UpdateLocations = (data: UpdateLocation) => AxiosPromise<boolean>;
type CreateLocations = (data: CreateLocation) => AxiosPromise<boolean>;
type GetLocationsSites = (tenantId: number) => AxiosPromise<LocationsSitesData>;

class Locations extends Core {
  getLocations: GetLocations = async () => this.get(LOCATIONS());

  updateLocations: UpdateLocations = async (data) =>
    this.patch<UpdateLocation, AxiosPromise<boolean>>(LOCATIONS(), data);

  createLocations: CreateLocations = async (data) =>
    this.put<CreateLocation, AxiosPromise<boolean>>(LOCATIONS(), data);

  getLocationsSites: GetLocationsSites = async (tenantId: number) =>
    this.get(LOCATIONS_SITES(tenantId));
}

export const locations = new Locations();
