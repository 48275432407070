import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  Col,
  Column,
  DataGrid,
  Editing,
  LoadPanel,
  Pager,
  Paging,
  Lookup,
  RequiredRule,
  EmailRule,
  FilterRow,
  Sorting,
  MasterDetail,
} from '@/ui';
import { StyledLink, StyledLinkContainer } from '@/ui/globalStyles';
import { faEnvelope, Icon } from '@/ui/components/Icon';
import { isPagerVisible } from '@/utils/helpers';
import { Status } from '@/types/Users/Users';
import {
  useAssignGBAUserToTenant,
  useUnassignGBAUserToTenant,
} from '@/api/Users';
import { ManageGBAUsersDataGridProps } from './types';
import { MasterDetailView } from '../MasterDetailView/MasterDetailView';

const PAGE_SIZE = 16;

export const ManageGBAUsersDataGrid: FC<ManageGBAUsersDataGridProps> = ({
  users,
  tenants,
  onAddUser,
  onUpdateUser,
  onDeleteUser,
  onInviteUser,
  roles,
  inspectors,
}) => {
  const [isShowEmailField, setIsShowEmailField] = useState(true);

  const { mutate: assignGBAUserMutate, isLoading: isAssignGBAUserLoading } =
    useAssignGBAUserToTenant();
  const { mutate: unassignGBAUserMutate, isLoading: isUnassignGBAUserLoading } =
    useUnassignGBAUserToTenant();

  const handleHideField = useCallback(() => setIsShowEmailField(false), []);
  const handleShowField = useCallback(() => setIsShowEmailField(true), []);

  const handleAddUser = useCallback(({ data }) => onAddUser(data), [onAddUser]);

  const handleEditUser = useCallback(
    ({ oldData, newData }) => onUpdateUser(newData, oldData),
    [onUpdateUser],
  );

  const handleDeleteUser = useCallback(
    ({ key }) => {
      onDeleteUser({ gbaUserId: key });
    },
    [onDeleteUser],
  );

  const onRenderCell = useCallback(
    ({ key, data }) => {
      if (data.status === Status.active) return null;

      return (
        <StyledLinkContainer>
          <StyledLink onClick={() => onInviteUser(key)}>
            <Icon icon={faEnvelope} />
          </StyledLink>
        </StyledLinkContainer>
      );
    },
    [onInviteUser],
  );

  const inspectorsDataSource = useMemo(
    () =>
      inspectors?.map(({ id, inspectorSurname, inspectorForenames }) => ({
        id,
        displayExpr: `${inspectorForenames} ${inspectorSurname}`,
      })),
    [inspectors],
  );

  return (
    <Col xxl={8} xl={10} xs={12}>
      <DataGrid
        id="gridContainer"
        keyExpr="id"
        dataSource={users}
        onRowUpdating={handleEditUser}
        onRowInserted={handleAddUser}
        onRowRemoving={handleDeleteUser}
        onEditingStart={handleHideField}
        onInitNewRow={handleShowField}
        columnHidingEnabled
        width="100%"
        showBorders
        allowColumnReordering
      >
        <FilterRow visible />
        <Sorting mode="multiple" />
        <LoadPanel enabled />
        <Paging pageSize={PAGE_SIZE} />
        <Pager
          showInfo
          showNavigationButtons
          visible={isPagerVisible(PAGE_SIZE, users?.length)}
        />
        <Editing
          mode="form"
          allowAdding
          allowUpdating
          allowDeleting
          confirmDelete={false}
        />
        <Column dataField="displayName" caption="Username" minWidth={20}>
          <RequiredRule />
        </Column>
        <MasterDetail
          enabled
          render={({ data: { id } }) => (
            <MasterDetailView
              gbaUserId={id}
              tenants={tenants}
              onAssignTenant={assignGBAUserMutate}
              onUnassignTenant={unassignGBAUserMutate}
              isLoading={isAssignGBAUserLoading || isUnassignGBAUserLoading}
            />
          )}
        />
        <Column
          dataField="email"
          minWidth={20}
          formItem={{ visible: isShowEmailField }}
        >
          <RequiredRule />
          <EmailRule />
        </Column>
        <Column dataField="role.id" caption="Role" width={150}>
          <Lookup dataSource={roles} displayExpr="name" valueExpr="id" />
          <RequiredRule />
        </Column>
        <Column dataField="inspectorId" caption="Inspector">
          <Lookup
            dataSource={inspectorsDataSource}
            displayExpr="displayExpr"
            valueExpr="id"
          />
        </Column>
        <Column dataField="status" width={150} formItem={{ visible: false }} />
        <Column
          caption="Invitation"
          width={100}
          formItem={{ visible: false }}
          allowFiltering={false}
          alignment="center"
          cellRender={onRenderCell}
        />
      </DataGrid>
    </Col>
  );
};
