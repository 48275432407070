import { StatusDetails } from "@/types/Pipelines/StatusDetails";
import { FormattedStatusData, LocationFiltersInitData, LocationFiltersValues } from "./types";

const findStatusRowIndex = (
    rows: FormattedStatusData[],
    item: StatusDetails,
  ) =>
    rows.findIndex(
      (row) =>
        row.statusCode === item.statusCode &&
        row.statusDesc === item.statusDesc,
    );
    
export const formatStatusData = (
statusEntities: StatusDetails[],
) =>
statusEntities.reduce((result, item) => {
    const statusRowIndex = findStatusRowIndex(result, item);
    const {
    statusCode,
    statusDesc,
    numberOfVehicles,
    serialNumber,
    productDesc,
    colourDesc,
    locationCity,
    } = item;
    if (statusRowIndex === -1) {
    return [
        ...result,
        {
        statusCode,
        statusDesc,
        numberOfVehicles,
        serialNumber,
        productDesc,
        colourDesc,
        locationCity,
        quantity: [item].length,
        items: [item],
        },
    ];
    }
    const updatedResult = [...result];
    updatedResult[statusRowIndex].items.push(item);
    updatedResult[statusRowIndex].quantity =
    updatedResult[statusRowIndex].items.length;

    return updatedResult;
}, [] as FormattedStatusData[]);


export const getStatusFilters = (
    statusData: FormattedStatusData[],
    ) => {
    const initialFiltersData: LocationFiltersInitData = {
        location: [],
    };
    
    return statusData.reduce((result, item) => {
        const updatedResult = { ...result };
    
        if (!result.location.includes(item.locationCity)) {
        updatedResult.location.push(item.locationCity);
        }
    
        return updatedResult;
    }, initialFiltersData);
};

export const formatDate = (dateValue?: string) => {
    if (!dateValue) {
        return undefined;
    }
    const date = new Date(dateValue);
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    
    return `${year}-${month}-${day}`;
};

export const filterStatusData = (
    statusData: FormattedStatusData[],
    selectedFilters: LocationFiltersValues,
  ) =>
  statusData.filter((item) => {
      const comparisonResults: boolean[] = [];
  
      if (selectedFilters.location) {
        comparisonResults.push(
          selectedFilters.location === item.locationCity,
        );
      }

  
      return !comparisonResults.includes(false);
});
  