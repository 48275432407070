import React, { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useTranslate } from '@/translations';
import { Title } from '@/ui/components/Title';
import { Button } from '@/ui/components/Button';
import { faLocationDot } from '@/ui/components/Icon';
import { PageContainer } from '@/ui/components/Page';
import { Col, SelectBox } from '@/ui';
import { routes } from '@/routes';
import { Loader } from '@/ui/components/Loader';
import { Location } from '@/types';
import {
  useLocations,
  useCreateLocation,
  useUpdateLocation,
} from '@/api/Locations';
import { useCountries } from '@/api/Countries';
import { useLocationTypes } from '@/api/LocationTypes';
import { LocationsDataGrid } from './components/LocationsDataGrid/LocationsDataGrid';
import { LocationsRow } from './Locations.styled';
import { formatCreateLocationData, formatUpdateLocationData } from './helpers';

export const AdminLocations: FC = () => {
  const { t } = useTranslate();
  const { accent } = useTheme();
  const [locationId, setLocationId] = useState('');
  const navigate = useNavigate();

  const { data: locationsData, isLoading: isLocationsLoading } = useLocations();
  const { mutate: createMutate, isLoading: isCreateLoading } =
    useCreateLocation();
  const { mutate: updateMutate, isLoading: isUpdateLoading } =
    useUpdateLocation();

  const { data: countriesData, isLoading: isCountriesLoading } = useCountries();
  const { data: locationTypesData, isLoading: isLocationTypesLoading } =
    useLocationTypes();

  const handleClick = useCallback(() => {
    navigate(routes.root, {
      state: {
        locationId,
      },
    });
  }, [locationId, navigate]);

  const handleCreateLocation = useCallback(
    (data: Location) => {
      const formattedData = formatCreateLocationData(data);
      createMutate(formattedData);
    },
    [createMutate],
  );

  const handleUpdateLocation = useCallback(
    (newData: Partial<Location>, oldData: Location) => {
      const formattedData = formatUpdateLocationData(newData, oldData);

      updateMutate(formattedData);
    },
    [updateMutate],
  );

  if (
    isLocationsLoading ||
    isCreateLoading ||
    isUpdateLoading ||
    isCountriesLoading ||
    isLocationTypesLoading
  )
    return <Loader />;

  return (
    <PageContainer fluid>
      <LocationsRow>
        <Col>
          <SelectBox
            items={locationsData?.entities}
            value={locationId}
            placeholder={t('chooseLocation')}
            label={t('location')}
            displayExpr="city"
            valueExpr="id"
            onValueChange={(e) => setLocationId(e)}
            showClearButton
          />
        </Col>
        <Col>
          <Button fullWidth="true" title={t('go')} onClick={handleClick} />
        </Col>
      </LocationsRow>
      <Title title={t('manageLocations')} icon={faLocationDot} color={accent} />
      <LocationsDataGrid
        onLocationAdd={handleCreateLocation}
        onLocationChanged={handleUpdateLocation}
        locations={locationsData?.entities}
        countries={countriesData?.entities}
        locationTypes={locationTypesData?.entities}
      />
    </PageContainer>
  );
};
