import React, { FC } from 'react';
import { Column, DataGrid, LoadPanel, Pager, Paging } from '@/ui';
import { currencyFormatted, isPagerVisible } from '@/utils/helpers';
import { ChargesDataGridProps } from './types';

const PAGE_SIZE = 6;

export const ChargesDataGrid: FC<ChargesDataGridProps> = ({ dataSource }) => (
  <DataGrid
    id="gridContainer"
    dataSource={dataSource}
    keyExpr="id"
    width="100%"
    showBorders
    hoverStateEnabled
  >
    <LoadPanel enabled />
    <Paging pageSize={PAGE_SIZE} />
    <Pager
      showInfo
      showNavigationButtons
      visible={isPagerVisible(PAGE_SIZE, dataSource?.length)}
    />
    <Column dataField="description" />
    <Column dataField="chargeType" />
    <Column dataField="price" format={currencyFormatted} width={220} />
    <Column dataField="externalPrice" format={currencyFormatted} width={220} />
  </DataGrid>
);
