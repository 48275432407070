import React, { FC, memo, useCallback } from 'react';
import { Button, Popup } from '@/ui';
import { useTranslate } from '@/translations';
import { DocumentTypesModalProps } from '@/ui/components/DocumentTypesModal/types';
import { useDeleteDocumentType, useDocumentTypes } from '@/api/DocumentManagement';
import { Loader } from '@/ui/components/Loader';
import { DocumentType } from '@/types';
import { StyledLink, StyledLinkContainer } from '@/ui/globalStyles';
import { faClose, Icon } from '@/ui/components/Icon';
import { useAddDocumentTypeModal } from '@/ui/components/AddDocumentTypeModal/useAddDocumentTypeModal';

import { HeaderWrapper, Title } from './DocumentTypesModal.styled';
import { DocumentTypesDataGrid } from './components/DocumentTypesDataGrid';

export const DocumentTypesModal: FC<DocumentTypesModalProps> = memo(
  ({ isOpen, closeModal }) => {
    const documentTypes = useDocumentTypes();
    const { mutate: deleteDocumentTypeMutate } = useDeleteDocumentType();
    const { openAddDocumentTypeModal, RenderAddDocumentTypeModal } = useAddDocumentTypeModal()
    const { t } = useTranslate();

    const titleRender = useCallback(() => (
      <HeaderWrapper>
        <Button onClick={openAddDocumentTypeModal}>{t('addNew')}</Button>
        <StyledLinkContainer onClick={closeModal}>
          <StyledLink>
            <Icon icon={faClose} scale={2} />
          </StyledLink>
        </StyledLinkContainer>
      </HeaderWrapper>
    ), [t, closeModal, openAddDocumentTypeModal]);

    return (
      <>
        <RenderAddDocumentTypeModal />
        <Popup visible={isOpen} onHiding={closeModal} fullScreen titleRender={titleRender}>
          {documentTypes.isLoading ? (
            <Loader />
          ) : (
            <>
              <Title>{t('documentTypes')}</Title>
              <DocumentTypesDataGrid
                documentTypes={documentTypes.data?.entities as DocumentType[]}
                deleteDocumentType={deleteDocumentTypeMutate}
              />
            </>
          )}
        </Popup>
      </>
    );
  },
);
