import styled from 'styled-components';

export const LicensePlate = styled.span<{ zoom?: number }>`
  background-color: ${({ theme }) => theme.vesselsLink};
  border: ${({ theme }) => `1px solid ${theme.black}`};
  border-radius: 8px;
  padding: 4px 8px;
  text-align: center;
  font-weight: bold;
  zoom: ${({ zoom }) => zoom || 1};
`;
