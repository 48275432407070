import React from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Navbar, Nav, Container, Tooltip } from '@/ui';
import { Logo } from '@/ui/components/Logo';
import {
  faSignOut,
  faUserCircle,
  Icon,
  Icons,
  IconDefinition,
} from '@/ui/components/Icon';
import { TenantSwitcher } from '@/ui/components/TenantSwitcher';
import { Button } from '@/ui/components/Button';
import { routes } from '@/routes';
import { useTheme } from 'styled-components';
import { useTranslate } from '@/translations';
import { useIdTokenClaims } from '@/hooks/useIdTokenClaims';
import { loginRequest } from '@/auth/config';
import {
  NavLinkDesktop,
  NavLinkMobile,
  NavLinkContent,
  NavLinkLabelMobile,
  NavCustomerLogo,
  NavLogo,
} from './Navigation.styled';
import { useNavigation } from './hooks';

type NavLinkProps = {
  to: string;
  icon: Icons | IconDefinition;
  color: string;
  label: string;
};

const NavLink = ({ to, icon, color, label }: NavLinkProps) => {
  const tooltipId = to.replace(/\//g, '-').replace(/^-/, '');
  return (
    <>
      <NavLinkContent to={to} id={tooltipId}>
        <div>
          <NavLinkDesktop
            color={color}
            className="d-none d-lg-block"
            fontSize={Icons[icon as Icons] ? 'unset' : ''}
          >
            <Icon icon={icon} />
          </NavLinkDesktop>
        </div>

        <NavLinkMobile color={color} className="d-lg-none">
          <Icon icon={icon} />
          <NavLinkLabelMobile>{label}</NavLinkLabelMobile>
        </NavLinkMobile>
      </NavLinkContent>

      <Tooltip
        target={`#${tooltipId}`}
        showEvent="mouseenter"
        hideEvent="mouseleave"
      >
        <div>{label}</div>
      </Tooltip>
    </>
  );
};

export const Navigation = () => {
  const { pages } = useNavigation();
  const { defaultLink } = useTheme();
  const { t } = useTranslate();
  const isAuthenticated = useIsAuthenticated();
  const idTokenClaims = useIdTokenClaims();
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logout();
  };

  const handleLogin = () => {
    instance
      .loginRedirect({
        scopes: loginRequest.scopes,
      })
      .catch((e: Error) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  };

  return (
    <Navbar expand="lg">
      <Container fluid>
        <Navbar.Brand>
          <NavLogo to={routes.root}>
            <Logo />
          </NavLogo>
        </Navbar.Brand>
        {isAuthenticated ? (
          <>
            <Navbar.Toggle aria-controls="navbar" />
            <Navbar.Collapse id="navbar">
              <Nav data-testid="navbar-inner" className="align-items-center">
                <TenantSwitcher />
                {pages.appPages.map(({ to, icon, color, label }) => (
                  <Nav.Item key={to}>
                    <NavLink to={to} icon={icon} color={color} label={label} />
                  </Nav.Item>
                ))}
                {pages.adminPages.map(({ to, icon, color, label }) => (
                  <Nav.Item key={to}>
                    <NavLink to={to} icon={icon} color={color} label={label} />
                  </Nav.Item>
                ))}
              </Nav>
              <Nav className="ms-auto align-content-center align-items-center">
                {idTokenClaims?.userDisplayName && (
                  <Nav.Item className="d-none d-lg-block">
                    <h4 className="m-0 text-light">
                      Welcome, {idTokenClaims?.userDisplayName}!
                    </h4>
                  </Nav.Item>
                )}
                {idTokenClaims && (
                  <Nav.Item>
                    <NavCustomerLogo
                      src={idTokenClaims.tenantIcon}
                      alt="Tenant logo"
                      className="d-none d-lg-block"
                      loading="lazy"
                    />
                  </Nav.Item>
                )}
                <Nav.Item>
                  <NavLink
                    to={routes.manageUser}
                    icon={faUserCircle}
                    color={defaultLink}
                    label={t('myAccount')}
                  />
                </Nav.Item>
                <Nav.Item onClick={handleLogout}>
                  <NavLinkDesktop
                    color={defaultLink}
                    className="d-none d-lg-block"
                  >
                    <Icon icon={faSignOut} />
                  </NavLinkDesktop>
                  <NavLinkMobile color={defaultLink} className="d-lg-none">
                    <Icon icon={faSignOut} />
                    <NavLinkLabelMobile>{t('logOut')}</NavLinkLabelMobile>
                  </NavLinkMobile>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </>
        ) : (
          <Button title={t('logIn')} onClick={handleLogin} />
        )}
      </Container>
    </Navbar>
  );
};
