import { AxiosPromise } from 'axios';
import { CompanyTypesData } from '@/types';
import { Core, COMPANY_TYPES } from '../Core';

type GetCompanyTypes = () => AxiosPromise<CompanyTypesData>;

class CompanyTypes extends Core {
  getCompanyTypes: GetCompanyTypes = async () => this.get(COMPANY_TYPES());
}

export const companyTypes = new CompanyTypes();
