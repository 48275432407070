import React, { useCallback } from 'react';
import { Col, Column, DataGrid, LoadPanel, Paging, Pager } from '@/ui';
import { faDownload, Icon } from '@/ui/components/Icon';
import { StyledLinkContainer } from '@/ui/globalStyles';
import { FORMAT_DATE_WITH_TIME } from '@/utils/constants';
import { isPagerVisible } from '@/utils/helpers';
import { ShippingPlanDataGridProps } from './types';

const PAGE_SIZE = 16;

export const ShippingPlanDataGrid = ({
  dataSource,
}: ShippingPlanDataGridProps) => {
  const onRenderCell = useCallback(
    ({ value, data: { fileName } }) => (
      <StyledLinkContainer>
        <a href={value} download={fileName} aria-label="Download">
          <Icon icon={faDownload} />
        </a>
      </StyledLinkContainer>
    ),
    [],
  );

  return (
    <Col xxl={6} xl={8} md={10} xs={12}>
      <DataGrid
        id="gridContainer"
        dataSource={dataSource}
        keyExpr="documentId"
        columnHidingEnabled
        width="100%"
        showBorders
        allowColumnReordering
      >
        <LoadPanel enabled />
        <Paging pageSize={PAGE_SIZE} />
        <Pager
          showInfo
          showNavigationButtons
          visible={isPagerVisible(PAGE_SIZE, dataSource?.length)}
        />
        <Column dataField="documentId" minWidth={20} visible={false} />
        <Column dataField="fileName" minWidth={20} />
        <Column
          dataField="createdDate"
          width={200}
          dataType="datetime"
          format={FORMAT_DATE_WITH_TIME}
        />
        <Column
          dataField="fileUrl"
          width={100}
          alignment="center"
          cellRender={onRenderCell}
        />
      </DataGrid>
    </Col>
  );
};
