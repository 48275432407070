import styled from 'styled-components';

export const Header = styled.div`
  margin-bottom: 60px;
`;

export const Heading = styled.span`
  vertical-align: unset;
  font-size: 35px;
  font-weight: bold;
  margin: 30px;
`;

export const CarModel = styled.div`
  font-size: 25px;
  font-weight: 600;
  border-bottom: 1px solid black;
  margin-bottom: 20px;
`;

export const HorizontalList = styled.ul`
  list-style: none;
  padding: 0;
  border-bottom: 1px solid black;
  margin: 0 0 20px;
  font-size: 20px;
  font-weight: 600;
`;

export const Appraised = styled.div`
  padding: 0;
  border-bottom: 1px solid black;
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0 40px 0;
`;

export const ConditionHeading = styled.div`
  background: #171b60;
  color: white;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 15px;
`;
