import { CreateCompany, UpdateCompany, Company } from '@/types';

export const formatUpdateCompanyData = (
  newData: Partial<Company>,
  oldData: Company,
): UpdateCompany => ({
  companyId: oldData.id,
  companyCode: {
    isChanged: newData.code !== undefined,
    value: newData.code ?? oldData.code,
  },
  companyName: {
    isChanged: newData.name !== undefined,
    value: newData.name ?? oldData.name,
  },
  countryId: {
    isChanged: newData.country?.id !== undefined,
    value:
      newData.country?.id === null
        ? newData.country?.id
        : oldData.country?.id ?? null,
  },
  isActive: {
    isChanged: newData.isActive !== undefined,
    value: !!(newData.isActive ?? oldData.isActive),
  },
  companyTypeId: {
    isChanged: newData.companyType?.id !== undefined,
    value: newData.companyType?.id ?? oldData.companyType.id,
  },
});

export const formatCreateCompanyData = (data: Company): CreateCompany => ({
  companyTypeId: data.companyType.id,
  companyCode: data.code,
  companyName: data.name,
  countryId: data.country?.id ?? null,
  isActive: data.isActive ?? false,
});
