import { AxiosPromise } from 'axios';
import { VesselMovementData, VesselPositionsData } from '@/types';
import { Core, VESSEL_MOVEMENTS, VESSEL_POSITIONS } from '../Core';

type GetVesselMovements = () => AxiosPromise<VesselMovementData>;
type GetVesselPositions = () => AxiosPromise<VesselPositionsData>;

class Vessel extends Core {
  getVesselMovements: GetVesselMovements = async () =>
    this.get(VESSEL_MOVEMENTS());

  getVesselPositions: GetVesselPositions = async () =>
    this.get(VESSEL_POSITIONS());
}

export const vessel = new Vessel();
