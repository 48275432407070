import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { useTranslate } from '@/translations';
import { Row, Col } from '@/ui';
import { Title } from '@/ui/components/Title';
import { faMapMarkerAlt } from '@/ui/components/Icon';
import { PageContainer } from '@/ui/components/Page';
import { Map } from '@/ui/components/Map';
import { Loader } from '@/ui/components/Loader';
import { useVinMovements, useVinPositions } from '@/api/Vin';
import { VinDataGrid } from './components/VinDataGrid';

export const Vin = () => {
  const [selectedVin, setSelectedVin] = useState<string | undefined>();
  const { t } = useTranslate();
  const { vinLink } = useTheme();
  const { data, isLoading } = useVinMovements();
  const { data: vinPositionsData } = useVinPositions(selectedVin);

  const handleSelectVin = (vin: string) => {
    setSelectedVin(vin);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <PageContainer fluid>
      <Title title={t('vinTracking')} icon={faMapMarkerAlt} color={vinLink} />
      <Row>
        <Col lg={6} md={12} sm={12}>
          <VinDataGrid
            dataSource={data?.entities}
            onSelectVin={handleSelectVin}
          />
        </Col>
        <Col lg={6} md={12} sm={12} className="mt-5 mt-lg-0">
          <Map
            icon={faMapMarkerAlt}
            markers={vinPositionsData?.entities?.map(({ lat, lon }) => ({
              lat: Number(lat),
              lng: Number(lon),
            }))}
          />
        </Col>
      </Row>
    </PageContainer>
  );
};
