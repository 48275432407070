import React, { FC, useState } from 'react';
import { Col, SelectBox, DateBox } from '@/ui';
import { Button } from '@/ui/components/Button';
import { useTranslate } from '@/translations';
import { VesselFiltersInitData, VesselFiltersValues } from '../../types';
import { formatDate } from '../../utils';
import { VesselFiltersRow } from './VesselFilters.styled';

type VesselFiltersProps = {
  initData: VesselFiltersInitData;
  onChange: (filters: VesselFiltersValues) => void;
};

export const VesselFilters: FC<VesselFiltersProps> = ({
  initData,
  onChange,
}) => {
  const [locationFrom, setLocationFrom] = useState<string | null>(null);
  const [locationTo, setLocationTo] = useState<string | null>(null);
  const [model, setModel] = useState(null);
  const [despatchDate, setDespatchDate] = useState<string | undefined>(
    undefined,
  );
  const [etaDate, setEtaDate] = useState<string | undefined>(undefined);
  const { t } = useTranslate();

  const handleFilterChange = () => {
    onChange({
      locationFrom,
      locationTo,
      model,
      despatchDate: formatDate(despatchDate),
      etaDate: formatDate(etaDate),
    });
  };

  return (
    <VesselFiltersRow>
      <Col lg={2} md={6} className="mb-3 mb-lg-0">
        <SelectBox
          items={initData.locationFromItems}
          value={locationFrom}
          placeholder={t('chooseLocation')}
          label={t('fromLocation')}
          onValueChange={(e) => setLocationFrom(e)}
          showClearButton
        />
      </Col>
      <Col lg={2} md={6} className="mb-3 mb-lg-0">
        <SelectBox
          items={initData.locationToItems}
          value={locationTo}
          placeholder={t('chooseLocation')}
          label={t('toLocation')}
          onValueChange={(e) => setLocationTo(e)}
          showClearButton
        />
      </Col>
      <Col lg={2} md={12} className="mb-3 mb-lg-0">
        <SelectBox
          items={initData.modelItems}
          value={model}
          placeholder={t('chooseModel')}
          label={t('model')}
          onValueChange={(e) => setModel(e)}
          showClearButton
        />
      </Col>
      <Col lg={2} md={6} className="mb-3 mb-lg-0">
        <DateBox
          label={t('despatchDate')}
          value={despatchDate}
          placeholder={t('chooseDate')}
          type="date"
          displayFormat="dd/MM/yyyy"
          onValueChanged={(e) => {
            setDespatchDate(e.value);
          }}
        />
      </Col>
      <Col lg={2} md={6} className="mb-3 mb-lg-0">
        <DateBox
          label={t('eta')}
          value={etaDate}
          placeholder={t('chooseDate')}
          type="date"
          displayFormat="dd/MM/yyyy"
          onValueChanged={(e) => {
            setEtaDate(e.value);
          }}
        />
      </Col>
      <Col lg={2} md={12}>
        <Button fullWidth="true" title={t('go')} onClick={handleFilterChange} />
      </Col>
    </VesselFiltersRow>
  );
};
