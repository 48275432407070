import React, { useCallback, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { PageContainer } from '@/ui/components/Page';
import { Title } from '@/ui/components/Title';
import {
  faScrewdriverWrench,
  faChevronLeft,
  faChevronRight,
  Icon,
} from '@/ui/components/Icon';
import {
  Col,
  Row,
  Card,
  TabPanelItem,
  TabPanel,
  Button as BootstrapButton,
  ItemClickEvent,
  Table,
} from '@/ui';
import { useTranslate } from '@/translations';
import { useTheme } from 'styled-components';
import { BackButton } from '@/ui/components/BackButton';
import { routes } from '@/routes';
import { TranslationKey } from '@/translations/types';
import { Loader } from '@/ui/components/Loader';
import { useInspectionsVehicle } from '@/api/Inspections';
import { InspectionStatusMap } from '@/utils/constants';
import { useUpdateInspectionsVehicle } from '@/api/Inspections/hooks/useInspectionsVehicles';
import { InspectionStatusComponent } from '@/modules/Inspection/components/InspectionStatusComponent';
import { currencyFormatted } from '@/utils/helpers';

import { tabs, fields, getPossibleStatues } from './helpers';
import { Card as InspectionCard } from './components/Card';
import { STATUSES } from './constants';

export const InspectionPage = () => {
  const { id } = useParams();
  const { t } = useTranslate();
  const { accent } = useTheme();
  const { search } = useLocation();

  const [isPopupVisible, setPopupVisibility] = useState(false);

  const { isLoading, data } = useInspectionsVehicle(Number(id));

  const { mutate: updateMutate } = useUpdateInspectionsVehicle(Number(id));

  const togglePopup = useCallback(() => {
    setPopupVisibility(!isPopupVisible);
  }, [isPopupVisible]);

  const changeStatus = ({ itemData }: ItemClickEvent) => {
    updateMutate({
      id: Number(id),
      inspectionStatus: {
        isChanged: true,
        value: itemData.id,
      },
    });

    togglePopup();
  };

  const inspection = data?.entities?.[0];

  if (isLoading || !inspection) return <Loader />;

  return (
    <PageContainer fluid>
      <BackButton linkTo={`${routes.inspections}${search}`} />
      <Title
        title={t('inspection')}
        icon={faScrewdriverWrench}
        color={accent}
      />

      <Row className="gap-3 gap-lg-0">
        <Col lg={4} md={12} sm={12}>
          <Card className="h-100">
            <div className="dx-fieldset">
              <InspectionCard data={inspection} fields={fields.basic} />
            </div>
          </Card>
        </Col>

        <Col lg={4} md={12} sm={12}>
          <Card className="h-100">
            <div className="dx-fieldset">
              <InspectionCard data={inspection} fields={fields.inspection} />
            </div>
          </Card>
        </Col>

        <Col lg={4} md={12} sm={12}>
          <Card className="h-100">
            <div className="dx-fieldset">
              <div className="dx-field m-0 d-flex align-items-center text-muted">
                <div className="dx-field-label fw-bold">{t('inspStatus')}:</div>
                <div className="dx-field-value px-3">
                  {InspectionStatusMap[
                    inspection.inspectionStatus as keyof typeof InspectionStatusMap
                  ] || t('notApplicable')}
                </div>
                <BootstrapButton
                  variant={isPopupVisible ? 'primary' : 'success'}
                  onClick={togglePopup}
                >
                  <Icon
                    icon={isPopupVisible ? faChevronLeft : faChevronRight}
                  />
                </BootstrapButton>
              </div>

              <InspectionCard
                data={inspection}
                fields={{
                  ...fields.inspectionStatus,
                }}
              />

              <Table size="sm" borderless>
                <thead>
                  <tr>
                    <th aria-label="empty">&nbsp;</th>
                    <th className="dx-field-label fw-bold float-none">
                      {t('cumulative')}
                    </th>
                    <th className="dx-field-label fw-bold float-none">
                      {t('final')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="dx-field-label fw-bold float-none">
                      {t('cost')}
                    </td>
                    <td className="text-muted">
                      {currencyFormatted(inspection.cumulativeCost)}
                    </td>
                    <td className="text-muted">
                      {currencyFormatted(inspection.finalCost)}
                    </td>
                  </tr>
                  <tr>
                    <td className="dx-field-label fw-bold float-none">
                      {t('gradePoints')}
                    </td>
                    <td className="text-muted">
                      {inspection.cumulativeGradePoints}
                    </td>
                    <td className="text-muted">
                      {inspection.finalGradePoints}
                    </td>
                  </tr>
                  <tr>
                    <td className="dx-field-label fw-bold float-none">
                      {t('gradeScore')}
                    </td>
                    <td className="text-muted">
                      {inspection.cumulativeGradeScore}
                    </td>
                    <td className="text-muted">{inspection.finalGradeScore}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>

        <InspectionStatusComponent
          dataSource={getPossibleStatues(STATUSES, inspection.inspectionStatus)}
          isPopupVisible={isPopupVisible}
          onTogglePopup={togglePopup}
          onChangeStatus={changeStatus}
        />
      </Row>

      <Row className="mt-3">
        <TabPanel>
          {tabs.map(({ title, component: Component }) => (
            <TabPanelItem key={title} title={t(title as TranslationKey)}>
              <Component inspection={inspection} />
            </TabPanelItem>
          ))}
        </TabPanel>
      </Row>
    </PageContainer>
  );
};
