import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { PageContainer } from '@/ui/components/Page';
import { Loader } from '@/ui/components/Loader';
import { useInspectionsVehicleDamages } from '@/api/Inspections';

import { DamageDataGrid } from './components/DamageDataGrid';
import { InspectionPageTab } from '../types';

export const Damage: FC<InspectionPageTab> = ({ inspection }) => {
  const { id } = useParams();

  const { isLoading, data } = useInspectionsVehicleDamages(Number(id));

  if (isLoading || !data) return <Loader />;

  const dataSource = data.entities;

  return (
    <PageContainer fluid>
      <DamageDataGrid
        inspection={inspection}
        dataSource={dataSource}
      />
    </PageContainer>
  );
};
