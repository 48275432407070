import React, { FC, useCallback } from 'react';
import { isPagerVisible } from '@/utils/helpers';
import {
  Column,
  DataGrid,
  LoadPanel,
  Pager,
  RequiredRule,
  Sorting,
  FilterRow,
  Form,
  Editing,
} from '@/ui';
import { FeaturesTabProps } from './types';

const PAGE_SIZE = 20;

export const FeaturesTab: FC<FeaturesTabProps> = ({
  features,
  onFeatureUpdate,
}) => {
  const renderActiveCell = useCallback(
    ({ data: { isEnabled } }) => (
      <Form.Check
        className="dx-custom-switch"
        type="switch"
        checked={isEnabled}
        readOnly
        disabled={!isEnabled}
      />
    ),
    [],
  );

  const handleFeatureUpdate = useCallback(
    ({ newData, oldData }) => {
      onFeatureUpdate(newData, oldData);
    },
    [onFeatureUpdate],
  );

  return (
    <DataGrid
      id="gridContainer"
      columnHidingEnabled
      showBorders
      dataSource={features}
      onRowUpdating={handleFeatureUpdate}
      rowAlternationEnabled
      width="100%"
    >
      <LoadPanel enabled />
      <Pager
        allowedPageSizes={PAGE_SIZE}
        showInfo
        showNavigationButtons
        visible={isPagerVisible(PAGE_SIZE, features?.length)}
      />
      <Editing mode="form" allowUpdating />
      <FilterRow visible />
      <Sorting mode="multiple" />
      <Column
        dataField="functionalFeature.name"
        caption="Feature"
        allowEditing={false}
      >
        <RequiredRule />
      </Column>
      <Column
        allowEditing
        dataField="isEnabled"
        caption="Active"
        width={85}
        cellRender={renderActiveCell}
        alignment="center"
      />
    </DataGrid>
  );
};
