import { useQuery, useMutation } from 'react-query';
import { QUERY_KEYS } from '../../queryKeys';
import { companies } from '../Companies';

type OptionsType = {
  enabled: boolean;
};

export const useCompanies = (options?: OptionsType) =>
  useQuery(
    QUERY_KEYS.company,
    async () => (await companies.getCompanies()).data,
    options,
  );

export const useCreateCompany = () => useMutation(companies.createCompanies);
export const useUpdateCompany = () => useMutation(companies.updateCompanies);
