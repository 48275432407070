import React, { FC, useCallback } from 'react';
import { useTranslate } from '@/translations';
import { Column, DataGrid, LoadPanel, Paging, Pager, FilterRow } from '@/ui';
import { faDownload, Icon } from '@/ui/components/Icon';
import { StyledLink, StyledLinkContainer } from '@/ui/globalStyles';
import { isPagerVisible } from '@/utils/helpers';
import { FORMAT_DATE_WITH_TIME } from '@/utils/constants';

import { Link, useParams } from 'react-router-dom';
import { Document } from '@/types/Documents/Documents';
import { DocumentsDataGridProps } from './types';

const PAGE_SIZE = 16;

export const DocumentsDataGrid: FC<DocumentsDataGridProps> = ({
  dataSource,
  vin,
}) => {
  const { t } = useTranslate();
  const { id } = useParams();

  const onRenderActionsCell = useCallback(
    ({ data: { fileUrl } }) => (
      <StyledLinkContainer>
        <StyledLink>
          <Link to={fileUrl(id)}>
            <Icon icon={faDownload} />
          </Link>
        </StyledLink>
      </StyledLinkContainer>
    ),
    [id],
  );

  const onRenderDescriptionLink = ({
    data: { description, externalLink },
  }: {
    data: Document;
  }) => {
    if (externalLink) {
      return (
        <a href={externalLink(vin)} target="_blank" rel="noreferrer">
          {description}
        </a>
      );
    }

    return description;
  };

  return (
    <DataGrid
      id="gridContainer"
      dataSource={dataSource}
      keyExpr="id"
      columnHidingEnabled
      showBorders
    >
      <LoadPanel enabled />
      <FilterRow visible />
      <Paging pageSize={PAGE_SIZE} />
      <Pager
        showInfo
        showNavigationButtons
        visible={isPagerVisible(PAGE_SIZE, dataSource?.length)}
      />
      <Column dataField="id" visible={false} />
      <Column
        caption=""
        allowFiltering={false}
        width={50}
        alignment="center"
        cellRender={onRenderActionsCell}
      />
      <Column
        dataField="description"
        caption={t('description')}
        minWidth={100}
        cellRender={onRenderDescriptionLink}
      />
      <Column
        dataField="date"
        caption={t('date')}
        dataType="date"
        minWidth={200}
        width={200}
        format={FORMAT_DATE_WITH_TIME}
        defaultSelectedFilterOperation="between"
      />
    </DataGrid>
  );
};
