import { AxiosPromise } from 'axios';
import { ServiceUnitTypesData } from '@/types';
import { Core, SERVICE_UNIT_TYPES } from '../Core';

type GetServiceUnitTypes = () => AxiosPromise<ServiceUnitTypesData>;

class ServiceUnitTypes extends Core {
  getServiceUnitTypes: GetServiceUnitTypes = async () =>
    this.get(SERVICE_UNIT_TYPES());
}

export const serviceUnitTypes = new ServiceUnitTypes();
