import React, { useState, useCallback } from 'react';

import { DocumentTypesModal } from '@/ui/components/DocumentTypesModal/DocumentTypesModal';
import {
  CloseDocumentTypesModal,
  OpenDocumentTypesModal,
  RenderDocumentTypesModalType,
  UseDocumentTypesModal,
  UseDocumentTypesModalState,
} from '@/ui/components/DocumentTypesModal/types';

const initialState: UseDocumentTypesModalState = {
  isOpen: false,
};

export const useDocumentTypesModal: UseDocumentTypesModal = () => {
  const [{ isOpen }, setState] =
    useState<UseDocumentTypesModalState>(initialState);

  const openDocumentTypesModal: OpenDocumentTypesModal = useCallback(
    () => setState({ isOpen: true }),
    [],
  );

  const closeDocumentTypesModal: CloseDocumentTypesModal = useCallback(
    () => setState(initialState),
    [],
  );

  const RenderDocumentTypesModal: RenderDocumentTypesModalType = useCallback(
    () => (
      <DocumentTypesModal
        isOpen={isOpen}
        closeModal={closeDocumentTypesModal}
      />
    ),
    [isOpen, closeDocumentTypesModal],
  );

  return {
    openDocumentTypesModal,
    RenderDocumentTypesModal,
  };
};
