import enLang from './messages/en';

export type PrimitiveType = string | number | boolean | null | undefined | Date;

export interface TType {
  (message: string, values?: Record<string, PrimitiveType>): string;
}

export type TranslationsTypes = {
  [translation in Translations]: {
    [K in TranslationKey]: string;
  };
};

export enum Translations {
  en = 'en',
}

export interface Language {
  language: keyof typeof Translations;
}

export type TranslationKey = keyof typeof enLang;
