import React, { FC, useCallback, useMemo } from 'react';
import { useTranslate } from '@/translations';
import {
  CustomStore,
  Column,
  DataGrid,
  Export,
  FilterRow,
  LoadPanel,
  Lookup,
  Pager,
  Paging,
  SearchPanel,
} from '@/ui';
import { faFile, Icon } from '@/ui/components/Icon';
import { StyledLink, StyledLinkContainer } from '@/ui/globalStyles';
import {
  localDateTimeFormatted,
  updateQueryParam,
} from '@/utils/helpers';
import {
  FilterModelTypeMap,
  FilterModelTypeMapReverse,
  FORMAT_DATE_DD_MM_YYYY_HH_MM,
  InspectionsPageFields,
  InspectionStatusMap,
  InspectionStatusMapLookupFilterValues,
  SortModelTypeMap,
} from '@/utils/constants';
import { paths } from '@/routes';
import { Link, useSearchParams } from 'react-router-dom';
import { LicensePlate } from '@/ui/components/LicensePlate';
import { inspections } from '@/api/Inspections';
import { useGetUserRole } from '@/hooks';
import { GetInspectionsData } from '@/types';

import { handleExport } from './export';
import { InspectionsDataGridProps } from './types';

const allowedPageSizes = [10, 25, 50, 100];

export const InspectionsDataGrid: FC<InspectionsDataGridProps> = () => {
  const { t } = useTranslate();

  const [searchParams] = useSearchParams();

  const { Member } = useGetUserRole();

  const inspectionStatusSearchParams = searchParams.get('inspectionStatus');
  const inspectionDateTimeSearchParam = searchParams.get('inspectionDateTime')?.split(',');

  const onRenderActionsCell = useCallback(
    ({ data: { id, inspectionStatus } }) => (
      <StyledLinkContainer>
        <StyledLink>
          <Link
            to={`/${paths.inspection}/${id}?inspectionStatus=${inspectionStatus}`}
          >
            <Icon icon={faFile} scale={2} />
          </Link>
        </StyledLink>
      </StyledLinkContainer>
    ),
    [],
  );

  const onRenderRegistration = useCallback(
    ({ data: { regNumber } }) =>
      regNumber ? <LicensePlate>{regNumber}</LicensePlate> : t('noRegNumber'),
    [t],
  );

  const dataSource = useMemo(
    () =>
      new CustomStore({
        load: async (loadOptions) => {
          const { sort, filter, skip, take } = loadOptions;

          const body: GetInspectionsData = {
            skip: skip as number,
            take: take as number,
          } as GetInspectionsData;

          if (sort) {
            const {
              selector, // field name
              desc, // true or false
              // @ts-ignore
            } = sort[0];

            const fieldName = selector as keyof typeof InspectionsPageFields;

            body[fieldName] = {
              ...(body[fieldName] || {}),
              isSorted: true,
              sortType: desc ? SortModelTypeMap.desc : SortModelTypeMap.asc,
            };
          }

          type FilterItem = [
            keyof typeof InspectionsPageFields,
            keyof typeof FilterModelTypeMap,
            string | number,
          ];

          const addBodyFilters = (
            item: string | number | FilterItem,
            idx: number,
            filterArray: FilterItem[],
          ) => {
            // search panel block
            if (
              typeof item === 'object' &&
              ((filterArray[idx + 1] as unknown as string) === 'or' || // next, if it's a first item
                (filterArray[idx - 1] as unknown as string) === 'or') // prev, if it's a last item
            ) {
              body.search = item[2].toString();
              return;
            }

            if (typeof item === 'object') {
              // filter: [
              //   ["regNumber", "contains", "Y"], <= item
              //   "and",
              //   ["vin", "contains", "WBA"],
              // ]
              addBodyFilters(item[0], 0, item as unknown as FilterItem[]); // pass index of new item = 0
              return;
            }

            const fieldName = item as keyof typeof InspectionsPageFields;

            if (InspectionsPageFields[fieldName]) {
              // filter: [
              // "manufacturer", <= item, idx: 0
              // "contains", <= filterType, idx: 1
              // "BMW", <= filterValue, idx: 2
              // ]

              const filterType =
                FilterModelTypeMap[
                  filterArray[
                    idx + 1
                  ] as unknown as keyof typeof FilterModelTypeMap
                ];
              const filterValue = filterArray[idx + 2].toString();

              body[fieldName] = {
                ...(body[fieldName] || {}),
                isFiltered: true,
                filters: [
                  ...(body[fieldName]?.filters || []),
                  {
                    filterType,
                    filterValue,
                  },
                ],
              };
            }
          };

          filter?.forEach(addBodyFilters);

          // set default values for Members on initial load
          if (Member && !body.inspectionStatus) {
            const array = [
              InspectionsPageFields.inspectionStatus,
              '=',
              2,
            ] as unknown as FilterItem[];
            addBodyFilters(InspectionsPageFields.inspectionStatus, 0, array);
          }

          const response = await inspections.postInspectionsVehicles(body);

          const searchParamsMap: Partial<Record<keyof typeof InspectionsPageFields, string | Array<string | number>>> = {
            [InspectionsPageFields.inspectionStatus]: body.inspectionStatus?.filters?.[0].filterValue,
            [InspectionsPageFields.inspectionDateTime]: undefined,
          };

          if (body.inspectionDateTime?.filters?.[0].filterValue) {
            searchParamsMap.inspectionDateTime = [
              body.inspectionDateTime?.filters?.[0].filterType,
              body.inspectionDateTime?.filters?.[0].filterValue
            ]
          }

          Object.entries(searchParamsMap).forEach(([key, value]) => {
            if (value) updateQueryParam(key, value.toString());
          })

          return {
            data: response.data.entities,
            totalCount: response.data.totalCount,
            summary: undefined,
            groupCount: undefined,
          };
        },
      }),
    [Member],
  );

  return (
    <DataGrid
      id="gridContainer"
      dataSource={dataSource}
      keyExpr="id"
      columnHidingEnabled
      allowColumnResizing
      columnResizingMode="widget"
      columnAutoWidth
      showBorders
      width="100%"
      onExporting={handleExport}
      remoteOperations
    >
      <LoadPanel enabled />
      <SearchPanel visible width="25vw" />
      <FilterRow visible />
      <Paging
        enabled
        defaultPageSize={allowedPageSizes[0]}
        defaultPageIndex={0}
      />
      <Pager
        allowedPageSizes={allowedPageSizes}
        displayMode="full"
        showInfo
        showNavigationButtons
        showPageSizeSelector
        visible
      />
      <Export enabled />

      <Column
        dataField={InspectionsPageFields.id}
        caption={t('inspectionId')}
        dataType="number"
        width={100}
        filterOperations={['=', '<>', '<', '>', '<=', '>=']} // 'between' not supported
      />
      <Column
        dataField={InspectionsPageFields.inspectionRef}
        caption={t('damageReference')}
        dataType="string"
        width={120}
      />
      <Column
        key={InspectionsPageFields.file}
        caption=""
        allowFiltering={false}
        width={50}
        alignment="center"
        cellRender={onRenderActionsCell}
        allowExporting={false}
      />
      <Column
        dataField={InspectionsPageFields.regNumber}
        caption={t('registration')}
        dataType="string"
        minWidth={100}
        cellRender={onRenderRegistration}
      />
      <Column
        dataField={InspectionsPageFields.vin}
        caption={t('vin')}
        dataType="string"
        width={200}
      />
      <Column
        dataField={InspectionsPageFields.fleetDescription}
        caption={t('customer')}
        dataType="string"
        width={200}
      />
      <Column
        dataField={InspectionsPageFields.inspectionLocation}
        caption={t('inspectedAt')}
        dataType="string"
      />
      <Column
        dataField={InspectionsPageFields.manufacturer}
        caption={t('make')}
        dataType="string"
        width={100}
      />
      <Column
        dataField={InspectionsPageFields.model}
        caption={t('model')}
        dataType="string"
        minWidth={150}
      />
      <Column
        dataField={InspectionsPageFields.inspectionDateTime}
        caption={t('inspectionDate')}
        dataType="datetime"
        minWidth={180}
        width={180}
        format={FORMAT_DATE_DD_MM_YYYY_HH_MM}
        defaultSortOrder="desc"
        filterOperations={['<', '>']} // 'between' not supported
        // @ts-ignore
        selectedFilterOperation={inspectionDateTimeSearchParam ? FilterModelTypeMapReverse[inspectionDateTimeSearchParam[0]] : '>'}
        filterValue={inspectionDateTimeSearchParam ? new Date(inspectionDateTimeSearchParam[1]) : undefined}
        cellRender={(props) =>
          localDateTimeFormatted(props.value, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
          })
        }
      />
      <Column
        dataField={InspectionsPageFields.inspectionStatus}
        caption={t('inspectionStatus')}
        minWidth={180}
        cellRender={({ value }: { value: string }) =>
          InspectionStatusMap[value as keyof typeof InspectionStatusMap]
        }
        defaultFilterValue={
          Member
            ? InspectionStatusMap['Awaiting Authorisation']
            : inspectionStatusSearchParams
        }
      >
        <Lookup
          dataSource={
            Member
              ? InspectionStatusMapLookupFilterValues[1]
              : InspectionStatusMapLookupFilterValues
          }
          displayExpr="displayExpr"
          valueExpr="id"
        />
      </Column>
      <Column
        dataField={InspectionsPageFields.inspector}
        caption={t('inspector')}
        dataType="string"
      />
    </DataGrid>
  );
};
