import { Storage } from '@/types';
import { Worksheet } from '@/ui';
import { MasterRow } from './types';

export const getUniqueStorageItem = (storage: Storage[]) => [
  ...new Map(storage?.map((item) => [item.locationId, item])).values(),
];

export const getStorageItem = (
  dataSource: Storage[],
  masterRows: MasterRow[],
  index: number,
) =>
  dataSource.find(
    ({ locationId }) => locationId === masterRows[index].data.locationId,
  );

export const getStorageItems = (id: number, storageData: Storage[] = []) =>
  storageData?.filter((storage) => storage.locationId === id);

export const insertRow = (
  worksheet: Worksheet,
  index: number,
  offset: number,
  outlineLevel: number,
) => {
  const workSheet = worksheet;
  const currentIndex = index + offset;
  const row = workSheet.insertRow(currentIndex, [], 'n');

  for (let i = worksheet.rowCount + 1; i > currentIndex; i -= 1) {
    workSheet.getRow(i).outlineLevel = worksheet.getRow(i - 1).outlineLevel;
  }
  row.outlineLevel = outlineLevel;
  return row;
};
