import React, { FC, useCallback } from 'react';
import { PageContainer } from '@/ui/components/Page';
import { useTranslate } from '@/translations';
import { faListCheck } from '@/ui/components/Icon';
import { useTheme } from 'styled-components';
import { Title } from '@/ui/components/Title';
import { routes } from '@/routes';
import { BackButton } from '@/ui/components/BackButton';
import { ChecklistDataGrid } from './components/ChecklistDataGrid';

const fakeData = [
  {
    id: 1,
    question: 'Change scheduler',
    attribute: 'none',
    fuel: 'Hybrid',
  },
  {
    id: 2,
    question: 'Additional information',
    attribute: 'none',
    fuel: 'Diesel',
  },
  {
    id: 3,
    question: 'Cancellation request',
    attribute: 'none',
    fuel: 'Gas',
  },
  {
    id: 4,
    question: 'Test',
    attribute: 'none',
    fuel: 'Electric',
  },
];

export const Checklist: FC = () => {
  const { t } = useTranslate();
  const { accent } = useTheme();

  // eslint-disable-next-line no-console
  const handleCreateUser = useCallback((data) => console.log(data), []);
  const handleUpdateUser = useCallback(
    // eslint-disable-next-line no-console
    (newData, oldData) => console.log(newData, oldData),
    [],
  );
  // eslint-disable-next-line no-console
  const handleDeleteUser = useCallback((data) => console.log(data), []);

  return (
    <PageContainer fluid>
      <Title title={t('functionSettings')} icon={faListCheck} color={accent} />
      <BackButton linkTo={`${routes.systemAdministration}`} />

      <ChecklistDataGrid
        questions={fakeData}
        onAddQuestion={handleCreateUser}
        onEditQuestion={handleUpdateUser}
        onDeleteQuestion={handleDeleteUser}
      />
    </PageContainer>
  );
};
