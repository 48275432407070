import { useMutation, useQuery } from 'react-query';
import { queryClient } from '@/config/reactQuery';
import { QUERY_KEYS } from '../../queryKeys';
import { documentManagement } from '../DocumentManagement';

export const useDocumentTypes = () =>
  useQuery(
    QUERY_KEYS.documentManagementDocumentType,
    async () => (await documentManagement.getDocumentTypes()).data,
  );

export const useCreateDocumentType = () =>
  useMutation(documentManagement.createDocumentType, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        QUERY_KEYS.documentManagementDocumentType,
      );
    },
  });

export const useDeleteDocumentType = () =>
  useMutation(documentManagement.deleteDocumentType, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        QUERY_KEYS.documentManagementDocumentType,
      );
    },
  });
