import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { PageContainer } from '@/ui/components/Page';
import { Title } from '@/ui/components/Title';
import { useTranslate } from '@/translations';
import { faFileWaveform } from '@/ui/components/Icon';
import { useDeleteDocument, useDocuments } from '@/api/DocumentManagement';
import { Loader } from '@/ui/components/Loader';
import { AdminDocumentManagementDataGrid } from '@/modules/AdminDocumentManagement/components';
import { Document } from '@/types';
import { Button } from '@/ui';
import { ButtonsContainer } from '@/modules/AdminDocumentManagement/AdminDocumentManagement.styled';
import { useDocumentTypesModal } from '@/ui/components/DocumentTypesModal/useDocumentTypesModal';
import { useUploadDocumentModal } from '@/ui/components/UploadDocumentModal/useUploadDocumentModal';
import { useSearchParams } from 'react-router-dom';
import { getInitialValuesFromSearchParams } from '@/ui/components/UploadDocumentModal/utils';

export const AdminDocumentManagement: FC = () => {
  const { t } = useTranslate();
  const { accent } = useTheme();
  const [searchParams] = useSearchParams();
  const documents = useDocuments();
  const { mutate: deleteDocumentMutate } = useDeleteDocument();
  const { openDocumentTypesModal, RenderDocumentTypesModal } =
    useDocumentTypesModal();
  const { openUploadDocumentModal, RenderUploadDocumentModal } =
    useUploadDocumentModal();

  const handleDeleteDocument = useCallback(
    (documentId: number) => {
      deleteDocumentMutate(documentId);
    },
    [deleteDocumentMutate],
  );

  const uploadDocumentInitialValues = useMemo(
    () => getInitialValuesFromSearchParams(searchParams),
    [searchParams],
  );

  const shouldOpenUploadDocumentModal = searchParams.get('modal') === 'uploadDocument';

  useEffect(() => {
    if (shouldOpenUploadDocumentModal) {
      openUploadDocumentModal();
    }
  }, [shouldOpenUploadDocumentModal, openUploadDocumentModal]);

  if (documents.isLoading) return <Loader />;

  return (
    <PageContainer fluid>
      <Title
        title={t('adminDocumentManagement')}
        icon={faFileWaveform}
        color={accent}
      />
      <ButtonsContainer>
        <Button onClick={openDocumentTypesModal}>{t('documentTypes')}</Button>
        <Button onClick={openUploadDocumentModal}>{t('uploadDocument')}</Button>
      </ButtonsContainer>
      <AdminDocumentManagementDataGrid
        documents={documents.data?.entities as Document[]}
        deleteDocument={handleDeleteDocument}
      />
      <RenderDocumentTypesModal />
      <RenderUploadDocumentModal initialValues={uploadDocumentInitialValues} />
    </PageContainer>
  );
};
