import { PageContainer } from '@/ui/components/Page';
import React, { FC, useRef } from 'react';
import generatePDF from 'react-to-pdf';
import { useTranslate } from '@/translations';
import { localDateFormatted } from '@/utils/helpers';
import { Button } from '@/ui';
import { GbaLogo } from '@/ui/components/Logo/GbaLogo';
import Condition from '@/modules/Inspection/components/tabs/Documents/components/Reports/components/Condition';
import Images from '@/modules/Inspection/components/tabs/Documents/components/Reports/components/Images';
import { Damage } from '@/types';

import {
  Header,
  Heading,
  CarModel,
  HorizontalList,
  Appraised,
} from '../styles/VehicleAppraisalReport.styled';

type Props = {
  vehicleData: {
    regNumber?: string;
    model: string;
    gradeScore: number;
    colour: string;
    mileage: number | string;
    vin: string;
    inspectionDateTime: string;
  };
  vehicleDamages: Damage[];
  imageUrls: string[];
};

export const VehicleAppraisalReport: FC<Props> = ({
  vehicleData,
  vehicleDamages,
  imageUrls,
}) => {
  const { t } = useTranslate();

  const targetRef = useRef(null);

  return (
    <>
      <Button
        onClick={() =>
          generatePDF(targetRef, { filename: 'Vehicle Appraisal Report.pdf' })
        }
      >
        <span className="dx-font-l">Download Vehicle Appraisal Report PDF</span>
      </Button>

      <PageContainer ref={targetRef} className="container">
        <Header>
          <div className="row">
            <div className="col-12 d-flex align-items-center">
              <GbaLogo fill="#171b60" width="115" height="100" />
              <Heading>Vehicle Appraisal Report</Heading>
            </div>
          </div>
        </Header>

        <div className="container">
          <div className="row">
            <div className="col-12">
              <CarModel className="d-flex justify-content-between">
                <div>
                  <span>
                    {vehicleData?.regNumber || t('noRegNumber')}, {vehicleData?.model}
                  </span>
                </div>

                <span>
                  Grade: <span>{vehicleData?.gradeScore}</span>
                </span>
              </CarModel>
            </div>

            <div className="col-12">
              <HorizontalList className="d-flex justify-content-between">
                <li className="d-flex flex-column">
                  <span>Registration: </span>
                  <span>{vehicleData?.regNumber ?? t('noRegNumber')}</span>
                </li>
                <li className="d-flex flex-column">
                  <span>Colour:</span> <span>{vehicleData?.colour}</span>
                </li>
                <li className="d-flex flex-column">
                  <span>Odometer:</span>{' '}
                  <span>
                    {vehicleData?.mileage}
                  </span>
                </li>
              </HorizontalList>
            </div>
            <div className="col-12">
              <Appraised className="d-flex justify-content-between">
                <span>ChassisNumber: {vehicleData?.vin}</span>
                <span>
                  Appraised:{' '}
                  {vehicleData?.inspectionDateTime
                    ? localDateFormatted(vehicleData.inspectionDateTime)
                    : null}
                </span>
              </Appraised>
            </div>

            <Condition vehicleDamages={vehicleDamages} />
          </div>

          <Images imageUrls={imageUrls} />
        </div>
      </PageContainer>
    </>
  );
};
