import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Column,
  Col,
  DataGrid,
  Editing,
  Export,
  LoadPanel,
  Lookup,
  MasterDetail,
  Pager,
  Paging,
  ToolbarDataGrid,
  Item as ItemDataGrid,
  FilterRow,
  RowPreparedEvent,
} from '@/ui';
import { StyledLink, StyledLinkContainer } from '@/ui/globalStyles';
import { faDownload, Icon } from '@/ui/components/Icon';
import { useTranslate } from '@/translations';
import { currencyFormatted, isPagerVisible } from '@/utils/helpers';
import {
  inspections,
  useInspectionsVehicleRepairActions,
} from '@/api/Inspections';
import { useUpdateInspectionsVehicleDamages } from '@/api/Inspections/hooks/useInspectionsVehicles';
import { ActionsPopup } from '@/modules/Inspection/components/tabs/Damage/components/ActionsPopup';
import { useTheme } from 'styled-components';
import { useDataGridExpandedState } from '@/modules/Inspection/components/tabs/Damage/components/useDataGridExpandedState';
import { useDataGridScroll } from '@/modules/Inspection/components/tabs/Damage/components/useDataGridScroll';

import { Damage, SeverityAction } from '@/types';
import { DamageMasterDetail } from './MasterDetail';
import { AuthorizationStatus, DamageDataGridProps } from './types';

const PAGE_SIZE = 6;
const INITIAL_VALUES = {
  id: null,
  status: AuthorizationStatus.Default,
};

export const DamageDataGrid: FC<DamageDataGridProps> = ({ dataSource }) => {
  const { t } = useTranslate();
  const { lightGreen, lightRed } = useTheme();
  const { expandedRows, onRowExpanded, onRowCollapsed } =
    useDataGridExpandedState();
  // leave this hook here, to avoid data clearing when re-rendering MasterDetail
  const masterDetailsDataGridProps = useDataGridExpandedState();
  const { ref } = useDataGridScroll({
    dataSource,
    expandedRows,
    // always scroll this dataGrid, cause it's mounted only once and scroll will be fired on each dataSource change
    // scroll here once, instead of scrolling 3+ times in MasterDetail data grid
    shouldScroll: true,
  });

  const [severityActionsData, setSeverityActionsData] = useState<{
    [key: string]: SeverityAction[];
  }>({});

  useEffect(() => {
    dataSource.forEach(async (item) => {
      const { data } = await inspections.getInspectionsVehicleSeverityActions(
        item.partCode,
        item.damageType,
      );
      setSeverityActionsData((prevState) => ({
        ...prevState,
        [item.partCode]: data.entities,
      }));
    });
  }, [dataSource]);

  const { data: repairActionsData } = useInspectionsVehicleRepairActions();

  const onRenderSectorCell = useCallback(
    ({ data: { sector } }) => (
      // '000010250' - show 10250
      // '000000000' - show 0
      <span>{sector.replace(/^0+/, '') || '0'}</span>
    ),
    [],
  );

  const onRenderActionsCell = useCallback(
    ({ data: { imageFileUrl, fileName } }) => (
      <a href={imageFileUrl} download={fileName} aria-label="Download">
        <Icon icon={faDownload} />
      </a>
    ),
    [],
  );

  const [comment, setComment] = useState<string>('');
  const [isPopupVisible, setPopupVisibility] = useState<boolean>(false);
  const [authorization, setAuthorization] = useState<{
    id: number | null;
    status: AuthorizationStatus;
  }>(INITIAL_VALUES);

  const { mutate } = useUpdateInspectionsVehicleDamages();

  const togglePopup = useCallback(() => {
    setPopupVisibility(!isPopupVisible);
  }, [isPopupVisible]);

  const handleAuthorizationStatus = useCallback(
    (id: number, status: AuthorizationStatus) => {
      setAuthorization({
        id,
        status,
      });
      togglePopup();
    },
    [togglePopup],
  );

  const handleSaveClick = () => {
    if (!authorization.id) return;

    mutate({
      id: authorization.id,
      authorizationNotes: {
        isChanged: true,
        value: comment,
      },
      authorizationStatus: {
        isChanged: true,
        value: authorization.status,
      },
      severityDesc: {
        isChanged: false,
      },
      repairActionDesc: {
        isChanged: false,
      },
    });

    setComment('');
    togglePopup();
  };

  const handleUpdate = useCallback(
    ({ newData, oldData }) => {
      const severityDescription = severityActionsData?.[oldData.partCode].find(
        ({ id }) => id === newData?.severityDesc,
      );

      mutate({
        id: oldData.id,
        authorizationNotes: {
          isChanged: false,
        },
        authorizationStatus: {
          isChanged: false,
        },
        severityDesc: {
          isChanged: newData.severityDesc !== undefined,
          value: severityDescription?.description ?? oldData.severityDesc,
        },
        repairActionDesc: {
          isChanged: newData.repairActionDesc !== undefined,
          value: newData.repairActionDesc ?? oldData.repairActionDesc,
        },
      });
    },
    [mutate, severityActionsData],
  );

  const handleCancelClick = () => {
    setComment('');
    setAuthorization(INITIAL_VALUES);
    togglePopup();
  };

  const getColorForAuthorizationStatus = ({
    rowType,
    data,
    rowElement,
  }: RowPreparedEvent) => {
    if (rowType !== 'data') return;

    const status = data?.authorizationStatus;
    const colorMap: { [key: string]: string } = {
      Approved: lightGreen,
      Rejected: lightRed,
    };
    const color = colorMap[status];

    if (color) {
      /* eslint-disable no-param-reassign */
      rowElement.style.backgroundColor = color;
    }
  };

  const renderCell = useCallback(
    ({ data: { id } }) => (
      <StyledLinkContainer>
        <StyledLink
          onClick={() =>
            handleAuthorizationStatus(id, AuthorizationStatus.Approved)
          }
        >
          {t('approve')}
        </StyledLink>
        <StyledLink
          onClick={() =>
            handleAuthorizationStatus(id, AuthorizationStatus.Rejected)
          }
        >
          {t('reject')}
        </StyledLink>
      </StyledLinkContainer>
    ),
    [handleAuthorizationStatus, t],
  );

  return (
    <Col>
      <ActionsPopup
        isPopupVisible={isPopupVisible}
        togglePopup={togglePopup}
        comment={comment}
        setComment={setComment}
        handleSaveClick={handleSaveClick}
        handleCancelClick={handleCancelClick}
        authorizationStatus={authorization.status}
      />

      <DataGrid
        id="gridContainer"
        dataSource={dataSource}
        columnHidingEnabled
        allowColumnResizing
        columnResizingMode="widget"
        columnAutoWidth
        showRowLines
        showBorders
        repaintChangesOnly
        width="100%"
        onRowPrepared={getColorForAuthorizationStatus}
        onRowUpdating={handleUpdate}
        ref={ref}
        onRowExpanded={onRowExpanded}
        onRowCollapsed={onRowCollapsed}
      >
        <LoadPanel enabled />
        <MasterDetail
          enabled
          render={({ data }) => (
            <DamageMasterDetail
              vehicleDamageId={data.id}
              image={data.imageFileUrl}
              note={data.note}
              authorizationNotes={data.authorizationNotes}
              masterDetailsDataGridProps={masterDetailsDataGridProps}
            />
          )}
        />
        <Paging pageSize={PAGE_SIZE} />
        <Pager
          showInfo
          showNavigationButtons
          visible={isPagerVisible(PAGE_SIZE, dataSource?.length)}
        />
        <Editing
          mode="cell"
          allowUpdating
          allowAdding={false}
          allowDeleting={false}
        />
        <FilterRow visible />
        <Export enabled />

        <ToolbarDataGrid>
          <ItemDataGrid name="exportButton" />
        </ToolbarDataGrid>

        <Column
          dataField="damageAreaDesc"
          caption={t('location')}
          allowEditing={false}
          width={150}
        />
        <Column
          dataField="damagePartDesc"
          caption={t('partComp')}
          allowEditing={false}
        />
        <Column
          dataField="damageType"
          caption={t('damageType')}
          allowEditing={false}
        />
        <Column
          dataField="sector"
          caption={t('sector')}
          allowEditing={false}
          cellRender={onRenderSectorCell}
        />
        <Column
          dataField="severityDesc"
          caption={t('severity')}
          allowEditing
          showEditorAlways
        >
          <Lookup
            dataSource={({ data }: { data: Damage }) =>
              severityActionsData?.[data?.partCode] || []
            }
            valueExpr="id"
            displayExpr="description"
          />
        </Column>
        <Column
          dataField="repairActionDesc"
          caption={t('repairMethod')}
          allowEditing // do not set as false, it will not render the Lookup
          width={160}
          showEditorAlways
        >
          <Lookup
            dataSource={repairActionsData?.entities}
            valueExpr="id"
            displayExpr="repairActionDesc"
          />
        </Column>
        <Column
          dataField="internalGradingPoints"
          caption={t('internalGrade')}
          allowEditing={false}
        />
        <Column
          dataField="workCenterDesc"
          caption={t('workCentre')}
          allowEditing={false}
        />
        <Column
          dataField="responsibilityDesc"
          caption={t('responsibility')}
          allowEditing={false}
        />
        <Column
          dataField="gradePoints"
          caption={t('gradePoints')}
          allowEditing={false}
        />
        <Column
          dataField="lineCost"
          caption={t('lineCost')}
          format={currencyFormatted}
          allowEditing={false}
        />
        <Column
          dataField="imageFileUrl"
          caption={null}
          allowSorting={false}
          cellRender={onRenderActionsCell}
          alignment="center"
          allowEditing={false}
          width={40}
        />
        <Column
          dataField="actions"
          caption={null}
          cellRender={renderCell}
          alignment="center"
          allowSorting={false}
          allowEditing={false}
          allowSearch={false}
          allowFiltering={false}
          width={140}
        />
      </DataGrid>
    </Col>
  );
};
