import React, { FC, useRef, useState } from 'react';
import { Title } from '@/ui/components/UploadDocumentModal/UploadDocumentModal.styled';
import { Card, Col, DevButton, Form, Row } from '@/ui';
import { useTranslate } from '@/translations';
import { columnsDescription } from './constants';

export const Popup: FC<{ onTogglePopup: () => void }> = ({ onTogglePopup }) => {
  const { t } = useTranslate();

  const [, setFileName] = useState<string>('');
  const [, setFile] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [lookupValue, setLookupValue] = useState<boolean>(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const fileReader = new FileReader();

  const handleFileChange = () => {
    const selectedFile = fileInputRef.current?.files?.[0];
    if (selectedFile) {
      setFileName(selectedFile.name || 'document');
      fileReader.readAsDataURL(selectedFile);
    } else {
      setFileName('');
      setFile('');
    }
  };

  return (
    <>
      <Title>{t('importData')}</Title>
      <Row>
        <Col lg={12}>
          <Form>
            <Card className="mb-3 dx-theme-border-color-as-background-color border-0">
              <Card.Body>
                <h1 className="mt-3 mb-2 text-muted fw-bold">
                  {t('importFile')}
                </h1>

                <h4 className="text-muted">{t('fileType')}</h4>

                <Card.Text as="div" className="dx-field px-4">
                  {columnsDescription.map(({ id, text }) => (
                    <div key={id} className="text-muted fw-bold">
                      Column {id}: {text}
                    </div>
                  ))}
                </Card.Text>
              </Card.Body>
            </Card>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={4} className="text-muted fw-bold">
                {t('description')}
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Description"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={4} className="text-muted fw-bold">
                {t('importData')}
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
                <Form.Text>
                  <em>{t('acceptedFile')}:</em> {t('excel')}
                </Form.Text>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3 align-items-center">
              <Form.Label column sm={4} className="text-muted fw-bold">
                {t('createMissingLookupValues')}
              </Form.Label>
              <Col sm={8}>
                <Form.Check
                  checked={lookupValue}
                  onChange={(e) => setLookupValue(e.target.checked)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Col sm={8} />
              <Col sm={4} className="d-flex justify-content-end gap-3">
                <DevButton icon="save" text="Import" />
                <DevButton icon="clear" text="Cancel" onClick={onTogglePopup} />
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </>
  );
};
