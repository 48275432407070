import React, { FC } from 'react';
import { GbaLogo } from '@/ui/components/Logo/GbaLogo';
import { Tooltip } from '@/ui';

import { useTranslate } from '@/translations';
import { Logo4pl } from './Logo.styled';
import Logo4plImg from './4pl-logo-white.png';

export const Logo: FC = () => {
  const { t } = useTranslate();

  return (
    <>
      <GbaLogo />
      <Logo4pl id="logo4pl" src={Logo4plImg} alt="logo" loading="lazy" />

      <Tooltip target="#logo4pl" showEvent="mouseenter" hideEvent="mouseleave">
        <div>{t('productPipeline')}</div>
      </Tooltip>
    </>
  );
};
