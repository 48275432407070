import React, { FC, useState } from 'react';
import { useTranslate } from '@/translations';
import {
  FileLoadPanel,
  FileUploader,
  Popup,
  UploadedEvent,
  ValueChangedEvent,
} from '@/ui';
import {
  acceptImageFiles,
  allowedFileExtensions,
} from '@/modules/UploadFile/utils';
import { MAX_FILE_SIZE } from '@/utils/constants';
import { useUpdateInspectionDamagesImages } from '@/api/Inspections/hooks/useInspectionsVehicles';
import { AddImagePopupProps } from './types';

export const AddImagePopup: FC<AddImagePopupProps> = ({
  isPopupVisible,
  onTogglePopup,
  vehicleDamageId,
}) => {
  const { t } = useTranslate();
  const [file, setFile] = useState<ArrayBuffer | string | null>(null);
  const [fileName, setFileName] = useState('');

  const { mutate: updateDamagesImagesData, isLoading } =
    useUpdateInspectionDamagesImages(vehicleDamageId);

  const handleUploadFile = () => {
    updateDamagesImagesData({
      data: {
        vehicleDamageId,
        imageFileName: fileName,
        imageFileBase64: file,
      },
    });
  };

  const handleValueChange = (e: ValueChangedEvent) => {
    const item: File = e.value?.[0]!;

    if (!item) return;
    const reader = new FileReader();
    reader.onload = ({ target }) => {
      if (target?.result) {
        setFile(target.result);
        setFileName(item.name);
      }
    };
    reader.readAsDataURL(item); // convert to base64 string
  };

  const handleUploaded = (e: UploadedEvent) => {
    e.component.reset();
    onTogglePopup();
  };

  return (
    <Popup
      visible={isPopupVisible}
      onHiding={onTogglePopup}
      title={t('addImage')}
      position="center"
      minWidth="320"
      maxWidth="400"
      height="auto"
    >
      <FileLoadPanel visible={isLoading} />
      <FileUploader
        uploadMode="useButtons"
        onUploaded={handleUploaded}
        accept={acceptImageFiles}
        allowedFileExtensions={allowedFileExtensions(acceptImageFiles)}
        uploadFile={handleUploadFile}
        onValueChanged={handleValueChange}
        selectButtonText={t('selectFile')}
        uploadButtonText={t('upload')}
        invalidMaxFileSizeMessage={t('fileIsTooLarge')}
        maxFileSize={MAX_FILE_SIZE}
      />
    </Popup>
  );
};
