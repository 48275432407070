import { useMutation, useQuery } from 'react-query';
import { QUERY_KEYS } from '../../queryKeys';
import { functionalFeatures } from '../FunctionalFeatures';

interface QueryOptions {
  enabled?: boolean;
}

export const useFunctionalFeatures = () =>
  useQuery(
    QUERY_KEYS.functionalFeatures,
    async () => (await functionalFeatures.getFunctionalFeatures()).data,
  );

export const useTenantFunctionalFeatures = (
  tenantId: number,
  options?: QueryOptions,
) =>
  useQuery(
    [QUERY_KEYS.functionalFeatures, tenantId],
    async () =>
      (await functionalFeatures.getTenantFunctionalFeatures(tenantId)).data,
    options,
  );

export const useToggleDisableFunctionalFeatures = () =>
  useMutation(functionalFeatures.toggleDisableFunctionalFeature);
