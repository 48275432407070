import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, ScrollView } from '@/ui';
import { Loader } from '@/ui/components/Loader';
import { useTranslate } from '@/translations';
import {
  ButtonsWrapper,
  PageCount,
  Wrapper,
} from '@/ui/components/PdfReader/PdfReader.styled';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

interface PdfReaderProps {
  src: string;
}

export const PDFReader = ({ src }: PdfReaderProps) => {
  const { t } = useTranslate();

  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const onLoadSuccess = ({ numPages }: { numPages: number }) =>
    setPageCount(numPages);

  const prevPage = () => setPageNumber((prevPageNumber) => prevPageNumber - 1);

  const nextPage = () => setPageNumber((prevPageNumber) => prevPageNumber + 1);

  return (
    <Wrapper>
      <ScrollView>
        <Document file={src} onLoadSuccess={onLoadSuccess} loading={Loader}>
          <Page pageNumber={pageNumber} />
        </Document>
      </ScrollView>
      {!!pageCount && (
        <ButtonsWrapper>
          <Button onClick={prevPage} disabled={pageNumber <= 1}>
            {t('prev')}
          </Button>
          <PageCount>
            {pageNumber} / {pageCount}
          </PageCount>
          <Button onClick={nextPage} disabled={pageNumber >= pageCount}>
            {t('next')}
          </Button>
        </ButtonsWrapper>
      )}
    </Wrapper>
  );
};
