import { AxiosPromise } from 'axios';
import {
  DocumentsData,
  CreateDocumentType as CreateDocumentTypeData,
  DocumentTypesData,
  UploadDocument as UploadDocumentData,
} from '@/types';
import {
  Core,
  DOCUMENT_MANAGEMENT_DOCUMENT_TYPES,
  DOCUMENT_MANAGEMENT_DOCUMENTS,
  DOCUMENT_MANAGEMENT_DOCUMENT_TYPE,
  DOCUMENT_MANAGEMENT_DOCUMENT,
} from '@/api/Core';

type GetDocumentTypesData = () => AxiosPromise<DocumentTypesData>;

type CreateDocumentType = (
  data: CreateDocumentTypeData,
) => AxiosPromise<boolean>;

type DeleteDocumentType = (documentTypeId: number) => AxiosPromise<boolean>;

type GetDocumentsData = () => AxiosPromise<DocumentsData>;
type UploadDocument = (data: UploadDocumentData) => AxiosPromise<boolean>;
type DeleteDocument = (documentId: number) => AxiosPromise<boolean>;

class DocumentManagement extends Core {
  /** Document Types: */
  getDocumentTypes: GetDocumentTypesData = async () =>
    this.get<DocumentTypesData, AxiosPromise<DocumentTypesData>>(DOCUMENT_MANAGEMENT_DOCUMENT_TYPES());

  createDocumentType: CreateDocumentType = async (data) =>
    this.post<CreateDocumentTypeData, AxiosPromise<boolean>>(
      DOCUMENT_MANAGEMENT_DOCUMENT_TYPES(),
      data,
    );

  deleteDocumentType: DeleteDocumentType = async (documentTypeId) =>
    this.delete<undefined, AxiosPromise<boolean>>(
      DOCUMENT_MANAGEMENT_DOCUMENT_TYPE(documentTypeId),
    );

  /** Documents: */
  getDocuments: GetDocumentsData = async () =>
    this.get<DocumentsData, AxiosPromise<DocumentsData>>(DOCUMENT_MANAGEMENT_DOCUMENTS());

  uploadDocument: UploadDocument = async (data) =>
    this.post<UploadDocumentData, AxiosPromise<boolean>>(
      DOCUMENT_MANAGEMENT_DOCUMENTS(),
      data
    );

  deleteDocument: DeleteDocument = async (documentId) =>
    this.delete<undefined, AxiosPromise<boolean>>(
      DOCUMENT_MANAGEMENT_DOCUMENT(documentId),
    )
}

export const documentManagement = new DocumentManagement();
