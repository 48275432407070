import React, { useState, useCallback } from 'react';

import { PreviewModal } from '@/ui/components/PreviewModal';
import {
  ClosePreviewModal,
  OpenPreviewModal,
  RenderPreviewModalType,
  UsePreviewModal,
  UsePreviewModalState,
} from '@/ui/components/PreviewModal/types';

const initialState: UsePreviewModalState = {
  isOpen: false,
  src: '',
  fileName: '',
};

export const usePreviewModal: UsePreviewModal = () => {
  const [{ isOpen, src, fileName }, setState] =
    useState<UsePreviewModalState>(initialState);

  const openPreviewModal: OpenPreviewModal = useCallback(
    (data) => setState({ ...data, isOpen: true }),
    [],
  );

  const closePreviewModal: ClosePreviewModal = useCallback(
    () => setState(initialState),
    [],
  );

  const RenderPreviewModal: RenderPreviewModalType = useCallback(
    () => (
      <PreviewModal
        isOpen={isOpen}
        closeModal={closePreviewModal}
        src={src}
        fileName={fileName}
      />
    ),
    [isOpen, closePreviewModal, src, fileName],
  );

  return {
    openPreviewModal,
    RenderPreviewModal,
  };
};
