import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { DataGrid, Paging, Pager, Column } from '@/ui';
import { isPagerVisible } from '@/utils/helpers';
import { TransportMovementGridDetailSectionProps } from './types';

const PAGE_SIZE = 6;
function renderGridCell(data: string) {
  return (
    <Link
      to={{
        pathname: '/passport',
        search: `search=${data}`,
      }}
    >
      View
    </Link>
  );
}
export const TransportMovementGridDetailSection: FC<
  TransportMovementGridDetailSectionProps
> = ({ dataSource }) => (
  <DataGrid
    dataSource={dataSource}
    columnHidingEnabled
    showRowLines
    width="100%"
    showBorders
    allowColumnReordering
  >
    <Paging pageSize={PAGE_SIZE} />
    <Pager
      showInfo
      showNavigationButtons
      visible={isPagerVisible(PAGE_SIZE, dataSource?.length)}
    />
    <Column
      caption="Passport History"
      alignment="center"
      cellRender={(e) => renderGridCell(e.data.serialNumber)}
      minWidth={100}
      width={100}
    />
    <Column
      dataField="orderReference"
      caption="Order Ref"
      minWidth={100}
      width={100}
      alignment="left"
    />
    <Column dataField="serialNumber" minWidth={200} width={200} />
    <Column dataField="productCode" minWidth={130} width={130} />
    <Column dataField="productDesc" width={180} />
    <Column dataField="colourCode" width={120} />
    <Column dataField="colourDesc" minWidth={120} width={120} />
  </DataGrid>
);
