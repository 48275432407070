import React, { FC, useCallback } from 'react';
import { isPagerVisible } from '@/utils/helpers';
import {
  Column,
  DataGrid,
  Editing,
  LoadPanel,
  Lookup,
  Pager,
  Form,
  RequiredRule,
  NumericRule,
  Sorting,
  FilterRow,
} from '@/ui';
import { LocationsDataGridProps } from './types';

const PAGE_SIZE = 20;

export const LocationsDataGrid: FC<LocationsDataGridProps> = ({
  locations,
  countries,
  locationTypes,
  onLocationAdd,
  onLocationChanged,
}) => {
  const renderActiveCell = useCallback(
    ({ data: { isActive } }) => (
      <Form.Check
        className="dx-custom-switch"
        type="switch"
        checked={isActive}
        readOnly
        disabled={!isActive}
      />
    ),
    [],
  );

  const handleAddLocation = useCallback(
    ({ data }) => {
      onLocationAdd(data);
    },
    [onLocationAdd],
  );

  const handleEditLocation = useCallback(
    ({ newData, oldData }) => {
      onLocationChanged(newData, oldData);
    },
    [onLocationChanged],
  );

  return (
    <DataGrid
      id="gridContainer"
      columnHidingEnabled
      showBorders
      dataSource={locations}
      onRowUpdating={handleEditLocation}
      onRowInserted={handleAddLocation}
      rowAlternationEnabled
      allowColumnReordering
      width="80%"
    >
      <LoadPanel enabled />
      <Pager
        allowedPageSizes={PAGE_SIZE}
        showInfo
        showNavigationButtons
        visible={isPagerVisible(PAGE_SIZE, locations?.length)}
      />
      <Editing mode="form" allowAdding allowUpdating />
      <FilterRow visible />
      <Sorting mode="multiple" />
      <Column dataField="desc" caption="Location Description">
        <RequiredRule />
      </Column>
      <Column dataField="capacity" caption="Capacity" width={120}>
        <NumericRule />
        <RequiredRule />
      </Column>
      <Column dataField="city" caption="City" width={250}>
        <RequiredRule />
      </Column>
      <Column dataField="postCode" caption="Postcode" width={100} />
      <Column dataField="locationType.id" caption="Location Type" width={250}>
        <Lookup dataSource={locationTypes} displayExpr="name" valueExpr="id" />
        <RequiredRule />
      </Column>
      <Column dataField="country.id" caption="Country Code" width={140}>
        <Lookup dataSource={countries} displayExpr="iso" valueExpr="id" />
        <RequiredRule />
      </Column>
      <Column dataField="country.id" caption="County Description" width={140}>
        <RequiredRule />
        <Lookup dataSource={countries} displayExpr="name" valueExpr="id" />
      </Column>
      <Column
        dataField="isActive"
        caption="Active"
        width={100}
        cellRender={renderActiveCell}
        alignment="center"
      />
    </DataGrid>
  );
};
