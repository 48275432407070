import React, { FC } from 'react';
import { useTranslate } from '@/translations';
import { useTheme } from 'styled-components';
import { PageContainer } from '@/ui/components/Page';
import { Title } from '@/ui/components/Title';
import { faUser } from '@/ui/components/Icon';
import { useGBAUsers } from '@/api/Users';
import { useIdTokenClaims } from '@/hooks';
import { Loader } from '@/ui/components/Loader';
import { useInspectionsVehiclesInspector } from '@/api/Inspections/hooks/useInspectionsVehicles';
import { UserProfile } from './components/UserProfile';

export const ManageUserProfile: FC = () => {
  const { t } = useTranslate();

  const { accent } = useTheme();
  const idTokenClaims = useIdTokenClaims();

  const { data, isLoading } = useGBAUsers();

  const user = data?.entities.find(
    (signInUser) => signInUser.email === idTokenClaims?.signInName,
  );

  const { data: inspectorData, isLoading: isInspectorLoading } =
    useInspectionsVehiclesInspector(Number(user?.inspectorId), {
      enabled: !!user?.inspectorId,
    });

  const handleResetPasswordClick = () => {};

  if (isLoading || isInspectorLoading || !user) return <Loader />;

  return (
    <PageContainer>
      <Title title={t('userProfile')} icon={faUser} color={accent} />

      <UserProfile
        onResetPasswordClick={handleResetPasswordClick}
        user={user}
        inspector={inspectorData?.entities[0]}
      />
    </PageContainer>
  );
};
