import React, { FC } from 'react';

const Images: FC<{ imageUrls: string[] }> = ({ imageUrls }) => (
  <div className="row">
    {imageUrls?.map((url) => (
      <div key={url} className="col-md-3 py-1">
        <div className="image-wrapper">
          <img
            className="img-fluid"
            src={url}
            alt="damage"
            loading="lazy"
          />
        </div>
      </div>
    ))}
  </div>
);

export default Images;
