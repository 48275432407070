import React, { FC, useCallback, useMemo } from 'react';
import { Column, DataGrid } from '@/ui';
import { faDownload, faEye, Icon } from '@/ui/components/Icon';
import { StyledLink, StyledLinkContainer } from '@/ui/globalStyles';
import { usePreviewModal } from '@/ui/components/PreviewModal/usePreviewModal';
import { Document } from '@/types';
import { useTranslate } from '@/translations';
import styled from 'styled-components';

const Title = styled.h6`
  font-size: 20px !important;
  padding: 0 0 12px 8px;
  margin: 0;
  font-weight: 500;
  line-height: 1.2;
`;

export const RelatedDocumentsDataGrid: FC<{
  data: Document;
  documents: Document[];
}> = ({ data, documents }) => {
  const { t } = useTranslate();
  const { openPreviewModal, RenderPreviewModal } = usePreviewModal();

  const onRenderActionsCell = useCallback(
    ({ data: { fileUrl, fileName } }) => (
      <StyledLinkContainer>
        <StyledLink
          onClick={() => openPreviewModal({ src: fileUrl, fileName })}
          aria-label="View"
        >
          <Icon icon={faEye} />
        </StyledLink>
        <a href={fileUrl} download={fileName} aria-label="Download">
          <Icon icon={faDownload} />
        </a>
      </StyledLinkContainer>
    ),
    [openPreviewModal],
  );

  const getFormattedStore = useCallback(
    (
      store: Document[],
      item: Document,
    ): { filter: (string | number[])[]; store: Document[] } => ({
      store,
      filter: item.relatedDocuments?.length
        ? ['documentId', 'contains', item.relatedDocuments]
        : [],
    }),
    [],
  );

  const dataSource = useMemo(
    () => getFormattedStore(documents, data),
    [getFormattedStore, documents, data],
  );

  return (
    <>
      <Title>{t('associatedDocuments')}</Title>

      <DataGrid
        id="gridContainer"
        dataSource={dataSource}
        keyExpr="documentId"
        showBorders
        allowColumnReordering
      >
        <Column dataField="documentId" visible={false} />
        <Column dataField="documentType" />
        <Column dataField="entity" />
        <Column dataField="entityReference" />
        <Column dataField="origin" />
        <Column dataField="destination" />
        <Column
          dataField="actions"
          minWidth={100}
          width={100}
          alignment="center"
          cellRender={onRenderActionsCell}
        />
      </DataGrid>

      <RenderPreviewModal />
    </>
  );
};
