import React, { FC } from 'react';
import { DataGrid, Column, LoadPanel, Paging, Pager } from '@/ui';
import { isPagerVisible } from '@/utils/helpers';
import { useTranslate } from '@/translations';

import { FORMAT_DATE_WITH_TIME } from '@/utils/constants';
import { EventViewerDataGridProps } from './types';

const PAGE_SIZE = 6;

export const EventViewerDataGrid: FC<EventViewerDataGridProps> = ({
  dataSource,
}) => {
  const { t } = useTranslate();

  return (
    <DataGrid
      id="gridContainer"
      dataSource={dataSource}
      columnHidingEnabled
      allowColumnResizing
      columnResizingMode="widget"
      columnAutoWidth
      showRowLines
      showBorders
      repaintChangesOnly
      width="100%"
    >
      <LoadPanel enabled />
      <Paging pageSize={PAGE_SIZE} />
      <Pager
        showInfo
        showNavigationButtons
        visible={isPagerVisible(PAGE_SIZE, dataSource?.length)}
      />
      <Column dataField="spLog_Id" caption={t('logId')} />
      <Column dataField="spName" caption={t('name')} />
      <Column dataField="spUser" caption={t('user')} />
      <Column
        dataField="spDateTime"
        caption={t('time')}
        dataType="datetime"
        format={FORMAT_DATE_WITH_TIME}
      />
      <Column
        dataField="statusChange"
        caption={t('statusChange')}
        cellRender={({ value }) => value || t('notApplicable')}
      />
      <Column
        dataField="fieldUpdate"
        caption={t('fieldUpdate')}
        cellRender={({ value }) => value || t('notApplicable')}
      />
      <Column
        dataField="description"
        caption={t('description')}
        cellRender={({ value }) => value || t('notApplicable')}
      />
    </DataGrid>
  );
};
