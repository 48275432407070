import React, { FC } from 'react';
import {
  ConditionHeading,
  HorizontalList,
} from '@/modules/Inspection/components/tabs/Documents/components/Reports/styles/VehicleAppraisalReport.styled';
import { Damage } from '@/types';

const Condition: FC<{ vehicleDamages?: Damage[] }> = ({ vehicleDamages }) => (
  <div className="col-12">
    <ConditionHeading>
      <span>Condition</span>
    </ConditionHeading>
    <div>
      {vehicleDamages?.map((vehicleDamage, index) => (
        <HorizontalList key={vehicleDamage.partCode} className="row">
          <span className="col-1">{index + 1}</span>
          <div className="col-5">
            {vehicleDamage?.damagePartDesc} {vehicleDamage?.damageAreaDesc}
          </div>
          <div className="col-6">
            {vehicleDamage?.damageType} {vehicleDamage?.severityDesc}
          </div>
        </HorizontalList>
      ))}
    </div>
  </div>
);

export default Condition;
