// example: TV02034
export const frCodeRegExp = /^[A-Z]{2}\d{5}$/;

// example: 1234567
export const frCodeRegExpBMW = /^\d{7}$/;

export const frCodeValidator = (frCode: string): boolean => !!frCode.match(new RegExp(frCodeRegExp));
export const frCodeValidatorBMW = (frCode: string): boolean => !!frCode.match(new RegExp(frCodeRegExpBMW));

// example: 00003103414
export const partCodeRegExp = /^\w{11}$/;

export const partCodeValidator = (partCode: string): boolean => !!partCode.match(new RegExp(partCodeRegExp));
