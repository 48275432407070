import { useMutation, useQuery } from 'react-query';
import { QUERY_KEYS } from '../../queryKeys';
import { tenants } from '../Tenants';

export const useTenants = () =>
  useQuery(QUERY_KEYS.tenants, async () => (await tenants.getTenants()).data);

export const useUserTenants = (userId?: number) =>
  useQuery(
    [QUERY_KEYS.users, userId],
    async () => (await tenants.getUserTenants(userId)).data,
  );

export const useGBAUserTenants = (gbaUserId: number) =>
  useQuery(
    [QUERY_KEYS.gbaUsers, gbaUserId],
    async () => (await tenants.getGBATenants(gbaUserId)).data,
  );

export const useCreateTenant = () => useMutation(tenants.createTenants);
export const useUpdateTenant = () => useMutation(tenants.updateTenants);
