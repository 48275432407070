import React, { FC, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { Title } from '@/ui/components/Title';
import { faFile } from '@/ui/components/Icon';
import { useTranslate } from '@/translations';
import { useOrderSummary } from '@/api/Order';
import { Loader } from '@/ui/components/Loader';
import { PageContainer } from '@/ui/components/Page';
import { OrderDataGrid } from './components/OrderDataGrid';
import { formatOrderSummary } from './utils';

export const Order: FC = () => {
  const { t } = useTranslate();
  const { accent } = useTheme();
  const { data, isLoading } = useOrderSummary();

  const formattedOrderSummary = useMemo(
    () => formatOrderSummary(data?.entities || []),
    [data?.entities],
  );

  if (isLoading) return <Loader />;

  return (
    <PageContainer fluid>
      <Title title={t('orderSummary')} icon={faFile} color={accent} />
      <OrderDataGrid dataSource={formattedOrderSummary} />
    </PageContainer>
  );
};
