import React, { useCallback } from 'react';
import { Col, Column, DataGrid, LoadPanel, Pager, Paging } from '@/ui';
import { faDownload, faEye, faTrash, Icon } from '@/ui/components/Icon';
import { StyledLinkContainer, StyledLink } from '@/ui/globalStyles';
import { FORMAT_DATE_WITH_TIME } from '@/utils/constants';
import { isPagerVisible } from '@/utils/helpers';
import { AdminShippingPlanDataGridProps } from '@/modules/SystemAdministration/ShippingPlan/components/ShippingPlanDataGrid/types';
import { usePreviewModal } from '@/ui/components/PreviewModal/usePreviewModal';

const PAGE_SIZE = 10;

export const AdminShippingPlanDataGrid = ({
  deleteShippingPlan,
  dataSource,
}: AdminShippingPlanDataGridProps) => {
  const { openPreviewModal, RenderPreviewModal } = usePreviewModal();

  const onRenderCell = useCallback(
    ({ value, key, data: { fileName } }) => (
      <StyledLinkContainer>
        <a href={value} download={fileName} aria-label="Download">
          <Icon icon={faDownload} />
        </a>
        <StyledLink onClick={() => openPreviewModal({ src: value, fileName })} aria-label="View">
          <Icon icon={faEye} />
        </StyledLink>
        <StyledLink onClick={() => deleteShippingPlan(key)} aria-label="Delete">
          <Icon icon={faTrash} />
        </StyledLink>
      </StyledLinkContainer>
    ),
    [deleteShippingPlan, openPreviewModal],
  );

  return (
    <Col xxl={6} xl={8} md={10} xs={12}>
      <DataGrid
        id="gridContainer"
        dataSource={dataSource}
        keyExpr="documentId"
        columnHidingEnabled
        width="100%"
        showBorders
        allowColumnReordering
      >
        <LoadPanel enabled />
        <Paging pageSize={PAGE_SIZE} />
        <Pager
          showInfo
          showNavigationButtons
          visible={isPagerVisible(PAGE_SIZE, dataSource?.length)}
        />
        <Column dataField="documentId" minWidth={20} visible={false} />
        <Column dataField="fileName" minWidth={200} />
        <Column
          dataField="createdDate"
          dataType="datetime"
          minWidth={200}
          width={200}
          format={FORMAT_DATE_WITH_TIME}
        />
        <Column
          dataField="fileUrl"
          minWidth={120}
          width={120}
          alignment="center"
          cellRender={onRenderCell}
        />
      </DataGrid>

      <RenderPreviewModal />
    </Col>
  );
};
