import { useQuery, useMutation } from 'react-query';
import { QUERY_KEYS } from '../../queryKeys';
import { serviceUnits } from '../ServiceUnits';

type OptionsType = {
  enabled: boolean;
};

export const useServiceUnits = (options?: OptionsType) =>
  useQuery(
    QUERY_KEYS.serviceUnits,
    async () => (await serviceUnits.getServiceUnits()).data,
    options,
  );

export const useCreateServiceUnit = () =>
  useMutation(serviceUnits.createServiceUnits);
export const useUpdateServiceUnit = () =>
  useMutation(serviceUnits.updateServiceUnits);
