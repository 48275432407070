import React, { FC, useCallback, useMemo } from 'react';
import { isPagerVisible } from '@/utils/helpers';
import { StyledLink, StyledLinkContainer } from '@/ui/globalStyles';
import {
  Column,
  DataGrid,
  LoadPanel,
  Pager,
  RequiredRule,
  Sorting,
  FilterRow,
  Lookup,
  Editing,
} from '@/ui';
import { faEnvelope, Icon } from '@/ui/components/Icon';
import { Status } from '@/types/Users/Users';
import { UsersTabProps } from './types';

const PAGE_SIZE = 20;

export const UsersTab: FC<UsersTabProps> = ({
  users,
  roles,
  inspectors,
  onInviteUser,
  onDeleteUser,
  onAddUser,
}) => {
  const handleAddUser = useCallback(({ data }) => onAddUser(data), [onAddUser]);

  const handleDeleteUser = useCallback(
    ({ data: { id } }) => {
      onDeleteUser({ userId: id });
    },
    [onDeleteUser],
  );

  const onRenderCell = useCallback(
    ({ data }) => {
      if (data.status === Status.active) return null;

      return (
        <StyledLinkContainer>
          <StyledLink onClick={() => onInviteUser(data.id)}>
            <Icon icon={faEnvelope} />
          </StyledLink>
        </StyledLinkContainer>
      );
    },
    [onInviteUser],
  );

  const inspectorsDataSource = useMemo(
    () =>
      inspectors?.map(({ id, inspectorSurname, inspectorForenames }) => ({
        id,
        displayExpr: `${inspectorForenames} ${inspectorSurname}`,
      })),
    [inspectors],
  );

  return (
    <DataGrid
      id="gridContainer"
      columnHidingEnabled
      showBorders
      dataSource={users}
      onRowInserted={handleAddUser}
      onRowRemoving={handleDeleteUser}
      rowAlternationEnabled
      width="100%"
    >
      <LoadPanel enabled />
      <Pager
        allowedPageSizes={PAGE_SIZE}
        showInfo
        showNavigationButtons
        visible={isPagerVisible(PAGE_SIZE, users?.length)}
      />
      <Editing allowAdding allowDeleting mode="form" />
      <FilterRow visible />
      <Sorting mode="multiple" />
      <Column dataField="displayName" caption="Username">
        <RequiredRule />
      </Column>
      <Column dataField="email" caption="Email" width={225}>
        <RequiredRule />
      </Column>
      <Column dataField="role.id" caption="Role" width={100}>
        <RequiredRule />
        <Lookup dataSource={roles} displayExpr="name" valueExpr="id" />
      </Column>
      <Column dataField="inspectorId" caption="Inspector">
        <Lookup
          dataSource={inspectorsDataSource}
          displayExpr="displayExpr"
          valueExpr="id"
        />
      </Column>
      <Column
        dataField="status"
        caption="Status"
        width={100}
        formItem={{ visible: false }}
      />
      <Column
        caption="Invitation"
        width={100}
        formItem={{ visible: false }}
        allowFiltering={false}
        alignment="center"
        cellRender={onRenderCell}
      />
    </DataGrid>
  );
};
