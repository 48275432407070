import React, { FC, useCallback } from 'react';
import {
  Column,
  DataGrid,
  DevButton,
  FilterRow,
  LoadPanel,
  Pager,
  RequiredRule,
  Sorting,
} from '@/ui';
import { isPagerVisible } from '@/utils/helpers';
import { useTranslate } from '@/translations';
import { CustomerGroupTabProps } from './types';

const PAGE_SIZE = 16;

export const CustomerGroupTab: FC<CustomerGroupTabProps> = ({
  dataSource,
  // onCreateCustomerGroup,
  onEditCustomerGroup,
  onDeleteCustomerGroup,
  onToggleImagePopup,
}) => {
  const { t } = useTranslate();

  const onRenderActionsCell = useCallback(
    ({ data }) => (
      <div className="d-flex justify-content-center">
        <DevButton
          text={t('edit')}
          stylingMode="text"
          icon="edit"
          onClick={() => onEditCustomerGroup(data)}
        />

        <DevButton
          text={t('addFleet')}
          stylingMode="text"
          icon="import"
          onClick={onToggleImagePopup}
        />

        <DevButton
          text={t('delete')}
          stylingMode="text"
          icon="trash"
          onClick={() => onDeleteCustomerGroup(data.id)}
        />
      </div>
    ),
    [t, onDeleteCustomerGroup, onEditCustomerGroup, onToggleImagePopup],
  );

  return (
    <DataGrid
      id="gridContainer"
      dataSource={dataSource}
      columnHidingEnabled
      onRowUpdating={() => {}}
      showRowLines
      showBorders
      width="100%"
    >
      <LoadPanel enabled />
      <Pager
        allowedPageSizes={PAGE_SIZE}
        showInfo
        showNavigationButtons
        visible={isPagerVisible(PAGE_SIZE, dataSource?.length)}
      />
      <FilterRow visible />
      <Sorting mode="multiple" />
      <Column dataField="code" caption="Code" allowEditing={false}>
        <RequiredRule />
      </Column>
      <Column dataField="groupName" caption="Group Name" allowEditing={false}>
        <RequiredRule />
      </Column>
      <Column
        dataField="action"
        caption=""
        width={320}
        cellRender={onRenderActionsCell}
      />
    </DataGrid>
  );
};
