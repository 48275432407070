export const FORMAT_DATE = 'dd/MM/yyyy';
export const FORMAT_DATE_WITH_TIME = 'dd/MM/yyyy h:mm a';
export const FORMAT_DATE_DD_MM_YYYY_HH_MM = 'dd/MM/yyyy HH:mm';
export const FORMAT_DATE_HH_MM = 'h:mm a';
export const MAX_FILE_SIZE = 5_242_880;

// https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types#common_image_file_types
export const ImageSupportedExtensions = [
  'apng',
  'avif',
  'gif',
  'jpg',
  'jpeg',
  'pjpeg',
  'pjp',
  'png',
  'svg',
  'webp',
  'bmp',
  'ico',
  'cur',
  'tif',
  'tiff',
];

// XLSX library supported formats: https://docs.sheetjs.com/docs/miscellany/formats
export const XLSXSupportedExtensions = [
  'xls',
  'xlsx',
  'xlsm',
  'xlsb',
  'csv',
  'txt',
  'dif',
  'sylk',
  'slk',
  'prn',
  'et',
  'ods',
  'fods',
  'uos1',
  'uos2',
  'dbf',
  'wks',
  'wk1',
  'wk2',
  'wk3',
  'wk4',
  'wq1',
  'wq2',
  'wb1',
  'wb2',
  'wb3',
  'qpw',
  'xlr',
  'eth',
];

export const InspectionStatusMap = {
  Inspected: 1,
  'Awaiting Authorisation': 2,
  'Requires Parts Pricing': 3,
  'Requires Review': 4,
  'Requires Re-submission Review': 5,
  Authorised: 6,
  'Auth Rejected': 7,
  Completed: 8,
  1: 'Inspected',
  2: 'Awaiting Authorisation',
  3: 'Requires Parts Pricing',
  4: 'Requires Review',
  5: 'Requires Re-submission Review',
  6: 'Authorised',
  7: 'Auth Rejected',
  8: 'Completed',
};

export const InspectionStatusMapLookupFilterValues = [
  { id: 1, displayExpr: 'Inspected' },
  { id: 2, displayExpr: 'Awaiting Authorisation' },
  { id: 3, displayExpr: 'Requires Parts Pricing' },
  { id: 4, displayExpr: 'Requires Review' },
  { id: 5, displayExpr: 'Requires Re-submission Review' },
  { id: 6, displayExpr: 'Authorised' },
  { id: 7, displayExpr: 'Auth Rejected' },
  { id: 8, displayExpr: 'Completed' },
];

export const SortModelTypeMap = {
  asc: 0, // Ascending
  desc: 1, // Descending
};

export const FilterModelTypeMap = {
  contains: 0,
  notcontains: 1,
  startswith: 2,
  endswith: 3,
  '=': 4, // equal
  '<>': 5, // not equal
  '<': 6, // less than
  '>': 7, // greater than
  '<=': 8, // less than or equal to
  '>=': 9, // greater than or equal to
};

export const FilterModelTypeMapReverse = {
  0: 'contains',
  1: 'notcontains',
  2: 'startswith',
  3: 'endswith',
  4: '=',
  5: '<>',
  6: '<',
  7: '>',
  8: '<=',
  9: '>=',
};

export const InspectionsPageFields = {
  // [key]: inspection.field,
  id: 'id',
  inspectionRef: 'inspectionRef',
  file: 'file',
  regNumber: 'regNumber',
  vin: 'vin',
  fleetDescription: 'fleetDescription',
  inspectionLocation: 'inspectionLocation',
  manufacturer: 'manufacturer',
  model: 'model',
  inspectionDateTime: 'inspectionDateTime',
  inspectionStatus: 'inspectionStatus',
  inspector: 'inspector',
};

// tenants
const DEFAULT = 'Default Tenant';
const BMW = 'BMW';

export const TENANTS = {
  DEFAULT,
  BMW,
};
