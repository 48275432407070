import { useQuery, useMutation } from 'react-query';
import { QUERY_KEYS } from '../../queryKeys';
import { locations } from '../Locations';

type OptionsType = {
  enabled: boolean;
};

export const useLocations = (options?: OptionsType) =>
  useQuery(
    QUERY_KEYS.location,
    async () => (await locations.getLocations()).data,
    options,
  );

export const useCreateLocation = () => useMutation(locations.createLocations);
export const useUpdateLocation = () => useMutation(locations.updateLocations);

export const useLocationsSites = (
  tenantId: number,
  options?: {
    enabled?: boolean;
  },
) =>
  useQuery(
    [QUERY_KEYS.location, tenantId],
    async () => (await locations.getLocationsSites(tenantId)).data,
    options,
  );
