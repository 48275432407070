import React, { FC, useCallback, useMemo } from 'react';
import {
  Column,
  DataGrid,
  LoadPanel,
  Pager,
  Paging,
  FilterRow,
  Sorting,
  Lookup,
  DevButton,
  TagBox,
} from '@/ui';
import { isPagerVisible } from '@/utils/helpers';
import { useTranslate } from '@/translations';
import { ManageTMSUsersDataGridProps } from './types';

const PAGE_SIZE = 16;

export const ManageTMSUsersDataGrid: FC<ManageTMSUsersDataGridProps> = ({
  dataSource,
  inspectors,
  onDeleteTMSUser,
  sitesMap,
  onSetEditUserData,
}) => {
  const { t } = useTranslate();

  const onRenderActionsCell = useCallback(
    ({ data }) => (
      <div className="d-flex justify-content-center">
        <DevButton
          text={t('edit')}
          stylingMode="text"
          icon="edit"
          onClick={() => onSetEditUserData(data)}
        />

        <DevButton
          text={t('delete')}
          stylingMode="text"
          icon="trash"
          onClick={() => onDeleteTMSUser(data.id)}
        />
      </div>
    ),
    [t, onDeleteTMSUser, onSetEditUserData],
  );

  const inspectorsDataSource = useMemo(
    () =>
      inspectors?.map(({ id, inspectorSurname, inspectorForenames }) => ({
        id,
        displayExpr: `${inspectorForenames} ${inspectorSurname}`,
      })),
    [inspectors],
  );

  const renderCell = useCallback(
    ({ data: { tenantId, siteIds } }) => {
      const sitesDataSource = siteIds.map(
        (siteId: number) => sitesMap?.[tenantId][siteId],
      );

      if (!sitesDataSource.length) return null;

      return (
        <TagBox
          dataSource={sitesDataSource}
          value={siteIds}
          valueExpr="siteId"
          displayExpr="siteName"
          disabled
        />
      );
    },
    [sitesMap],
  );

  return (
    <DataGrid
      id="gridContainer"
      keyExpr="id"
      dataSource={dataSource}
      onRowRemoving={({ key }) => onDeleteTMSUser(key)}
      columnHidingEnabled
      width="100%"
      showBorders
      allowColumnReordering
      showRowLines
    >
      <FilterRow visible />
      <Sorting mode="multiple" />
      <LoadPanel enabled />
      <Paging pageSize={PAGE_SIZE} />
      <Pager
        showInfo
        showNavigationButtons
        visible={isPagerVisible(PAGE_SIZE, dataSource?.length)}
      />
      <Column
        dataField="displayName"
        formItem={false}
        caption={t('displayName')}
        minWidth={20}
      />
      <Column
        dataField="userName"
        formItem={false}
        caption={t('username')}
        minWidth={20}
      />
      <Column dataField="inspectorId" caption="Inspector">
        <Lookup
          dataSource={inspectorsDataSource}
          displayExpr="displayExpr"
          valueExpr="id"
        />
      </Column>
      <Column
        dataField="siteIds"
        caption={t('site')}
        minWidth={20}
        cellRender={renderCell}
        width={360}
        allowFiltering={false}
      />
      <Column
        dataField="action"
        caption=""
        allowFiltering={false}
        allowSorting={false}
        cellRender={onRenderActionsCell}
      />
    </DataGrid>
  );
};
