import React, { FC, useCallback } from 'react';
import { Column, DataGrid, LoadPanel, Paging, Pager } from '@/ui';
import { isPagerVisible } from '@/utils/helpers';
import { faTrash, Icon, faCheck } from '@/ui/components/Icon';
import { StyledLinkContainer, StyledLink } from '@/ui/globalStyles';
import { useTranslate } from '@/translations';
import { useTheme } from 'styled-components';

import { DocumentTypesDataGridProps } from '../types';

const PAGE_SIZE = 16;

export const DocumentTypesDataGrid: FC<DocumentTypesDataGridProps> = ({
  documentTypes,
  deleteDocumentType,
}) => {
  const { t } = useTranslate();
  const { accent } = useTheme();

  const renderCustomerPreviewRestrictedCell = useCallback(
    ({ data: { isCustomerPreviewRestricted } }) =>
      isCustomerPreviewRestricted ? <Icon icon={faCheck} color={accent} /> : null,
    [accent],
  );

  const renderActionsCell = useCallback(
    ({ data }) => (
      <StyledLinkContainer>
        <StyledLink onClick={() => deleteDocumentType(data.documentTypeId)}>
          <Icon icon={faTrash} />
        </StyledLink>
      </StyledLinkContainer>
    ),
    [deleteDocumentType],
  );

  return (
    <DataGrid
      id="gridContainer"
      dataSource={documentTypes}
      keyExpr="documentTypeId"
      showBorders
      allowColumnReordering
    >
      <LoadPanel enabled />
      <Paging pageSize={PAGE_SIZE} />
      <Pager
        showInfo
        showNavigationButtons
        visible={isPagerVisible(PAGE_SIZE, documentTypes?.length)}
      />
      <Column dataField="documentTypeCode" minWidth={200} width={200} />
      <Column dataField="documentType" minWidth={500} width={500} />
      <Column
        caption={t('isCustomerPreviewRestricted')}
        minWidth={250}
        width={250}
        alignment="center"
        cellRender={renderCustomerPreviewRestrictedCell}
      />
      <Column
        dataField="actions"
        minWidth={80}
        width={80}
        alignment="center"
        cellRender={renderActionsCell}
      />
    </DataGrid>
  );
};
