import React, { FC, useCallback, useState } from 'react';

import { FunctionalFeature } from '@/types';
import {
  useAssignGBAUserToTenant,
  useCreateUserInTenant,
  useDeleteUser,
  useGBAUsers,
  useInviteGBAUser,
  useInviteUser,
  useTenantGBAUsers,
  useTenantUsers,
  useUnassignGBAUserToTenant,
} from '@/api/Users';
import { useInspectionsVehiclesInspectors } from '@/api/Inspections';
import { useGBARoles, useRoles } from '@/api/Roles';
import {
  useTenantFunctionalFeatures,
  useToggleDisableFunctionalFeatures,
} from '@/api/FunctionalFeatures';
import { DevButton, TabPanel, TabPanelItem } from '@/ui';
import { AddUserData } from '@/modules/TenantManagement/components/tabs/Users/types';

import { CustomerGroupTab } from './CustomerGroup/CustomerGroupTab';
import { AddFleetPopup } from './CustomerGroup/components/AddFleetPopup/AddFleetPopup';
import { UsersTab } from './Users/Users';
import { FeaturesTab } from './Features/Features';
import { formatCreateUserData } from './helpers';
import { GBAUsersTab } from './GBAUsers/GBAUsers';

const tabNames = ['GBA Users', 'Users', 'Features', 'Customer Group'];

const mockedData = {
  entities: [
    {
      id: 1,
      code: 'CCL',
      groupName: 'CocaCoal',
    },
    {
      id: 2,
      code: 'LLC',
      groupName: 'CoalCoca',
    },
    {
      id: 3,
      code: 'LCL',
      groupName: 'AlcoCoca',
    },
  ],
};

export const MasterDetail: FC<{ tenantId: number }> = ({ tenantId }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [isImagePopupVisible, setImagePopupVisibility] = useState(false);

  const { data: tenantGBAUsersData, isLoading: isTenantGBAUsersLoading } =
    useTenantGBAUsers(tenantId, {
      enabled: selectedTabIndex === 0,
    });
  const { data: tenantUsersData, isLoading: isTenantUsersLoading } =
    useTenantUsers(tenantId, {
      enabled: selectedTabIndex === 1,
    });
  const { data: inspectorsData, isLoading: isInspectorsLoading } =
    useInspectionsVehiclesInspectors({
      enabled: [0, 1].includes(selectedTabIndex),
    });
  const { data: tenantFeaturesData, isLoading: isTenantFeaturesLoading } =
    useTenantFunctionalFeatures(tenantId, {
      enabled: selectedTabIndex === 2,
    });

  const { data: gbaUsers, isLoading: isGBAUsersLoading } = useGBAUsers({
    enabled: selectedTabIndex === 0,
  });

  const { data: gbaRolesData, isLoading: isGBARolesLoading } = useGBARoles({
    enabled: selectedTabIndex === 0,
  });
  const { data: rolesData, isLoading: isRolesLoading } = useRoles({
    enabled: selectedTabIndex === 1,
  });

  const { mutate: updateFeatureMutate, isLoading: isUpdateFeatureLoading } =
    useToggleDisableFunctionalFeatures();

  const { mutate: createUserMutate, isLoading: isCreateUserLoading } =
    useCreateUserInTenant();
  const { mutate: deleteUserMutate, isLoading: isDeleteUserLoading } =
    useDeleteUser();
  const { mutate: inviteUserMutate, isLoading: isInviteUserLoading } =
    useInviteUser();

  const { mutate: inviteGBAUserMutate, isLoading: isInviteGBAUserLoading } =
    useInviteGBAUser();
  const { mutate: assignGBAUserMutate, isLoading: isAssignGBAUserLoading } =
    useAssignGBAUserToTenant();
  const { mutate: unassignGBAUserMutate, isLoading: isUnassignGBAUserLoading } =
    useUnassignGBAUserToTenant();

  const handleTabSelectionChanged = useCallback((e) => {
    setSelectedTabIndex(tabNames.indexOf(e.addedItems[0].title));
  }, []);

  const handleUpdateFeature = useCallback(
    (
      { isEnabled }: Partial<FunctionalFeature>,
      { functionalFeature: { id } }: FunctionalFeature,
    ) => {
      updateFeatureMutate({
        tenantId,
        isEnabled: !!isEnabled,
        featureId: id,
      });
    },
    [tenantId, updateFeatureMutate],
  );

  const handleCreateUser = useCallback(
    (data: AddUserData) => {
      const formattedData = formatCreateUserData(data);
      createUserMutate({ ...formattedData, tenantId });
    },
    [createUserMutate, tenantId],
  );

  // const handleCreateCustomerGroup = useCallback((data) => {
  //   console.log('data:', data);
  // }, []);

  const toggleImagePopup = useCallback(() => {
    setImagePopupVisibility(!isImagePopupVisible);
  }, [isImagePopupVisible]);

  const handleEditCustomerGroup = useCallback((data) => {
    console.log('data:', data);
  }, []);

  const handleDeleteCustomerGroup = useCallback((data) => {
    console.log('data:', data);
  }, []);

  return (
    <TabPanel
      selectedIndex={selectedTabIndex}
      onSelectionChanged={handleTabSelectionChanged}
    >
      <TabPanelItem
        title={tabNames[0]}
        render={() => (
          <GBAUsersTab
            tenantId={tenantId}
            roles={gbaRolesData?.entities}
            users={tenantGBAUsersData?.entities}
            gbaUsers={gbaUsers?.entities}
            inspectors={inspectorsData?.entities}
            isLoading={
              isTenantGBAUsersLoading ||
              isGBARolesLoading ||
              isInspectorsLoading ||
              isInviteGBAUserLoading ||
              isAssignGBAUserLoading ||
              isUnassignGBAUserLoading ||
              isGBAUsersLoading
            }
            onAssignUser={assignGBAUserMutate}
            onUnassignUser={unassignGBAUserMutate}
            onInviteUser={inviteGBAUserMutate}
          />
        )}
      />
      <TabPanelItem
        title={tabNames[1]}
        render={() => (
          <UsersTab
            roles={rolesData?.entities}
            users={tenantUsersData?.entities}
            inspectors={inspectorsData?.entities}
            isLoading={
              isTenantUsersLoading ||
              isRolesLoading ||
              isInspectorsLoading ||
              isCreateUserLoading ||
              isInviteUserLoading ||
              isDeleteUserLoading
            }
            onAddUser={handleCreateUser}
            onDeleteUser={deleteUserMutate}
            onInviteUser={inviteUserMutate}
          />
        )}
      />
      <TabPanelItem
        title={tabNames[2]}
        render={() => (
          <FeaturesTab
            features={tenantFeaturesData?.entities}
            onFeatureUpdate={handleUpdateFeature}
            isLoading={isTenantFeaturesLoading || isUpdateFeatureLoading}
          />
        )}
      />
      <TabPanelItem
        title={tabNames[3]}
        render={() => (
          <>
            <div className="pb-2 d-flex justify-content-end bg-white">
              <DevButton
                stylingMode="outlined"
                icon="add"
                onClick={toggleImagePopup}
                className="d-flex"
              />
            </div>

            <CustomerGroupTab
              dataSource={mockedData?.entities}
              // onCreateCustomerGroup={handleCreateCustomerGroup}
              onEditCustomerGroup={handleEditCustomerGroup}
              onDeleteCustomerGroup={handleDeleteCustomerGroup}
              onToggleImagePopup={toggleImagePopup}
            />

            <AddFleetPopup
              isPopupVisible={isImagePopupVisible}
              onTogglePopup={toggleImagePopup}
            />
          </>
        )}
      />
    </TabPanel>
  );
};

export default MasterDetail;
