import styled from 'styled-components';

export const Title = styled.h6`
  color: ${({ theme }) => theme.accent};
  font-size: 22px !important;
  padding: 0 0 12px 0;
  margin: 0;
  font-weight: 500;
  line-height: 1.2;
`
